import axios from '../gateway/backend-api'
const state = () => {
  if (localStorage.menu) {
    return JSON.parse(localStorage.getItem('menu'))
  }
}

// getters
const getters = {
  getMenu: (state) => state.menu
}

// actions
const actions = {
  getMenu: function ({ commit }, payload) {
    commit('getMenu', payload)
  },
  getMenuCall: function ({  dispatch }) {
            axios
              .get(
                '/' +
                  localStorage.getItem('clinic') +
                  '/auth/' +
                  localStorage.getItem('userId') +
                  '/get-menu'
              )
              .then((res) => {
                dispatch('getMenu', JSON.stringify(res.data))
              })

  }
}

// mutations
const mutations = {
  getMenu: function (state, payload) {
    localStorage.setItem('menu', payload)
    state.menu = JSON.parse(payload).menu
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
