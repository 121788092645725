import axios from '../gateway/backend-api'

const state = () => {
  return {
    paymentMethods: null,
    defaultPaymentMethods: null,
    documentTypes: null,
    invoiceTypes: null,
    invoiceTypesClinic: null,
    invoiceStatus: null,
    listVat: null,
    templateSchede: null,
    templateReferti: null,
    templateSupport: null,
    templateLab: null,
    clinicSetup: null,
    permissions: null,
    examsType: [
      { id: 1, value: 'emocromo', label: 'Esame Emocromo' },
      {
        id: 2,
        value: 'biochimico',
        label: 'Esame Biochimico'
      },
      {
        id: 3,
        value: 'ecografico',
        label: 'Esame Ecografico'
      }
    ],
    totalBell: 0,
    prefissoSezionale: [],
    clinic_name: null
  }
}

// getters
const getters = {
  getPaymentMethods: (state) => state.paymentMethods,
  getDefaultPaymentMethods: (state) => state.defaultPaymentMethods,
  getDocumentTypes: (state) => state.documentTypes,
  getInvoiceTypes: (state) => state.invoiceTypes,
  getInvoiceTypesClinic: (state) => state.invoiceTypesClinic,
  getInvoiceStatus: (state) => state.invoiceStatus,
  getListVat: (state) => state.listVat,
  getTemplateSchede: (state) => state.templateSchede,
  getTemplateReferti: (state) => state.templateReferti,
  getTemplateSupport: (state) => state.templateSupport,
  getTemplateLab: (state) => state.templateLab,
  getClinicSetup: (state) => state.clinicSetup,
  getShowIva: (state) =>
    state.clinicSetup &&
    state.clinicSetup.show_price_iva &&
    state.clinicSetup.show_price_iva[0]
      ? state.clinicSetup.show_price_iva[0].show_price_iva
      : false,
  getRegimeFiscaleIva: (state) =>
    state.clinicSetup &&
    state.clinicSetup.regime_fiscale &&
    state.clinicSetup.regime_fiscale[0] &&
    state.clinicSetup.regime_fiscale[0].is_active
      ? state.clinicSetup.regime_fiscale[0].iva
      : null,
  getRegimeFiscaleIvaPerc: (state) =>
    state.clinicSetup &&
    state.clinicSetup.regime_fiscale &&
    state.clinicSetup.regime_fiscale[0] &&
    state.clinicSetup.regime_fiscale[0].is_active
      ? state.clinicSetup.regime_fiscale[0].iva_perc !== 0
        ? state.clinicSetup.regime_fiscale[0].iva_perc
        : 1
      : 1.22,
  getCassaPrevidenzialeActive: (state) =>
    state.clinicSetup &&
    state.clinicSetup.cassa_previdenziale &&
    state.clinicSetup.cassa_previdenziale[0] &&
    state.clinicSetup.cassa_previdenziale[0].is_active === true
      ? state.clinicSetup.cassa_previdenziale[0]
      : null,
  getRitenutaAccontoActive: (state) =>
    state.clinicSetup &&
    state.clinicSetup.ritenuta &&
    state.clinicSetup.ritenuta[0] &&
    state.clinicSetup.ritenuta[0].is_active === true
      ? state.clinicSetup.ritenuta[0]
      : null,
  getRitenutaAccontoDefault: (state) =>
    state.clinicSetup &&
    state.clinicSetup.ritenuta &&
    state.clinicSetup.ritenuta[0] &&
    state.clinicSetup.ritenuta[0].is_default === true
      ? state.clinicSetup.ritenuta[0]
      : null,
  getShowRiepilogoStampa: (state) =>
    state.clinicSetup &&
    state.clinicSetup.show_riepilogo_stampa &&
    state.clinicSetup.show_riepilogo_stampa[0]
      ? state.clinicSetup.show_riepilogo_stampa[0].show_riepilogo_stampa
      : false,
  getScontoPercentualeActive: (state) =>
    state.clinicSetup &&
    state.clinicSetup.percentage_discount &&
    state.clinicSetup.percentage_discount[0]
      ? state.clinicSetup.percentage_discount[0].percentage_discount
      : null,
  getPermissions: (state) => state.permissions,
  getExamsType: (state) => state.examsType,
  getTotalBell: (state) => state.totalBell,
  getPrefissoSezionale: (state) =>
    state.prefissoSezionale && state.prefissoSezionale.length > 0
      ? state.prefissoSezionale
      : null,
  getSezionali: (state) =>
    state.clinicSetup.sezionali && state.clinicSetup.sezionali.length > 0
      ? state.clinicSetup.sezionali
      : null,
  getClinicName: (state) => state.clinic_name,
  getScadenzaDefault: (state) =>
    state.clinicSetup?.validita_preventivo !== null &&
    typeof state.clinicSetup.validita_preventivo === 'number'
      ? state.clinicSetup.validita_preventivo
      : null
}

// actions
const actions = {
  getDefaultPaymentMethods: function ({ commit }) {
    axios
      .get(
        '/' +
          localStorage.getItem('clinic') +
          '/bills/payments?type=default_types'
      )
      .then((res) => {
        commit('setDefaultPaymentMethods', res.data)
      })
  },
  getPaymentMethods: function ({ commit }) {
    axios
      .get(
        '/' +
          localStorage.getItem('clinic') +
          '/bills/payments?type=alias_types'
      )
      .then((res) => {
        commit('setPaymentMethods', res.data)
      })
  },
  getDocumentTypes: function ({ commit }) {
    if (localStorage.getItem('clinic')) {
      if (parseInt(localStorage.getItem('userRoleId')) != 5) {
        axios
          .get('/' + localStorage.getItem('clinic') + '/bills/document-type')
          .then((res) => {
            commit('setDocumentTypes', res.data)
          })
      }
    }
  },
  getInvoiceTypes: function ({ commit }) {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/document-type')
      .then((res) => {
        commit('setInvoiceTypes', res.data)
      })
  },
  getInvoiceTypesClinic: function ({ commit }) {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/document-type-clinic')
      .then((res) => {
        commit('setInvoiceTypesClinic', res.data)
      })
  },
  getInvoiceStatus: function ({ commit }) {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/document-status')
      .then((res) => {
        commit('setInvoiceStatus', res.data)
      })
  },
  getListVat: function ({ commit }) {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/iva')
      .then((res) => {
        commit('setListVat', res.data)
      })
  },
  getSchedeClinicheTemplate: function ({ commit }) {
    if (localStorage.getItem('clinic')) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medicallayout?type=clinical_card '
        )
        .then((res) => {
          if (typeof res != 'undefined')
            commit('setSchedeClinicheTemplate', res.data)
        })
    }
  },
  getRefertiTemplate: function ({ commit }) {
    if (localStorage.getItem('clinic')) {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/medicallayout?type=report '
        )
        .then((res) => {
          if (typeof res != 'undefined') commit('setRefertiTemplate', res.data)
        })
    }
  },
  getRefertiSupport: function ({ commit }) {
    if (localStorage.getItem('clinic')) {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/medicallayout?type=support'
        )
        .then((res) => {
          commit('setSupportTemplate', res.data)
        })
    }
  },
  getRefertiLab: function ({ commit }) {
    if (localStorage.getItem('clinic')) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medicallayout?type=referti_di_laboratorio'
        )
        .then((res) => {
          commit('setLabTemplate', res.data)
        })
    }
  },
  getClinicaSetup: function ({ commit }) {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/default-setup')
      .then((res) => {
        commit('setClinicaSetup', res.data)
      })
  },
  getBackendVersion: function () {
    axios.get('/cvit/getCurrentGitVersion').then((res) => {
      localStorage.setItem('beVersion', res.data)
    })
  },
  getPermissionsAction: function ({ commit }, id) {
    axios
      .post('/permission', {
        staff_user_id: id,
        clinic_id: localStorage.getItem('clinic')
      })
      .then((res) => {
        commit('setPermissions', res.data)
      })
  },
  getExamsType: function ({ commit }, id) {
    axios
      .post('/examsType', {
        staff_user_id: id,
        clinic_id: localStorage.getItem('clinic')
      })
      .then((res) => {
        commit('setExamsType', res.data)
      })
  },
  getTotalBell: function ({ commit }) {
    axios
      .post('/' + localStorage.getItem('clinic') + '/notification/bell', {
        type: null
      })
      .then((res) => {
        var bellTotal = 0

        Object.values(res.data).forEach((value) => {
          bellTotal += value
        })

        commit('setTotalBell', bellTotal)
      })
  },
  setPrefissoSezionale: function ({ commit }, prefisso_sezionale) {
    commit('setPrefissoSezionale', prefisso_sezionale)
  },
  updateClinicData: function ({ commit }) {
    axios.get('/clinic/' + localStorage.getItem('clinic')).then((res) => {
      console.log(res)
      if (res.data && res.data.ragione_sociale)
        //localStorage.setItem('clinic-name', res.data.ragione_sociale)
        commit('setClinicName', res.data.ragione_sociale)
    })
  }
}

// mutations
const mutations = {
  setClinicName: function (state, payload) {
    state.clinic_name = payload
    localStorage.setItem('clinic-name', payload)
  },
  setDefaultPaymentMethods: function (state, payload) {
    state.defaultPaymentMethods = payload
  },
  setPaymentMethods: function (state, payload) {
    state.paymentMethods = payload
  },
  setDocumentTypes: function (state, payload) {
    state.documentTypes = payload
  },
  setInvoiceTypes: function (state, payload) {
    state.invoiceTypes = payload
  },
  setInvoiceTypesClinic: function (state, payload) {
    state.invoiceTypesClinic = payload
  },
  setInvoiceStatus: function (state, payload) {
    state.invoiceStatus = payload
  },
  setListVat: function (state, payload) {
    state.listVat = payload
  },
  setSchedeClinicheTemplate: function (state, payload) {
    state.templateSchede = payload
  },
  setRefertiTemplate: function (state, payload) {
    state.templateReferti = payload
  },
  setSupportTemplate: function (state, payload) {
    state.templateSupport = payload
  },
  setLabTemplate: function (state, payload) {
    state.templateLab = payload
  },
  setClinicaSetup: function (state, payload) {
    state.clinicSetup = payload
  },
  setPermissions: function (state, payload) {
    state.permissions = payload
  },
  setExamsType: function (state, payload) {
    state.examsType = payload
  },
  setTotalBell: function (state, payload) {
    state.totalBell = payload
  },
  setPrefissoSezionale: function (state, payload) {
    state.prefissoSezionale = payload.prefisso_sezionale
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
