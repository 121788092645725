import Dropzone from 'dropzone'
import axios from '../../gateway/backend-api'

const init = (el, props, refresh_function) => {
  console.log(props)
  Dropzone.autoDiscover = false
  el.dropzone = new Dropzone(el, props.options)
  el.dropzone.on('addedfile', (file) => {
    if (props.uploadToUrl) {
      console.log('A file has been added in url ', props.options.url, file)
      var send_data = new FormData()
      send_data.append('type', 'documents')

      send_data.append('file', file)
      axios
        .post(
          '/clinic/' + localStorage.getItem('clinic') + props.options.url,
          send_data
        )
        .then(() => {
          refresh_function()
        })
        .catch(() => {
          refresh_function()
        })
    }
  })
}

export { init }
