<!--Pagina parallela a DatiClinica.vue con lo stile di Aruba-->
<template>
  <div
    class="p-4 rounded-lg content-container overflow-scroll scrollbar-none bg-white"
  >
    <div class="flex justify-end">
      <button
        class="mb-2"
        :class="!espandi ? 'orange-btn-inverted' : 'orange-btn'"
        @click="toggleAll"
      >
        {{ !espandi ? 'Espandi' : 'Riduci' }} tutto
      </button>
    </div>
    <!-- start Regime fiscale -->
    <Acordeon
      id="regime-fiscale"
      class="mb-1"
      title="Regime fiscale"
      :stato="false"
      :swich="false"
      :setup="false"
      :open="true"
      @handleOpen="openRegimeFiscale = !openRegimeFiscale"
      :openVar="openRegimeFiscale"
    />
    <div v-if="openRegimeFiscale" class="px-1 py-2 mb-2">
      <div>
        <div
          class="flex items-center gap-2 mt-2 py-8 px-6 shadow-sm border-2 border-theme-2 rounded-xl bg-white"
        >
          <select ref="selectRegimeFiscale" class="custom-input w-full">
            <!-- @todo si potrebbe sostituire con la tabella tax_regimes_currents -->
            <!-- <option :value="null">Scegli un valore</option> -->
            <option
              :value="1"
              :selected="defaultRegimeFiscale?.tax_regime_id == 1"
            >
              RF01 - Ordinario
            </option>
            <option
              :value="18"
              :selected="defaultRegimeFiscale?.tax_regime_id == 18"
            >
              RF19 - Regime forfettario (art.1, c.54-89, L. 190/2014)
            </option>
          </select>

          <button class="orange-btn" @click="saveRegime">
            <i class="fas fa-save mr-1"></i>
          </button>
        </div>
        <div
          v-if="defaultRegimeFiscale"
          class="flex flex-col px-2 justify-center shadow-sm py-2 border-2 border-theme-2 rounded-xl bg-white"
        >
          <div class="p-2">
            <p
              class="text-base font-semibold mt-1 mb-2 bg-theme-2 p-2 rounded-md"
            >
              Attuale tipo di regime fiscale scelto:
            </p>
          </div>
          <div class="col-span-3 flex flex-col gap-1 px-2">
            {{ defaultRegimeFiscale.code ?? '' }} -
            {{ defaultRegimeFiscale.description ?? '' }}
          </div>
        </div>
      </div>
    </div>
    <!-- end Regime fiscale -->

    <!-- start Tariffario -->

    <Acordeon
      id="tariffario"
      class="mb-1"
      title="Tariffario"
      :stato="true"
      stato_text="Iva presente:"
      :swich="true"
      :setup="false"
      :open="false"
      :vmodel-var="iva"
      @handleSwich="handleChangeIva"
    />

    <!-- end Tariffario -->
    <!-- start Tariffario -->

    <Acordeon
      id="preventivo"
      class="mb-1"
      title="Preventivo"
      :stato="true"
      stato_text="Preventivo presente in stampa:"
      :swich="true"
      :setup="false"
      :open="false"
      @handleSwich="handleShowRiepilogoStampa"
      :vmodel-var="showRiepilogoStampa"
    />

    <!-- end Tariffario -->

    <!-- start Ritenuta -->
    <Acordeon
      id="ritenuta"
      class="mb-1"
      title="Ritenuta d'acconto"
      :stato="true"
      :swich="true"
      :setup="true"
      :open="true"
      @toggleSetup="toggleRitenute"
      :vmodel-var="ritenuteSwitch"
      @handleSwich="handleSwitchRitenuta"
      @handleOpen="openRitenute = !openRitenute"
      :openVar="openRitenute"
    />
    <div
      v-if="openRitenute && defaultRitenuta.type != null"
      class="px-1 py-2 mb-2"
    >
      <div
        class="flex flex-col px-2 justify-center shadow-sm py-2 border-2 border-theme-2 rounded-xl bg-white"
      >
        <div class="py-1">
          <p
            class="text-base font-semibold mt-1 mb-2 bg-theme-2 p-2 rounded-md"
          >
            Attuale tipo di ritenuta predefinita:
          </p>
        </div>
        <div class="grid grid-cols-11 gap-2 px-2">
          <div class="col-span-3 flex flex-col gap-1">
            <p class="font-bold mt-1 mb-1">Tipo di ritenuta:</p>
            {{ defaultRitenuta.type.code }} -
            {{ defaultRitenuta.type.description }}
          </div>
          <div class="col-span-6 flex flex-col gap-1">
            <p class="font-bold mt-1 mb-1">Causale:</p>
            {{ defaultRitenuta.causale.description }}
          </div>
          <div class="col-span-2 flex flex-col gap-1">
            <p class="font-bold mt-1 mb-1">Aliquota ritenuta:</p>
            {{ defaultRitenuta.aliquota }} su {{ defaultRitenuta.onPerc }} %
          </div>
        </div>
      </div>
    </div>
    <RitenutaModal
      v-if="showRitenute"
      :is-coming-from-setup="true"
      :ritenuta-vecchia="defaultRitenuta"
      @close="toggleRitenute"
      @saveDefault="defaultRitenutaFunction"
    />
    <!-- end Ritenuta -->

    <!-- start Sconto Magg -->

    <Acordeon
      id="sconto-magg"
      class="mb-1"
      title="Sconto - Maggiorazione"
      :stato="true"
      stato_text="Sconto / magg. presente in stampa:"
      :swich="true"
      :setup="false"
      :open="false"
      :vmodel-var="scontoSwitch"
      @handleSwich="saveDefaultSconto"
    />

    <!-- end Sconto Magg -->

    <!-- start Sconto Magg -->

    <Acordeon
      id="sconto-type"
      class="mb-1"
      title="Tipologia sconto"
      :stato="true"
      stato_text="Sconto in formato percentuale"
      :swich="true"
      :setup="false"
      :open="false"
      :vmodel-var="scontoTypeSwitch"
      @handleSwich="saveDefaultScontoType"
    />

    <!-- end Sconto Magg -->

    <!-- start Causale -->
    <Acordeon
      id="causale"
      class="mb-1"
      title="Causale documento"
      :stato="true"
      :swich="true"
      :setup="true"
      :open="true"
      @toggleSetup="toggleCausale"
      :vmodel-var="causaleSwitch"
      @handleSwich="causaleSwitch = !causaleSwitch"
      @handleOpen="openCausale = !openCausale"
      :openVar="openCausale"
    />
    <div
      v-if="defaultCausale.text != null && openCausale"
      class="flex flex-col px-1 py-2 mb-2"
    >
      <div
        class="flex flex-col gap-3 items-start shadow-sm p-2 border-2 border-theme-2 rounded-xl bg-white"
      >
        <p class="text-base font-semibold bg-theme-2 p-2 rounded-md w-full">
          Attuale causale predefinita:
        </p>

        <p class="text-base pl-10">
          <span class="text-theme-41">• </span>
          {{ defaultCausale.text }}
        </p>
      </div>
    </div>
    <CausaleModal
      v-if="showCausale"
      :coming-from-setup="true"
      :old-causale="defaultCausale"
      @close="toggleCausale"
      @addCausaleSetup="addCausaleSetup"
    />
    <!-- end Causale -->

    <!-- start Note-->
    <Acordeon
      id="notes"
      class="mb-1"
      title="Note a piè di pagina"
      :stato="true"
      :swich="true"
      :setup="true"
      :open="true"
      @toggleSetup="toggleNote"
      :vmodel-var="noteSwitch"
      @handleSwich="noteSwitch = !noteSwitch"
      @handleOpen="openNote = !openNote"
      :openVar="openNote"
    />
    <div
      v-if="defaultNota.title != '' && defaultNota.title != null && openNote"
      class="flex flex-col mt-2 px-1 py-2 mb-2"
    >
      <div
        class="flex flex-col gap-3 items-start shadow-sm p-2 border-2 border-theme-2 rounded-xl"
      >
        <p class="text-base font-semibold bg-theme-2 p-2 rounded-md w-full">
          Attuale nota a piè di pagina predefinita:
        </p>

        <div class="flex flex-col gap-1 px-10">
          <h3 class="font-semibold text-base">
            {{ defaultNota.title }}
          </h3>
          <p class="pl-4">
            {{ defaultNota.text }}
          </p>
        </div>
      </div>
    </div>
    <NoteModal
      v-if="showNote"
      :old-note="defaultNota"
      :coming-from-setup="true"
      @close="toggleNote"
      @addNote="addNoteSetup"
    />
    <!-- end Note -->

    <!-- start Esigibilità IVA-->
    <Acordeon
      id="esigibilita-iva"
      class="mb-1"
      title="Esigibilità IVA"
      :stato="false"
      :swich="false"
      :setup="false"
      :open="true"
      @handleOpen="openIva = !openIva"
      :openVar="openIva"
    />
    <div v-if="openIva" class="px-1 py-2 mb-2">
      <div
        class="flex items-center gap-2 mt-2 py-8 px-6 shadow-sm border-2 border-theme-2 rounded-xl bg-white"
      >
        <select class="custom-input w-full">
          <option>IVA ad esigibilità immediata</option>
          <option>IVA ad esigibilità differita</option>
          <option>Scissione dei pagamenti</option>
        </select>

        <button class="orange-btn" @click="saveIva">
          <i class="fas fa-save mr-1"></i>
        </button>
      </div>
    </div>
    <!-- end Esigibilità IVA-->

    <!-- start Bollo-->
    <Acordeon
      id="bollo"
      class="mb-1"
      title="Bollo automatico"
      :stato="true"
      :swich="true"
      :setup="true"
      :open="true"
      @toggleSetup="toggleBollo"
      :vmodel-var="bolloSwitch"
      @handleSwich="bolloSwitch = !bolloSwitch"
      @handleOpen="openBollo = !openBollo"
      :openVar="openBollo"
    />
    <div
      v-if="defaultBollo.value != null && openBollo"
      class="flex flex-col px-1 py-2 mb-2"
    >
      <div
        class="flex flex-col gap-3 items-start shadow-sm p-2 border-2 border-theme-2 rounded-xl"
      >
        <p class="text-base font-semibold bg-theme-2 p-2 rounded-md w-full">
          Attuale bollo predefinito:
        </p>
        <p class="text-base pl-10">
          <span class="text-theme-41">• </span>
          {{ defaultBollo.alias }} - €{{ defaultBollo.value }}
        </p>
      </div>
    </div>
    <BolloModal
      v-if="showModalBollo"
      :coming-from-setup="true"
      :bollo-vecchio="defaultBollo"
      @close="toggleBollo"
      @addBolloSetup="addBolloSetup"
    />
    <!-- end Bollo -->

    <!-- start Contributo-->
    <Acordeon
      id="contributo"
      class="mb-1"
      title="Contributo previdenziale"
      :stato="true"
      :swich="true"
      :setup="false"
      :open="true"
      @toggleSetup="toggleCassa"
      :vmodel-var="previdenzialeSwitch"
      @handleSwich="previdenzialeSwitch = !previdenzialeSwitch"
      @handleOpen="openContributo = !openContributo"
      :openVar="openContributo"
    />
    <div
      v-if="defaultCassa.type != null && openContributo"
      class="flex flex-col px-1 py-2 mb-2"
    >
      <div
        class="flex flex-col px-2 justify-center shadow-sm py-2 border-2 border-theme-2 rounded-xl"
      >
        <div class="py-1">
          <p
            class="text-base font-semibold mt-1 mb-2 bg-theme-2 p-2 rounded-md"
          >
            Attuale tipo di cassa previdenziale predefinito
          </p>
        </div>
        <div class="grid grid-cols-9 gap-2 px-2">
          <div class="col-span-5 flex items-center gap-1">
            <p class="font-bold mt-1 mb-1">Tipo di cassa:</p>
            {{
              defaultCassa.type.code
                ? defaultCassa.type.code
                : defaultCassa.code
            }}
            - {{ defaultCassa.type.description }}
          </div>
          <div class="col-span-2 flex items-center gap-1">
            <p class="font-bold mt-1 mb-1">Aliquota cassa:</p>
            {{
              defaultCassa.aliquota ? defaultCassa.aliquota : defaultCassa.vat
            }}
            su
            {{
              defaultCassa.onPerc
                ? defaultCassa.onPerc
                : defaultCassa.vat_on_percentage
            }}
            %
          </div>
          <div class="col-span-2 flex items-center gap-1">
            <p class="font-bold mt-1 mb-1">IVA:</p>
            {{
              defaultCassa.VAT.descrizione
                ? defaultCassa.VAT.descrizione
                : defaultCassa.VAT.vat_percentage + '%'
            }}
          </div>
        </div>
      </div>
    </div>

    <CassaModal
      v-if="showCassa == true"
      :is-coming-from-setup="true"
      :cassa-vecchia="defaultCassa"
      @close="toggleCassa"
      @saveDefault="defaultCassaFunction"
    />
    <!-- end Contributo -->

    <!-- start Pagamento -->

    <Acordeon
      id="pagamento"
      class="mb-1"
      title="Metodi di pagamento"
      :stato="false"
      :swich="false"
      :setup="false"
      :open="true"
      @handleOpen="openPagamento = !openPagamento"
      :openVar="openPagamento"
    />
    <div v-if="openPagamento">
      <div>
        <div class="table-container overflow-scroll scrollbar-none py-2 px-3">
          <table class="w-full">
            <thead class="">
              <tr>
                <th
                  v-for="item in paymentsTableHeader"
                  :key="item"
                  class="t-header bg-theme-44 text-theme-43"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(p, k) in paymentMethods"
                :key="k"
                class="h-12 text-xs font-normal hover:bg-theme-45"
                :class="{ 'bg-theme-2': k % 2 === 0 }"
              >
                <td class="text-center text-sm border-r-2 border-theme-46">
                  {{ p.alias }}
                </td>
                <td class="text-center text-sm border-r-2 border-theme-46">
                  {{ p.description }}
                </td>

                <td class="text-center text-sm border-r-2 border-theme-46">
                  {{ p.code }}
                </td>

                <td class="text-center text-sm border-r-2 border-theme-46">
                  <a class="mx-auto my-2">
                    <input
                      :id="p.id"
                      v-model="p.is_active"
                      type="checkbox"
                      class="form-check-switch"
                      @click="setActivePayment"
                    />
                  </a>
                </td>
                <td class="text-center text-sm border-r-2 border-theme-46">
                  <a class="mx-auto my-2">
                    <input
                      :id="p.id"
                      v-model="p.is_default"
                      type="checkbox"
                      class="form-check-switch"
                      @click="setDefaultPayment"
                    />
                  </a>
                </td>
                <td class="text-center text-sm border-r-2 border-theme-46">
                  <a
                    class="mx-auto my-2 cursor-pointer"
                    @click="deletePaymentMethods(p.id)"
                  >
                    <i class="fas fa-times text-center p-3" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex justify-end mt-4 px-4">
          <button type="button" class="orange-btn" @click="togglePagamento">
            <PlusIcon class="w-4 h-4" /> Aggiungi
          </button>
        </div>

        <PayModal
          v-if="openPayments"
          :default-payments="defaultPaymentMethods"
          :payments="paymentMethods"
          :coming-from-setup="true"
          @addPaymentSetup="addPaymentSetup"
          @close="togglePagamento"
        />
      </div>
    </div>

    <!-- end Pagamento -->
    <!-- start Prefissi -->
    <Acordeon
      v-if="false"
      id="sezionale"
      class="mb-1"
      title="Sezionale Fattura"
      :stato="false"
      :swich="false"
      :setup="false"
      :open="true"
      @handleOpen="openSezionale = !openSezionale"
      :openVar="openSezionale"
    />
    <div v-if="openSezionale" class="px-1 py-2 mb-2">
      <div>
        <div
          v-if="newSezionale"
          class="flex flex-col items-start text-left gap-2 mt-2 py-8 px-6 shadow-sm border-2 border-theme-2 rounded-xl bg-white"
        >
          <span>Sigla:</span>
          <input
            v-model="newSezionale.label"
            type="text"
            class="custom-input"
          />
          <span>Descrizione:</span>
          <input
            v-model="newSezionale.description"
            type="text"
            class="custom-input"
          />

          <button class="orange-btn" @click="saveSezionale">+ Aggiungi</button>
        </div>
        <div
          v-if="defaultRegimeFiscale"
          class="flex flex-col px-2 justify-center shadow-sm py-2 border-2 border-theme-2 rounded-xl bg-white"
        >
          <div class="p-2">
            <p
              class="text-base font-semibold mt-1 mb-2 bg-theme-2 p-2 rounded-md"
            >
              Sezionali presenti:
            </p>
          </div>
          <div
            v-for="sez in sezionali"
            :key="sez.id"
            class="col-span-3 flex flex-col gap-1 p-4 rounded-md bg-gray-200 my-1"
          >
            - {{ sez.label }} ({{ sez.description }})

            <div v-if="sez.clinic_id" class="flex">
              <p>Nascondi:</p>
              <input
                v-model="sez.hidden"
                type="checkbox"
                class="form-check-switch ml-1"
                @change="editSezionale(sez)"
              />
            </div>
            <div v-if="sez.clinic_id" class="flex flex-col">
              <p>Tipo emissione:</p>
              <v-select
                v-model="sez.type"
                :options="['elettronica', 'cartacea']"
                label="Tipo emissione"
                placeholder="Aggiungi il tipo di emissione"
                class="py-2 w-48"
                @option:selected="editSezionale(sez)"
              >
              </v-select>
            </div>
          </div>
          <button
            class="orange-btn"
            @click="
              () => {
                newSezionale = {}
              }
            "
          >
            Nuovo sezionale
          </button>
        </div>
      </div>
    </div>
    <!-- end Prefissi -->

 <!-- start scadenza -->
    <Acordeon
      id="scadenza"
      class="mb-1"
      title="Validità preventivo"
      :stato="false"
      :swich="false"
      :setup="false"
      :open="true"
      @handleOpen="openScadenzaP = !openScadenzaP"
      :openVar="openScadenzaP"
    />
    <div v-if="openScadenzaP" class="px-1 py-2 mb-2">
      <div class="flex items-center gap-2 mt-2 py-8 px-6 shadow-sm border-2 border-theme-2 rounded-xl bg-white">
        <p class="text-base font-semibold bg-theme-2 p-2 rounded-md w-full">
          Scadenza preventivo in giorni:
        </p>

        <input v-model="scadenzaP" type="number" min="0" max="360" class="custom-input">

        <button class="orange-btn" @click="saveScadenzaP">
          <i class="fas fa-save mr-1"></i>
        </button>

      </div>
    </div>
    <!-- end scadenza -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import PayModal from '../../components/new-invoice/PayModal.vue'
import CassaModal from '../../components/new-invoice/CassaModal.vue'
import RitenutaModal from '../../components/new-invoice/RitenutaModal.vue'
import BolloModal from '../../components/new-invoice/BolloModal.vue'
// import ScontoModal from '../../components/new-invoice/ScontoMaggiorazioneModal.vue'
import Acordeon from './AcordeonCustom.vue'
import CausaleModal from '../../components/new-invoice/CausaleModal.vue'
import NoteModal from '../../components/new-invoice/NoteModal.vue'
import axios from '../../gateway/backend-api'
import _ from 'lodash'

// import {
//   VueCollapsiblePanelGroup,
//   VueCollapsiblePanel
// } from '@swoga/vue-collapsible-panel'

const paymentsTableHeader = [
  'Alias',
  'Descrizione',
  'Codice',
  'Attivo',
  'Default',
  ''
]

export default defineComponent({
  name: 'DatiClinicaAruba',
  components: {
    // VueCollapsiblePanelGroup,
    // VueCollapsiblePanel,
    NoteModal,
    CausaleModal,
    BolloModal,
    PayModal,
    CassaModal,
    RitenutaModal,
    Acordeon
    // ScontoModal
  },
  props: {
    isComingFromSetup: Object,
    checkStatusDiscount: Object
  },
  emits: ['saveDefault'],

  data() {
    return {
      checkScMag: false,
      isSetup: false,
      paymentsTableHeader: paymentsTableHeader,
      vatSwitch: false,
      previdenzialeSwitch: false,
      ritenuteSwitch: false,
      bolloSwitch: false,
      paymentsSwitch: false,
      paymentsDefaultSwitch: false,
      causaleSwitch: false,
      scontoSwitch: false,
      scontoTypeSwitch: false,
      noteSwitch: false,
      openPayments: false,
      defaultPayment: {},
      defaultBollo: {},
      defaultCausale: {},
      defaultSconto: null,
      defaultScontoType: null,
      defaultNota: {},
      showCassa: false,
      showRitenute: false,
      defaultCassa: [],
      defaultRitenuta: [],
      // listVAT: []
      showModalBollo: false,
      showCausale: false,
      showSconto: false,
      showNote: false,
      openRegimeFiscale: false,
      openRitenute: false,
      openCausale: false,
      openNote: false,
      openIva: false,
      openBollo: false,
      openContributo: false,
      openPagamento: false,
      openScadenzaP: false,
      espandi: false,
      defaultRegimeFiscale: null,
      openSezionale: false,
      newSezionale: null,
      scadenzaP: null
    }
  },
  computed: {
    ...mapGetters({
      paymentMethods: 'reusableData/getPaymentMethods',
      defaultPaymentMethods: 'reusableData/getDefaultPaymentMethods',
      iva: 'reusableData/getShowIva',
      showRiepilogoStampa: 'reusableData/getShowRiepilogoStampa',
      sezionali: 'reusableData/getSezionali'
    })
  },
  watch: {
    causaleSwitch: function () {
      if (Object.keys(this.defaultCausale).length > 0) {
        if (this.defaultCausale.is_active != this.causaleSwitch) {
          var causaleStatus = {
            isActive: this.causaleSwitch,
            setup_type: 'causale',
            id: this.defaultCausale.id
          }

          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              causaleStatus
            )
            .then((res) => {
              this.defaultCausale = res.data[0]
            })
            .catch(function () {
              this.causaleSwitch = !this.causaleSwitch
            })
        }
      }
    },
    previdenzialeSwitch: function () {
      if (Object.keys(this.defaultCassa).length > 0) {
        if (this.defaultCassa.is_active != this.previdenzialeSwitch) {
          var cassaStatus = {
            isActive: this.previdenzialeSwitch,
            setup_type: 'cassa_previdenziale',
            id: this.defaultCassa.id
          }

          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              cassaStatus
            )
            .then((res) => {
              this.defaultCassa = res.data[0]
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
              this.$store.dispatch('reusableData/getClinicaSetup')
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Modifica effetuata con successo!'
              })
            })
            .catch(function () {
              this.previdenzialeSwitch = !this.previdenzialeSwitch
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        }
      }
    },
    // ritenuteSwitch: function () {
    //   if (Object.keys(this.defaultRitenuta).length > 0) {
    //     if (this.defaultRitenuta.is_active != this.ritenuteSwitch) {
    //       var ritenutaStatus = {
    //         isActive: this.ritenuteSwitch,
    //         setup_type: 'ritenuta',
    //         id: this.defaultRitenuta.id
    //       }

    //       axios
    //         .post(
    //           '/' + localStorage.getItem('clinic') + '/bills/default-setup',
    //           ritenutaStatus
    //         )
    //         .then((res) => {
    //           this.defaultRitenuta = res.data[0]
    //         })
    //         .catch(function () {
    //           this.ritenuteSwitch = !this.ritenuteSwitch
    //         })
    //     }
    //   }
    // },
    scontoSwitch: function () {
      if (Object.keys(this.defaultSconto).length > 0) {
        if (this.defaultSconto.is_active != this.scontoSwitch) {
          var scontoStatus = {
            isActive: this.scontoSwitch,
            setup_type: 'discount',
            id: this.defaultSconto.id
          }

          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              scontoStatus
            )
            .then((res) => {
              this.defaultSconto = res.data.discount[0].discount
            })
            .catch(function () {
              this.scontoSwitch = !this.scontoSwitch
            })
        }
      }
    },
    scontoTypeSwitch: function () {
      if (
        this.defaultScontoType &&
        Object.keys(this.defaultScontoType).length > 0
      ) {
        if (this.defaultScontoType.is_active != this.defaultScontoType) {
          var scontoTypeStatus = {
            isActive: this.scontoTypeSwitch,
            setup_type: 'discount',
            id: this.defaultScontoType.id
          }

          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              scontoTypeStatus
            )
            .then((res) => {
              this.defaultScontoType =
                res.data.percentage_discount[0].percentage_discount
            })
            .catch(function () {
              this.scontoTypeSwitch = !this.scontoTypeSwitch
            })
        }
      }
    },
    noteSwitch: function () {
      if (Object.keys(this.defaultNota).length > 0) {
        if (this.defaultNota.is_active != this.noteSwitch) {
          var noteStatus = {
            isActive: this.noteSwitch,
            setup_type: 'note_a_pie_pagina',
            id: this.defaultNota.id
          }

          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              noteStatus
            )
            .then((res) => {
              this.defaultNota = res.data[0]
            })
            .catch(function () {
              this.noteSwitch = !this.noteSwitch
            })
        }
      }
    },
    bolloSwitch: function () {
      if (Object.keys(this.defaultBollo).length > 0) {
        if (this.defaultBollo.is_active != this.bolloSwitch) {
          var bolloStatus = {
            isActive: this.bolloSwitch,
            setup_type: 'bollo',
            id: this.defaultBollo.id
          }

          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              bolloStatus
            )
            .then((res) => {
              this.defaultBollo = res.data[0]
            })
            .catch(function () {
              this.bolloSwitch = !this.bolloSwitch
            })
        }
      }
    },
    paymentsSwitch: function () {
      if (Object.keys(this.defaultPayment).length > 0) {
        if (this.defaultPayment.is_active != this.paymentsSwitch) {
          var paymentsStatus = {
            isActive: this.paymentsSwitch,
            setup_type: 'dati_pagamento',
            id: this.defaultPayment.id
          }

          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              paymentsStatus
            )
            .then((res) => {
              this.defaultPayment = res.data[0]
            })
            .catch(function () {
              this.paymentsSwitch = !this.paymentsSwitch
            })
        }
      }
    }
  },
  mounted() {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/default-setup')
      .then((res) => {
        if (res.data.causale.length > 0) {
          this.defaultCausale = res.data.causale[0]
          if (res.data.causale[0].is_active == true) {
            this.causaleSwitch = true
          }
        }
        if (res.data.regime_fiscale.length > 0) {
          this.defaultRegimeFiscale = res.data.regime_fiscale[0]
          console.log(this.defaultRegimeFiscale)
        }
        if (res.data.cassa_previdenziale.length > 0) {
          this.defaultCassa = res.data.cassa_previdenziale[0]
          if (res.data.cassa_previdenziale[0].is_active == true) {
            this.previdenzialeSwitch = true
          }
        }
        if (res.data.ritenuta.length > 0) {
          this.defaultRitenuta = res.data.ritenuta[0]
          if (res.data.ritenuta[0].is_active == true) {
            this.ritenuteSwitch = true
          }
        }
        if (res.data.bollo.length > 0) {
          this.defaultBollo = res.data.bollo[0]
          if (res.data.bollo[0].is_active == true) {
            this.bolloSwitch = true
          }
        }
        if (res.data.discount.length > 0) {
          this.defaultSconto = res.data.discount[0].discount
          if (this.defaultSconto) {
            this.scontoSwitch = true
          }
        }
        if (res.data.percentage_discount.length > 0) {
          this.defaultScontoType =
            res.data.percentage_discount[0].percentage_discount
          if (this.defaultScontoType) {
            this.scontoTypeSwitch = true
          }
        }
        if (res.data.note_a_pie_pagina.length > 0) {
          this.defaultNota = res.data.note_a_pie_pagina[0]
          if (res.data.note_a_pie_pagina[0].is_active == true) {
            this.noteSwitch = true
          }
        }
        if (res.data.dati_pagamento.length > 0) {
          this.defaultPayment = res.data.dati_pagamento[0]
          if (res.data.dati_pagamento[0].is_active == true) {
            this.paymentsSwitch = true
          }
        }

        if (res.data.validita_preventivo !== null) {
          this.scadenzaP = res.data.validita_preventivo;
        }
      })
  },
  created() {
    if (this.isComingFromSetup != null && this.isComingFromSetup == true) {
      this.isSetup = true
    }
    if (this.checkStatusDiscount != null) {
      if (this.checkStatusDiscount == true) {
        this.checkScMag = true
      } else {
        this.checkScMag = false
      }
    }
  },

  methods: {
    saveScadenzaP () {
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/default-setup', {
          scadenza_preventivo: this.scadenzaP
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
          this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Modifica effetuata con successo!'
            })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    saveSezionale() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/sezionali/store', {
          label: this.newSezionale.label,
          description: this.newSezionale.description,
          hidden: false
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    editSezionale(sez) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/sezionali/edit', {
          id: sez.id,
          hidden: sez.hidden,
          type: sez.type
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    toggleAll() {
      this.espandi = !this.espandi
      this.openRegimeFiscale = !this.openRegimeFiscale
      this.openRitenute = !this.openRitenute
      this.openCausale = !this.openCausale
      this.openNote = !this.openNote
      this.openIva = !this.openIva
      this.openBollo = !this.openBollo
      this.openContributo = !this.openContributo
      this.openPagamento = !this.openPagamento
      this.openScadenzaP = !this.openScadenzaP

      
    },
    saveRegime() {
      var regimeFiscale = {
        isActive: true,
        isDefault: true,
        setup_type: 'regime_fiscale',
        tax_regime_id: this.$refs.selectRegimeFiscale.value
      }

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          regimeFiscale
        )
        .then((res) => {
          if (res.status == 200) {
            this.defaultRegimeFiscale = res.data[0]
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.$store.dispatch('reusableData/getClinicaSetup')
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Modifica effetuata con successo!'
            })
          }
        })
        .catch(function () {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })

      this.openRegimeFiscale = false
    },

    saveIva() {
      this.openIva = false
    },
    saveDefaultSconto: function () {
      this.scontoSwitch = !this.scontoSwitch
      // var checkSconto = {
      //   discountStatus: this.checkScMag
      // }
      // this.$emit('saveDefault', checkSconto)

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/default-setup', {
          discountStatus: this.scontoSwitch
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Modifica effetuata con successo!'
          })
        })
        .catch(function () {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    saveDefaultScontoType: function () {
      this.scontoTypeSwitch = !this.scontoTypeSwitch
      // var checkSconto = {
      //   discountStatus: this.checkScMag
      // }
      // this.$emit('saveDefault', checkSconto)

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/default-setup', {
          discountTypeStatus: this.scontoTypeSwitch
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Modifica effetuata con successo!'
          })
        })
        .catch(function () {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    handleChangeIva() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/default-setup', {
          showPriceIva: !this.iva
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Modifica effetuata con successo!'
          })
        })
        .catch(function () {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    handleShowRiepilogoStampa() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/default-setup', {
          showRiepilogoStampa: !this.showRiepilogoStampa
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Modifica effetuata con successo!'
          })
        })
        .catch(function () {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    handleSwitchRitenuta() {
      this.ritenuteSwitch = !this.ritenuteSwitch
      if (Object.keys(this.defaultRitenuta).length > 0) {
        if (this.defaultRitenuta.is_active != this.ritenuteSwitch) {
          var ritenutaStatus = {
            isActive: this.ritenuteSwitch,
            setup_type: 'ritenuta',
            id: this.defaultRitenuta.id
          }

          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/default-setup',
              ritenutaStatus
            )
            .then((res) => {
              this.defaultRitenuta = res.data[0]
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
              this.$store.dispatch('reusableData/getClinicaSetup')
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Modifica effetuata con successo!'
              })
            })
            .catch(function () {
              this.ritenuteSwitch = !this.ritenuteSwitch
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        }
      }
    },
    setDefaultPayment(e) {
      var paymentsStatus = {
        isActive: true,
        isDefault: true,
        setup_type: 'dati_pagamento',
        id: e.target.id
      }

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          paymentsStatus
        )
        .then((res) => {
          if (res.status == 200) {
            this.paymentMethods.forEach((el) => {
              if (el.id != e.target.id) el.is_default = false
              else el.is_active = true
            })
          }
        })
    },
    setActivePayment(e) {
      var value = ''
      this.paymentMethods.forEach((el) => {
        if (el.id == e.target.id) {
          value = !el.is_active
          if (!value) el.is_default = false
        }
      })
      var paymentsStatus = {
        isActive: value,
        setup_type: 'dati_pagamento',
        id: e.target.id
      }

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          paymentsStatus
        )
        .then()
    },
    deletePaymentMethods(id) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler eliminare questo metodo di pagamento?',
        background: false,
        confirm: () => {
          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/bills/payments-delete',
              {
                id_payment: id
              }
            )
            .then((res) => {
              if (res.status == 200) {
                this.paymentMethods.filter((el) => {
                  if (_.isEqual(el.id, id)) _.remove(this.paymentMethods, el)
                })
              }
            })
        },
        decline: () => {
          console.log('Ho declinato la risposta')
        }
      })
    },
    toggleRitenute: function () {
      this.showRitenute = !this.showRitenute
    },
    toggleCassa: function () {
      this.showCassa = !this.showCassa
    },
    toggleCausale: function () {
      this.showCausale = !this.showCausale
    },
    toggleSconto: function () {
      this.showSconto = !this.showSconto
    },
    toggleNote: function () {
      this.showNote = !this.showNote
    },
    toggleBollo: function () {
      this.showModalBollo = !this.showModalBollo
    },
    togglePagamento: function () {
      this.openPayments = !this.openPayments
    },
    // Imposta i valori di default dei dati pagamento
    addPaymentSetup(value) {
      value.setup_type = 'dati_pagamento'
      value.isDefault = true
      if (this.paymentsSwitch == true) {
        value.isActive = true
      } else {
        value.isActive = false
      }
      var paymentToSend = value
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          paymentToSend
        )
        .then(() => {
          this.defaultPayment = value
          this.togglePagamento()
        })
    },
    addBolloSetup(value) {
      value.setup_type = 'bollo'
      value.isDefault = true
      if (this.bolloSwitch == true) {
        value.isActive = true
      } else {
        value.isActive = false
      }
      var bolloToSend = value

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          bolloToSend
        )
        .then(() => {
          this.defaultBollo = value
          this.toggleBollo()
        })
    },
    addCausaleSetup(value) {
      value.setup_type = 'causale'
      value.isDefault = true
      if (this.causaleSwitch == true) {
        value.isActive = true
      } else {
        value.isActive = false
      }
      var causaleToSend = value

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          causaleToSend
        )
        .then(() => {
          this.defaultCausale = value
          this.toggleCausale()
        })
    },
    addNoteSetup(valueText) {
      valueText.setup_type = 'note_a_pie_pagina'
      valueText.isDefault = true
      if (this.noteSwitch == true) {
        valueText.isActive = true
      } else {
        valueText.isActive = false
      }
      var noteToSend = valueText

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          noteToSend
        )
        .then(() => {
          this.defaultNota = valueText
          this.toggleNote()
        })
    },

    defaultCassaFunction: function (cassa) {
      cassa.setup_type = 'cassa_previdenziale'
      cassa.isDefault = true
      if (this.previdenzialeSwitch == true) {
        cassa.isActive = true
      } else {
        cassa.isActive = false
      }
      var cassaToSend = cassa
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          cassaToSend
        )
        .then(() => {
          this.defaultCassa = cassa
          this.toggleCassa()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Modifica effetuata con successo!'
          })
        })
        .catch(function () {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    defaultRitenutaFunction: function (ritenuta) {
      ritenuta.setup_type = 'ritenuta'
      ritenuta.isDefault = true
      if (this.ritenuteSwitch == true) {
        ritenuta.isActive = true
      } else {
        ritenuta.isActive = false
      }
      var ritenutaToSend = ritenuta
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          ritenutaToSend
        )
        .then(() => {
          this.defaultRitenuta = ritenuta
          this.toggleRitenute()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('reusableData/getClinicaSetup')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Modifica effetuata con successo!'
          })
        })
        .catch(function () {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    defaultScontoFunction: function (checkSconto) {
      checkSconto.setup_type = 'discount'
      checkSconto.isDefault = true
      if (this.scontoSwitch == true) {
        checkSconto.isActive = true
      } else {
        checkSconto.isActive = false
      }
      var scontoToSend = checkSconto

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/default-setup',
          scontoToSend
        )
        .then(() => {
          this.defaultSconto = checkSconto.discountStatus
          this.toggleSconto()
        })
    }
  }
})
</script>

<style>
.vcp__header-icon:hover {
  color: #ff8f1c;
}

.vcp__header-title {
  justify-content: center;
}

.rotate180 {
  transform: rotate(180deg);
}
</style>
