<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-20 p-2 mx-auto border h-auto sm:w-4/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col justify-between h-full">
        <!-- Modal header -->
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div class="flex items-start p-5 rounded-t">
          <h3 class="modal-title w-full mt-5">
            {{ title }}
          </h3>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <!-- INIZIO RIGA -->
          <div
            class="grid grid-cols-12 gap-2 rounded-md p-1 px-3 shadow-sm border border-theme-44 border-opacity-30 md:text-center items-end"
          >
            <div class="col-span-12 flex gap-1 justify-between items-end">
              <div class="flex-1 grid grid-cols-12 content-end gap-1">
                <div
                  v-if="vets"
                  class="col-span-12 md:col-span-6 lg:col-span-2 py-1"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <label class="form-label mb-0">Medico </label>
                    <v-select
                      v-model="line.performed_by"
                      :options="vets"
                      :reduce="(value) => value.id"
                      multiple
                      label="full_name"
                      placeholder="Seleziona veterinario"
                      class="w-full uppercase text-xs"
                    >
                      <template #selected-option="option">
                        {{ option.full_name }}
                      </template>
                      <template #option="option">
                        {{ option.full_name }}
                      </template>
                    </v-select>
                  </div>
                </div>
                <div
                  class="col-span-12 md:col-span-6 lg:col-span-3 h-full justify-end py-1"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <label class="form-label mb-0"> Descrizione</label>
                    <input
                      v-model="line.description"
                      class="custom-input"
                      type="text"
                      placeholder="Descrizione"
                    />
                  </div>
                </div>
                <div
                  class="col-span-12 sm:col-span-4 md:col-span-2 lg:col-span-1 h-full justify-end py-1"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <label class="form-label mb-0">Cod. </label>
                    <input
                      v-model="line.code"
                      class="custom-input"
                      type="text"
                      placeholder="Codice"
                    />
                  </div>
                </div>

                <div
                  class="col-span-12 sm:col-span-4 md:col-span-1 lg:col-span-1 h-full justify-end py-1"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <label class="form-label mb-0">Quantità</label>

                    <input
                      v-model="line.qty"
                      class="custom-input"
                      type="number"
                      placeholder="Quantità"
                      min="1"
                      @change="calculate()"
                    />
                  </div>
                </div>

                <div
                  class="h-full justify-end py-1 col-span-4 md:col-span-2 lg:col-span-1"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <label class="form-label mb-0">Prezzo</label>
                    <div
                      v-if="documento === 'preventivo'"
                      class="grid grid-cols-2 items-baseline relative w-full"
                    >
                      <span class="form-label col-span-1"> Netto</span>
                      <span class="form-label col-span-1"> Ivato</span>
                    </div>
                    <div class="flex items-baseline relative w-full gap-3">
                      <span class="form-label absolute top-1.5 left-1"> €</span>
                      <div
                        v-if="documento === 'preventivo' && iva"
                        class="appearance-none border border-gray-300 rounded w-full py-1.5 p-3 text-theme-48 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                      >
                        <span>
                          {{
                            Math.round((line.price + Number.EPSILON) * 100) /
                            100
                          }}
                        </span>
                      </div>
                      <input
                        v-else-if="iva"
                        v-model="line.priceIva"
                        class="custom-input pl-5"
                        type="number"
                        placeholder="Prezzo"
                        min="0.00"
                        step="0.01"
                        @change="calculate()"
                      />
                      <input
                        v-else
                        v-model="line.price"
                        class="custom-input pl-5"
                        type="number"
                        placeholder="Prezzo"
                        min="0.00"
                        step="0.01"
                        @change="calculate()"
                      />
                      <span
                        v-if="documento === 'preventivo'"
                        class="form-label absolute top-1.5 left-1"
                      >
                        €</span
                      >

                      <div
                        v-if="documento === 'preventivo' && !iva"
                        class="appearance-none border border-gray-300 rounded w-full py-1.5 p-3 text-theme-48 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                      >
                        <span>
                          {{
                            Math.round((line.priceIva + Number.EPSILON) * 100) /
                            100
                          }}
                        </span>
                      </div>
                      <input
                        v-else-if="documento === 'preventivo'"
                        v-model="line.priceIva"
                        class="custom-input pl-5"
                        type="number"
                        placeholder="Prezzo"
                        min="0.00"
                        step="0.01"
                        @change="calculate()"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="col-span-3 md:col-span-1 lg:col-span-1 h-full justify-end py-1"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <label class="form-label mb-0">Sc / Mg</label>
                    <div class="flex items-baseline relative w-full">
                      <input
                        v-model="line.sconto"
                        class="custom-input text-center"
                        type="number"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        step="0.0001"
                        maxlength="5"
                        @change="calculate()"
                        @keydown.enter.prevent=""
                      />
                      <span class="form-label absolute top-1.5 right-2">
                        %</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-span-3 md:col-span-2 lg:col-span-1 h-full justify-end py-1"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <label class="form-label">IVA</label>

                    <select
                      v-model="line.VAT"
                      class="custom-input mt-1"
                      @change="calculate()"
                    >
                      <option v-for="nn in listVAT" :key="nn.id" :value="nn">
                        {{ nn.vat_percentage + ' %' }}
                      </option>
                    </select>
                  </div>
                </div>

                <div
                  class="col-span-6 md:col-span-4 lg:col-span-2 justify-end py-1 grid grid-cols-2 gap-2"
                >
                  <div class="flex flex-col gap-1 h-full justify-end">
                    <span class="form-label mb-0 col-span-1"> Imp. Netto</span>
                    <div class="custom-input -mt-1">
                      €
                      <span>
                        {{
                          line.taxable_max
                            ? Math.round(
                                (line.taxable_max * 1 +
                                  // (cassa
                                  // ? cassa.vat
                                  // ? (cassa.vat + 100) / 100
                                  // : 1.02
                                  // : 1)
                                  Number.EPSILON) *
                                  100
                              ) / 100
                            : Math.round(
                                (line.taxable * 1 +
                                  // (cassa
                                  // ? cassa.vat
                                  // ? (cassa.vat + 100) / 100
                                  // : 1.02
                                  // : 1)
                                  Number.EPSILON) *
                                  100
                              ) / 100
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="flex flex-col gap-1 h-full justify-end">
                    <span class="form-label mb-0 col-span-1"> Imp. Ivato</span>
                    <div class="custom-input -mt-1">
                      <span>€</span
                      ><span>
                        {{
                          line.taxable_max
                            ? Math.round(
                                line.taxable_max *
                                  // (cassa
                                  // ? cassa.vat
                                  // ? (cassa.vat + 100) / 100
                                  // : 1.02
                                  // : 1) *
                                  1 *
                                  ((line.VAT.vat_percentage + 100) / 100) *
                                  100
                              ) / 100
                            : Math.round(
                                line.taxable *
                                  // (cassa
                                  // ? cassa.vat
                                  // ? (cassa.vat + 100) / 100
                                  // : 1.02
                                  // : 1) *
                                  1 *
                                  ((line.VAT.vat_percentage + 100) / 100) *
                                  100
                              ) / 100
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- FINE RIGA -->
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
        >
          <div class="px-6 flex items-center justify-end mt-3">
            <button
              type="button"
              class="orange-btn mr-3"
              @click="handleResponse()"
            >
              <PlusCircleIcon class="h-5 w-5 mr-2" />
              Salva
            </button>
            <button type="button" class="gray-btn" @click="$emit('close')">
              <span class="h-5"></span>
              Annulla
            </button>
          </div>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from '../../gateway/backend-api'

import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'

export default defineComponent({
  props: ['title'],
  emits: ['close', 'confirm'],
  setup(_, { emit }) {
    // ----- DATA -----
    const store = useStore()
    const vets = ref(null)

    const ivaPerc = computed(
      () => store.getters['reusableData/getRegimeFiscaleIvaPerc']
    )

    const showIva = computed(() => store.getters['reusableData/getShowIva'])

    const iva = computed(
      () => store.getters['reusableData/getRegimeFiscaleIva']
    )

    const listVAT = computed(() => store.getters['reusableData/getListVat'])

    let tempIva = listVAT.value[0]
    if (ivaPerc.value) {
      listVAT.value.forEach((el) => {
        if (el.vat_percentage === iva.value) tempIva = el
      })
    }
    const line = ref({
      code: null,
      description: '',
      qty: 1,
      price: null,
      priceIva: null,
      sconto: '',
      taxable: '',
      VAT: tempIva
    })

    // ----- METHODS -----

    const getStaff = () => {
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff', {
          roles: [3, 2]
        })
        .then((res) => {
          vets.value = res.data.map((el) => ({
            id: el.id,
            full_name: el.full_name
          }))
        })
    }

    const calculate = () => {
      if (showIva.value) {
        line.value.price =
          line.value.priceIva /
          (line.value.VAT && line.value.VAT.vat_percentage !== null
            ? (line.value.VAT.vat_percentage + 100) / 100
            : ivaPerc.value)
      } else {
        // if (line.value.newLine && this.iva === true)
        //   line.value.price =
        //     line.value.priceIva /
        //     (line.value.VAT &&
        //     line.value.VAT.vat_percentage
        //       ? (line.value.VAT.vat_percentage + 100) / 100
        //       : this.ivaPerc)
        // else {
        line.value.priceIva =
          Math.round(
            ((line.value.price * 1 +
              getArrotondamentoNetto(line.value.price * 1)) *
              (line.value.VAT && line.value.VAT.vat_percentage !== null
                ? (line.value.VAT.vat_percentage + 100) / 100
                : ivaPerc.value) +
              Number.EPSILON) *
              100000000
          ) / 100000000

        //}
      }
      let disc
      if (line.value.sconto == 0 || line.value.sconto == '') {
        disc = 0
      } else {
        disc = line.value.qty * ((line.value.price * line.value.sconto) / 100)
      }
      line.value.taxable =
        line.value.qty *
          // (this.cassa
          //   ? this.cassa.aliquota
          //     ? (this.cassa.aliquota + 100) / 100
          //     : 1.02
          //   : 1) *
          (line.value.price * 1) +
        getArrotondamentoNetto(line.value.price * 1) +
        disc
    }

    const getArrotondamentoNetto = (netto) => {
      let lordo_arrotondato =
        Math.round((netto * ivaPerc.value + Number.EPSILON) * 100) / 100
      return lordo_arrotondato / ivaPerc.value - netto
    }

    const handleResponse = () => {
      if (!line.value.description || line.value.description.trim() === '') {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la descrizione'
        })
        return
      }

      if (!line.value.qty || line.value.qty === '') {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la quantità'
        })
        return
      }

      if (!line.value.price || line.value.price === '') {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il prezzo'
        })
        return
      }

      line.value.status = 'libera'
      emit('confirm', line.value)
      emit('close')
    }

    // ----- COMPUTED -----

    onMounted(() => {
      getStaff()
    })

    return {
      line,
      listVAT,
      ivaPerc,
      showIva,
      iva,
      vets,
      getStaff,
      handleResponse,
      calculate,
      getArrotondamentoNetto
    }
  }
})
</script>
