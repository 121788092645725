<template>
  <div
    id="new-appointment-modal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      id="draggable-div"
      class="h-auto relative top-5 md:top-12 w-11/12 p-5 mx-auto mb-5 border md:w-3/5 shadow-lg rounded-md bg-white"
      draggable="true"
      @dragover.prevent
      @dragenter.prevent
      @dragstart="onDrag($event)"
      @drop="onDrop($event)"
      @dragend="onDrop($event)"
    >
      <button type="button" class="closing-btn" @click="closeModal">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div class="mx-auto rounded-2xl p-1 mt-6">
        <!-- BEGIN: Modal Header -->

        <div
          id="new-appointment-titolo"
          class="mx-auto justify-center my-1 bg-white border-b-1 border-gray-400"
        >
          <h2 class="font-bold text-center text-xl modal-title">
            {{ modalAppointmentTitle }}
          </h2>
        </div>

        <!-- END: Modal Header -->
        <div
          :class="[
            isQuick
              ? 'h-auto '
              : 'ricerca_avanzata-cont overflow-auto scrollbar_custom'
          ]"
          class="mx-auto p-2 rounded-2xl"
        >
          <!-- BEGIN: Modal Body -->
          <div class="">
            <div v-if="isQuick" class="">
              <label
                for="new-appointment-motivo-appuntamento"
                class="font-medium form-label"
                >Motivo dell'appuntamento</label
              >
              <!-- id prima era modal-form-1 -->
              <input
                id="new-appointment-motivo-appuntamento"
                ref="input1"
                v-model="newAppointment.title"
                name="motivo"
                type="text"
                maxlength="128"
                class="custom-input mt-1"
                :class="{
                  notapproved: !inputcontrol.title
                }"
                required
                value
                placeholder="Motivo dell'appuntamento"
                :disabled="preview"
                @keyup.enter="switchFocus(2)"
                @keyup.tab="switchFocus(2)"
              />
              <span v-if="!inputcontrol.title" class="text-theme-41 mt-1">
                Questo è un campo obbligatorio</span
              >

              <div class="col-span-1 py-2">
                <label for="new-appointment-note" class="font-medium form-label"
                  >Note</label
                >
                <textarea
                  id="new-appointment-note"
                  ref="input8"
                  v-model="newAppointment.description"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-28"
                  placeholder="Note dell'appuntamento"
                  :disabled="preview"
                  @keyup.enter="switchFocus(9)"
                  @keyup.left="switchFocus(7)"
                />
              </div>

              <label for="new-appointment-vet" class="font-medium form-label"
                >Veterinario</label
              >
              <div v-if="isQuick" id="multiple-select-vet" class="py-2">
                <div class="appuntamenti-select">
                  <v-select
                    ref="input10"
                    v-model="newAppointment.staff_users"
                    :options="vets"
                    :class="{
                      notapproved: !inputcontrol.staff_users
                    }"
                    label="last_name"
                    multiple
                    placeholder="Seleziona veterinario"
                    :disabled="preview"
                    @keyup.enter="switchFocus(11)"
                    @keyup.left="switchFocus(9)"
                  >
                    <template #selected-option="option">
                      {{ option.last_name }} {{ option.first_name }} 
                    </template>
                    <template #option="option">
                      {{ option.last_name }} {{ option.first_name }} 
                    </template>
                  </v-select>
                  <span
                    v-if="!inputcontrol.staff_users"
                    class="text-theme-41 mt-1"
                  >
                    Questo è un campo obbligatorio</span
                  >
                </div>
              </div>
              <label for="new-appointment-vet" class="font-medium form-label"
                >Durata</label
              >
              <div id="multiple-select-vet" class="py-2">
                <div class="appuntamenti-select">
                  <v-select
                    v-model="newAppointment.durata"
                    :options="[0.5, 1, 2, 3, 4, 5]"
                    placeholder=""
                    :disabled="preview"
                  >
                    <template #selected-option="option">
                      {{ option.label >= 1 ? option.label : '30 MINUTI' }}
                      {{
                        option.label >= 1
                          ? option.label === 1
                            ? 'ORA'
                            : 'ORE'
                          : ''
                      }}
                    </template>
                    <template #option="option">
                      {{ option.label >= 1 ? option.label : '30 MINUTI' }}
                      {{
                        option.label >= 1
                          ? option.label === 1
                            ? 'ORA'
                            : 'ORE'
                          : ''
                      }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div
              v-if="!isQuick && !comingFromClient && !preview"
              class="py-3 flex flex-col items-center gap-3 sm:flex-row md:justify-end"
            >
              <button type="button" class="orange-btn" @click="search = true">
                <SearchIcon class="h-5 w-5 mr-2" />Ricerca Anagrafica
              </button>

              <a
                class="cursor-pointer orange-btn-inverted"
                @click="search = false"
              >
                <PlusIcon class="h-5 w-5 mr-2" />Nuova Anagrafica
              </a>
            </div>
            <div v-if="!isQuick" class="mb-2">
              <div v-if="search" class="pt-3">
                <label
                  for="new-appointment-cliente"
                  class="font-medium form-label"
                  >Nome del Cliente</label
                >
                <!-- id prima era modal-form-1 -->
                <div class="mt-1">
                  <div class="flex gap-1 justify-between relative">
                    <input
                      id="new-appointment-cliente"
                      ref="input2"
                      v-model="newAppointment.client"
                      type="text"
                      autocomplete="off"
                      class="flex-1 custom-input"
                      :class="[!inputcontrol.client ? 'notapproved' : '']"
                      placeholder="Nome del cliente"
                      value
                      required
                      :disabled="preview"
                      @click.stop="dropDownSearchCustomer"
                      @keyup="keyOnCustomer()"
                      @keyup.enter="switchFocus(3)"
                      @keyup.left="switchFocus(1)"
                    />
                    <div v-if="isCustomer0" class="flex-0">
                      <button
                        v-if="!footerModalAppointment"
                        type="button"
                        class="orange-btn"
                        @click="goToCustomer"
                      >
                        <FileIcon class="w-4 h-5 mr-1" />Scheda Cliente
                        <!-- <ClockIcon class="w-4 h-4 mr-2" />Appuntamento Rapido -->
                      </button>
                    </div>
                  </div>
                  <div class="">
                    <SearchDrop
                      v-if="isActiveSearchCustomer"
                      class="z-50 w-96 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 left-1/2 top-56"
                      :filter-categories="['customer']"
                      :pet-invoice="petData"
                      :filter="newAppointment.client"
                      @closeDropDownSearch="isActiveSearchCustomer = false"
                      @childToParent="onChildClick"
                    />
                  </div>
                </div>
                <span v-if="!inputcontrol.client" class="text-theme-41 mt-1">
                  Questo è un campo obbligatorio</span
                >
              </div>

              <div v-if="search" class="py-3">
                <label
                  for="new-appointment-animale"
                  class="font-medium form-label"
                  >Nome dell'animale</label
                >
                <!-- id prima era modal-form-1 -->
                <div class="mt-1">
                  <div class="flex gap-1 justify-between relative">
                    <input
                      id="new-appointment-animale"
                      ref="input3"
                      v-model="newAppointment.pet"
                      type="text"
                      class="flex-1 custom-input"
                      autocomplete="off"
                      placeholder="Nome dell'animale"
                      :class="{
                        notapproved: !inputcontrol.pet
                      }"
                      :disabled="preview"
                      value
                      required
                      @click.stop="dropDownSearchPet"
                      @keyup="keyOnPet()"
                      @keyup.enter="switchFocus(4)"
                      @keyup.left="switchFocus(2)"
                    />
                    <div v-if="!isPet0" class="flex-0">
                      <button
                        v-if="footerModalAppointment"
                        type="button"
                        class="orange-btn"
                        @click="goToPet"
                      >
                        <FileIcon class="w-4 h-5 mr-1" />Scheda Animale
                        <!-- <ClockIcon class="w-4 h-4 mr-2" />Appuntamento Rapido -->
                      </button>
                    </div>
                  </div>
                  <div class="">
                    <SearchDrop
                      v-if="isActiveSearchPet"
                      class="z-50 w-96 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 py-4 px-3 left-1/2 top-72"
                      :filter-categories="['pet']"
                      :customer-invoice="clientData"
                      :filter="newAppointment.pet"
                      :search-in-appointment="true"
                      :pet-types="petTypes"
                      @newPet="savePet"
                      @closeDropDownSearch="isActiveSearchPet = false"
                      @childToParent="onChildClick"
                    />
                  </div>
                </div>
                <span v-if="!inputcontrol.pet" class="text-theme-41 mt-1">
                  Questo è un campo obbligatorio</span
                >
              </div>
              <div v-if="!search" class="py-3">
                <div class="flex">
                  <div class="flex-1 mr-2">
                    <label
                      for="new-appointment-cliente_first"
                      class="font-medium form-label"
                      >Nome del nuovo Cliente</label
                    >
                    <div class="mt-1">
                      <div class="flex gap-1 justify-between relative">
                        <input
                          id="new-appointment-cliente_first"
                          v-model="newAppointment['new_data']['first_name']"
                          type="text"
                          autocomplete="off"
                          class="flex-1 custom-input"
                          placeholder="Nome del cliente"
                          :disabled="preview"
                        />
                      </div>
                      <span
                        v-if="!inputcontrol.new_client_name"
                        class="text-theme-41 mt-1"
                      >
                        Questo è un campo obbligatorio</span
                      >
                    </div>
                  </div>
                  <div class="flex-1">
                    <label
                      for="new-appointment-cliente_last"
                      class="font-medium form-label"
                      >Cognome del nuovo Cliente</label
                    >
                    <div class="mt-1">
                      <div class="flex gap-1 justify-between relative">
                        <input
                          id="new-appointment-cliente_last"
                          v-model="newAppointment['new_data']['last_name']"
                          type="text"
                          autocomplete="off"
                          class="flex-1 custom-input"
                          placeholder="Cognome del cliente"
                          :disabled="preview"
                        />
                      </div>
                      <span
                        v-if="!inputcontrol.new_client_surname"
                        class="text-theme-41 mt-1"
                      >
                        Questo è un campo obbligatorio</span
                      >
                    </div>
                  </div>
                </div>

                <div class="flex my-1">
                  <div class="flex-1 mr-2">
                    <label
                      for="new-appointment-animale"
                      class="font-medium form-label"
                      >Nome del nuovo Animale</label
                    >
                    <div class="mt-1">
                      <div class="flex gap-1 justify-between relative">
                        <input
                          id="new-appointment-animale"
                          v-model="newAppointment['new_data']['pet_name']"
                          type="text"
                          autocomplete="off"
                          class="flex-1 custom-input"
                          placeholder="Nome dell'animale"
                        />
                      </div>
                      <span
                        v-if="!inputcontrol.new_pet_name"
                        class="text-theme-41 mt-1"
                      >
                        Questo è un campo obbligatorio</span
                      >
                    </div>
                  </div>
                  <div class="flex-1 appuntamenti-select">
                    <label
                      for="new-appointment-animale"
                      class="font-medium form-label"
                      >Specie del nuovo Animale</label
                    >
                    <v-select
                      v-model="newAppointment['new_data']['pet_type_id']"
                      class="flex-1 w-full"
                      label="name"
                      :reduce="(value) => value.id"
                      placeholder="Selezionare la specie"
                      :options="petTypes"
                    ></v-select>
                    <span
                      v-if="!inputcontrol.new_pet_breed"
                      class="text-theme-41 mt-1"
                    >
                      Questo è un campo obbligatorio</span
                    >
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                <div class="col-span-1 py-2">
                  <label
                    for="new-appointment-data-inizio"
                    class="font-medium form-label text-sm focus:ring-1 data"
                    >Data di inizio</label
                  >

                  <input
                    id="new-appointment-data-inizio"
                    ref="input4"
                    v-model="newAppointment.datainizio"
                    type="text"
                    class="date mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    :class="{
                      notapproved: !inputcontrol.datainizio
                    }"
                    placeholder="Seleziona data dell'appuntamento"
                    :disabled="preview"
                    @keyup.enter="switchFocus(5)"
                    @keyup.space="switchFocus(3)"
                  />
                  <span
                    v-if="!inputcontrol.datainizio"
                    class="text-theme-41 mt-1"
                  >
                    Questo è un campo obbligatorio</span
                  >
                </div>
                <div class="col-span-1 py-2">
                  <label
                    for="new-appointment-data-fine"
                    class="font-medium form-label text-sm focus:ring-1 data"
                    >Data di fine</label
                  >
                  <input
                    id="new-appointment-data-fine"
                    ref="input5"
                    v-model="newAppointment.datafine"
                    :class="{
                      notapproved: !inputcontrol.datafine
                    }"
                    type="text"
                    class="date mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Seleziona data dell'appuntamento"
                    :disabled="preview"
                    @keyup.enter="switchFocus(6)"
                    @keyup.left="switchFocus(4)"
                  />
                  <span
                    v-if="!inputcontrol.datafine"
                    class="text-theme-41 mt-1"
                  >
                    Questo è un campo obbligatorio</span
                  >
                </div>

                <div class="col-span-1 py-2">
                  <label
                    for="new-appointment-ora-inizio"
                    class="font-medium form-label"
                    >Ora di inizio</label
                  >

                  <input
                    id="new-appointment-ora-fine"
                    ref="input6"
                    v-model="newAppointment.orainizio"
                    :class="{
                      notapproved: !inputcontrol.orainizio
                    }"
                    type="text"
                    class="hour mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Seleziona Ora di fine"
                    :disabled="preview"
                    @keyup.enter="switchFocus(7)"
                    @keyup.left="switchFocus(5)"
                  />
                </div>
                <div class="col-span-1 py-2">
                  <label
                    for="new-appointment-ora-fine"
                    class="font-medium form-label"
                    >Ora di fine</label
                  >

                  <input
                    id="new-appointment-ora-fine"
                    ref="input7"
                    v-model="newAppointment.orafine"
                    :class="{
                      notapproved: !inputcontrol.orafine
                    }"
                    type="text"
                    class="hour mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Seleziona Ora di fine"
                    :disabled="preview"
                    @keyup.enter="switchFocus(8)"
                    @keyup.left="switchFocus(6)"
                  />
                </div>
              </div>
              <div class="col-span-1">
                <label
                  for="new-appointment-motivo-appuntamento"
                  class="font-medium form-label"
                  >Motivo dell'appuntamento</label
                >
                <!-- id prima era modal-form-1 -->
                <input
                  id="new-appointment-motivo-appuntamento"
                  ref="input1"
                  v-model="newAppointment.title"
                  name="motivo"
                  type="text"
                  maxlength="128"
                  class="custom-input mt-1"
                  :class="{
                    notapproved: !inputcontrol.title
                  }"
                  required
                  value
                  placeholder="Motivo dell'appuntamento"
                  :disabled="preview"
                  @keyup.enter="switchFocus(2)"
                  @keyup.tab="switchFocus(2)"
                />
                <span v-if="!inputcontrol.title" class="text-theme-41 mt-1">
                  Questo è un campo obbligatorio</span
                >
              </div>

              <div class="col-span-1 py-2">
                <label for="new-appointment-note" class="font-medium form-label"
                  >Note</label
                >
                <textarea
                  id="new-appointment-note"
                  ref="input8"
                  v-model="newAppointment.description"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-28"
                  placeholder="Note dell'appuntamento"
                  :disabled="preview"
                  @keyup.enter="switchFocus(9)"
                  @keyup.left="switchFocus(7)"
                />
              </div>
              <div class="col-span-1">
                <div class="flex flex-col md:flex-row mb-2 gap-4">
                  <!-- BEGIN: Multiple Select for rooms  -->
                  <div class="flex-1">
                    <label
                      for="new-appointment-sala"
                      class="font-medium form-label"
                      >Sala</label
                    >
                    <div class="py-2 appuntamenti-select">
                      <v-select
                        ref="input9"
                        v-model="newAppointment.clinic_rooms"
                        multiple
                        :options="rooms"
                        label="name"
                        placeholder="Seleziona sala"
                        :disabled="preview"
                        @keyup.enter="switchFocus(10)"
                        @keyup.left="switchFocus(8)"
                      >
                        <template #option="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- END: Multiple Select for rooms -->

                  <!-- BEGIN: Multiple Select for doctors -->
                  <div class="flex-1">
                    <label
                      for="new-appointment-vet"
                      class="font-medium form-label"
                      >Veterinario</label
                    >
                    <div id="multiple-select-vet" class="py-2">
                      <div class="appuntamenti-select">
                        <v-select
                          ref="input10"
                          v-model="newAppointment.staff_users"
                          :options="vets"
                          :class="{
                            notapproved: !inputcontrol.staff_users
                          }"
                          label="last_name"
                          multiple
                          placeholder="Seleziona veterinario"
                          :disabled="preview"
                          @keyup.enter="switchFocus(11)"
                          @keyup.left="switchFocus(9)"
                        >
                          <template #selected-option="option">
                            {{ option.last_name }} {{ option.first_name }} 
                          </template>
                          <template #option="option">
                            {{ option.last_name }} {{ option.first_name }} 
                          </template>
                        </v-select>
                        <span
                          v-if="!inputcontrol.staff_users"
                          class="text-theme-41 mt-1"
                        >
                          Questo è un campo obbligatorio</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- END: Multiple Select for doctors -->
                </div>

                <!-- Appuntamento presso da -->

                <div
                  v-if="appointmentData.staff_users_edited"
                  class="flex justify-end"
                >
                  <h3 class="font-bold mr-2 text-xs">
                    Appuntamento modificato da:
                  </h3>
                  <div class="text-xs">
                    {{ appointmentData.staff_users_edited.first_name }}
                    {{ appointmentData.staff_users_edited.last_name }}
                  </div>
                </div>

                <div
                  v-if="appointmentData.staff_users_registered"
                  class="flex justify-end"
                >
                  <h3 class="font-bold mr-2 text-xs">
                    Appuntamento creato da:
                  </h3>
                  <div class="text-xs">
                    {{ appointmentData.staff_users_registered.first_name }}
                    {{ appointmentData.staff_users_registered.last_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
        <!-- BEGIN: Modal Footer -->
        <div
          v-if="!footerModalAppointment"
          id="new-appointment-footer-add-event"
          class="border-t-1 border-gray-400 text-right"
        >
          <div class="my-2">
            <button
              v-if="!preview"
              id="new-appointment-aggiungi-button"
              ref="input11"
              type="button"
              class="orange-btn"
              @keyup.enter="switchFocus(12)"
              @click="saveAppointment()"
            >
              <PlusCircleIcon class="h-5 w-5 mr-2" />Aggiungi appuntamento
            </button>

            <button type="button" class="gray-btn ml-3" @click="closeModal">
              <span class="h-5" />
              Annulla
            </button>
          </div>
        </div>
        <!-- END: Modal Footer -->

        <!-- footer that will appear when the events gets clicked -->

        <div
          v-if="footerModalAppointment"
          id="new-appointment-footer-aggiorna-event"
          class="border-t-1 border-gray-400 text-right"
        >
          <div class="my-2">
            <button
              v-if="isQuick"
              id="new-appointment-aggiungi-button"
              ref="input11"
              type="button"
              class="orange-btn mr-2"
              @keyup.enter="switchFocus(12)"
              @click="changeAppointment()"
            >
              <PlusCircleIcon class="h-5 w-5 mr-2" />Appuntamento Standard
            </button>
            <button
              id="new-appointment-aggiorna-button"
              type="button"
              data-dismiss="modal"
              class="orange-btn-inverted"
              @click="saveAppointment()"
            >
              <Edit3Icon class="w-4 h-4 mr-2" />Aggiorna
            </button>
            <button
              v-if="
                permissions &&
                permissions.agenda &&
                permissions.agenda.delete_agenda &&
                permissions.agenda.delete_agenda.enabled === true
              "
              id="new-appointment-delete-button"
              type="button"
              class="orange-btn flex items-center gap-1"
              @click="openConfirmDelete"
            >
              <i class="fas fa-trash fill-current"></i>Cancella
            </button>
            <button
              id="new-appointment-annulla-button"
              type="button"
              data-dismiss="modal"
              class="gray-btn"
              @click="closeModal"
            >
              <span class="h-4" />
              Annulla
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
  <div v-if="openWarning"><Warning @closeWarning="closeWarning" /></div>
</template>

<!--suppress JSValidateTypes -->
<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import SearchDrop from '../../components/search-drop/SearchDrop.vue'

import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import Warning from '../../views/nuovo-cliente/Warning.vue'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: { SearchDrop, Warning },
  props: [
    'footerModalAppointment',
    'modalAppointmentTitle',
    'appointmentData',
    'comingFromClient',
    'pet',
    'enableDrag',
    'preview'
  ],
  emits: ['openConfirmModal', 'close', 'render'],
  data() {
    return {
      petTypes: [],
      isQuick: false,
      isPet0: false,
      isCustomer0: false,
      dataToComplete: true,
      openWarning: false,
      inputcontrol: {
        client: true,
        pet: true,
        orainizio: true,
        orafine: true,
        datainizio: true,
        datafine: true,
        title: true,
        staff_users: true,
        new_client_name: true,
        new_client_surname: true,
        new_pet_name: true,
        new_pet_breed: true
      },
      vet_fullname: [],
      veterinari: [],
      clientData: [],
      petData: [],
      search: true,
      isActiveSearchCustomer: false,
      isActiveSearchPet: false,
      newAppointment: {
        id: '',
        title: '',
        client: '',
        customer_id: '',
        pet_id: '',
        pet: '',
        datainizio: '',
        datafine: '',
        orainizio: '',
        orafine: '',
        description: '',
        clinic_rooms: [],
        staff_users: [],
        color: '',
        new_data: {
          first_name: '',
          last_name: '',
          pet_name: ''
        },
        durata: 1
      },
      vets: [],
      rooms: []
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer',
      user: 'getUser',
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {
    'newAppointment.durata': function (elem) {
      console.log('durata', elem)
      if (elem && this.isQuick)
        this.newAppointment.orafine = this.$FormatDateMin(
          this.newAppointment['start_datetime'] + 3600 * elem
        ).substring(10)
      // if (this.newAppointment['start_datetime']) {
      //   this.newAppointment['end_datetime'] =
      //     this.newAppointment['start_datetime'] + 3600 * elem
      // }
    },
    'newAppointment.datainizio': function (elem) {
      if (this.newAppointment['datafine'] === '') {
        this.newAppointment['datafine'] = elem
      }
    },
    'newAppointment.pet': function (elem) {
      if (elem === '') {
        if (this.newAppointment.client == '') {
          // Se non avevo selezionato il cliente cancello il clientData da passare nella searchDrop
          this.clientData = null
        }
        this.petData = null
      }
    },
    'newAppointment.client': function (elem) {
      if (elem == '') {
        if (this.newAppointment.pet === '') {
          // Se non avevo selezionato l'animale cancello il petData da passare nella searchDrop
          this.petData = null
        }
        this.clientData = null
      }
    },
    search: function (elem) {
      if (elem) {
        this.newAppointment['new_data'] = {
          first_name: '',
          last_name: '',
          pet_name: ''
        }
      } else {
        this.newAppointment.client = ''
        this.newAppointment.pet = ''
        this.clientData = null
        this.petData = null
      }
    }
  },

  mounted() {
    this.getPetTypes()
    if (this.comingFromClient) {
      this.newAppointment.client =
        this.comingFromClient.last_name + ' ' + this.comingFromClient.first_name
      this.newAppointment.customer_id = this.comingFromClient.id
      this.clientData = this.comingFromClient
    }
    //funzione per chiamata veterinari
    axios.post('/' + localStorage.getItem('clinic') + '/staff').then((res) => {
      this.vets = res.data
    })

    axios
      .get('/' + localStorage.getItem('clinic') + '/departments/clinic-room')
      .then((res) => {
        this.rooms = res.data
      })

    if (this.$refs.input1) this.$refs.input1.focus()
    //document.addEventListener('click', this.closeDropDownSearchCustomer)
    //document.addEventListener('click', this.closeDropDownSearchPet)

    flatpickr.localize(Italian)

    flatpickr('.date', {
      plugins: [
        require('shortcut-buttons-flatpickr')({
          button: [
            {
              label: 'Oggi'
            },
            {
              label: 'Domani'
            },
            { label: 'Chiudi' }
          ],

          label: '',
          onClick: (index, fp) => {
            let date
            switch (index) {
              case 0:
                date = new Date()
                break
              case 1:
                date = new Date(Date.now() + 24 * 60 * 60 * 1000)
                break

              case 2:
                fp.close()
                break
            }
            fp.setDate(date)
          }
        })
      ],
      minDate: 'today',
      maxDate: new Date().fp_incr(3652.5),
      dateFormat: 'd/m/Y'
      // mode: 'multiple'
    })
    flatpickr('.hour', {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true
      // mode: 'multiple'
    })
  },

  created() {
    if (this.pet) {
      console.log('pet')
      this.petData = this.pet
      this.newAppointment.pet = this.pet.name
      this.newAppointment.pet_id = this.pet.id
      // if (this.appointmentData && this.appointmentData.customer) {
      //   this.clientData = this.appointmentData.customer
      // }
      if (this.pet.owners && this.pet.owners[0]) {
        this.clientData = this.pet.owners[0]
        this.newAppointment.client =
          this.pet.owners[0].last_name + ' ' + this.pet.owners[0].first_name
        this.newAppointment.customer_id = this.pet.owners[0].id
      }
    }
    if (this.appointmentData?.isQuick) {
      this.isQuick = true
    }
    if (
      this.appointmentData?.start_datetime != null &&
      this.appointmentData?.end_datetime != null
    ) {
      this.newAppointment = {
        ...this.appointmentData,
        new_data: {
          first_name: '',
          last_name: '',
          pet_name: ''
        },
        durata:  (this.appointmentData?.end_datetime - this.appointmentData?.start_datetime) / 3600
      }
      this.newAppointment.datainizio = this.$FormatDateCalendarEpoch(
        this.appointmentData.start_datetime
      )
      this.newAppointment.datafine = this.$FormatDateCalendarEpoch(
        this.appointmentData.end_datetime
      )
      this.newAppointment.orainizio = this.$FormatDateMin(
        this.appointmentData.start_datetime
      ).substring(10)
      this.newAppointment.orafine = this.$FormatDateMin(
        this.appointmentData.end_datetime
      ).substring(10)
    }
    if (this.appointmentData.id != null) {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/pet/' +
            this.appointmentData.pet_id
        )
        .then((res) => {
          if (
            this.appointmentData.pet_id &&
            this.appointmentData.pet_id !== 0
          ) {
            this.isPet0 = false
            this.newAppointment.pet = res.data.name
          } else {
            this.isPet0 = true
          }
        })
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/customer/' +
            this.appointmentData.customer_id
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.isCustomer0 = false
            this.newAppointment.client =
              (res.data.last_name ?? '') +
              ' ' +
              (res.data.first_name ?? '') +
              ' ' +
              (res.data.ragione_sociale ?? '')
          } else {
            this.isCustomer0 = true
          }
        })
    } else {
      if (this.newAppointment.title == 'Appuntamento Rapido') {
        this.isQuick = true
      }
    }
  },

  methods: {
    changeAppointment() {
      this.isQuick = false
    },
    getPetTypes() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
        .then((res) => {
          this.petTypes = res.data
        })
    },
    closeWarning() {
      this.openWarning = false
    },
    checkForm() {
      this.formValidation()
      this.falseInputsControl()
    },
    formValidation() {
      // if (
      //   this.newAppointment['customer_id'] != 0 &&
      //   this.newAppointment['customer_id'] != undefined &&
      //   this.search === true
      // ) {
      //   switch (this.newAppointment['client'].length) {
      //     case null:
      //     case 0:
      //       this.inputcontrol.client = false
      //       break
      //     default:
      //       this.inputcontrol.client = true
      //   }
      //   switch (this.newAppointment['customer_id'].length) {
      //     case null:
      //     case 0:
      //       this.inputcontrol.client = false
      //       break
      //     default:
      //       this.inputcontrol.client = true
      //   }
      // }

      if (!this.isQuick) {
        if (
          (!this.newAppointment.customer_id ||
            this.newAppointment.customer_id === '') &&
          this.search
        ) {
          this.inputcontrol.client = false
        } else {
          this.inputcontrol.client = true
        }

        if (
          (!this.newAppointment.pet_id || this.newAppointment.pet_id === '') &&
          this.search
        ) {
          this.inputcontrol.pet = false
        } else {
          this.inputcontrol.pet = true
        }

        if (
          this.newAppointment['pet_id'] != 0 &&
          this.newAppointment['pet_id'] != undefined &&
          this.search === true
        ) {
          switch (this.newAppointment['pet'].length) {
            case null:
            case 0:
              this.inputcontrol.pet = false
              break
            default:
              this.inputcontrol.pet = true
          }
          switch (this.newAppointment['pet_id'].length) {
            case null:
            case 0:
              this.inputcontrol.pet = false
              break
            default:
              this.inputcontrol.pet = true
          }
        }

        if (this.search === false) {
          if (this.newAppointment.new_data) {
            if (
              !this.newAppointment.new_data.first_name ||
              this.newAppointment.new_data.first_name === ''
            )
              this.inputcontrol.new_client_name = false
            else this.inputcontrol.new_client_name = true
            if (
              !this.newAppointment.new_data.last_name ||
              this.newAppointment.new_data.last_name === ''
            )
              this.inputcontrol.new_client_surname = false
            else this.inputcontrol.new_client_surname = true
            if (
              !this.newAppointment.new_data.pet_name ||
              this.newAppointment.new_data.pet_name === ''
            )
              this.inputcontrol.new_pet_name = false
            else this.inputcontrol.new_pet_name = true
            if (!this.newAppointment.new_data.pet_type_id)
              this.inputcontrol.new_pet_breed = false
            else this.inputcontrol.new_pet_breed = true
          } else {
            this.inputcontrol.new_client_name = false
            this.inputcontrol.new_client_surname = false
            this.inputcontrol.new_pet_name = false
            this.inputcontrol.new_pet_breed = false
          }
        } else {
          this.inputcontrol.new_client_name = true
          this.inputcontrol.new_client_surname = true
          this.inputcontrol.new_pet_name = true
          this.inputcontrol.new_pet_breed = true
        }
      }

      switch (this.newAppointment['datainizio'].length) {
        case null:
        case 0:
          this.inputcontrol.datainizio = false
          break
        default:
          this.inputcontrol.datainizio = true
      }
      switch (this.newAppointment['datafine'].length) {
        case null:
        case 0:
          this.inputcontrol.datafine = false
          break
        default:
          this.inputcontrol.datafine = true
      }
      if (this.newAppointment['title'] && this.newAppointment['title'] !== '') {
        this.inputcontrol.title = true
      } else {
        this.inputcontrol.title = false
      }
    },
    falseInputsControl() {
      var obj = this.inputcontrol

      const isAnyKeyValueFalse = (obj) =>
        !!Object.keys(obj).find((k) => !obj[k])

      if (isAnyKeyValueFalse(obj) == true) {
        document.getElementById('new-appointment-titolo').scrollIntoView()
        this.dataToComplete = true
        this.openWarning = true
      } else {
        this.dataToComplete = false
      }
    },
    savePet(pet) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/pet', pet)
        .then((res) => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Nuovo paziente inserito con successo'
          })
          if (res.status == 200) this.isActiveSearchPet = false
          this.petData = res.data
          this.newAppointment.pet_id = res.data.id
        })
        .catch((err) => {
          this.$store.dispatch('commonComponents/openMessageModal', {
            title: 'Errore',
            subtitle: err.response.data.message
          })
        })
    },
    saveAppointment() {
      this.checkForm()
      if (!this.dataToComplete) {
        var dataOraInizio =
          this.newAppointment.datainizio + ' ' + this.newAppointment.orainizio
        let dateAndHourStart = this.$FormatEpochMin(dataOraInizio)
        var dataOraFine =
          this.newAppointment.datafine + ' ' + this.newAppointment.orafine
        let dateAndHourEnd = this.$FormatEpochMin(dataOraFine)
        if (dateAndHourStart > dateAndHourEnd) {
          this.$store.dispatch('commonComponents/openMessageModal', {
            title: 'Errore',
            subtitle: 'Fine appuntamento è precedente a inizio',
            background: false
          })
        } else {
          let appuntamento = {}

          appuntamento = this.newAppointment
          if (this.search) {
            appuntamento.new_data = {}
          } else {
            appuntamento.pet_id = null
            appuntamento.customer_id = null
          }
          appuntamento.start_datetime = dateAndHourStart
          appuntamento.end_datetime = dateAndHourEnd
          let arrayVets = []
          if (typeof appuntamento.staff_users != 'undefined') {
            for (let i = 0; i < appuntamento.staff_users.length; i++) {
              let tempVet = {
                id: appuntamento.staff_users[i].id
              }
              arrayVets.push(tempVet)
            }
          }
          appuntamento.staff_users = arrayVets
          let arrayRooms = []
          if (typeof appuntamento.clinic_rooms != 'undefined') {
            for (let i = 0; i < appuntamento.clinic_rooms.length; i++) {
              let tempRoom = {
                id: appuntamento.clinic_rooms[i].id
              }
              arrayRooms.push(tempRoom)
            }
          }
          appuntamento.clinic_rooms = arrayRooms
          appuntamento.isQuick = this.isQuick
          delete appuntamento.pet
          delete appuntamento.client
          delete appuntamento.datainizio
          delete appuntamento.datafine
          delete appuntamento.orainizio
          delete appuntamento.orafine

          if (appuntamento.id != '' && appuntamento.id != null) {
            axios
              .post(
                '/' +
                  localStorage.getItem('clinic') +
                  '/agenda/' +
                  appuntamento.id +
                  '/edit',
                appuntamento
              )
              .then((res) => {
                if (res) {
                  this.$emit('render')
                  this.$emit('close')
                }
              })
          } else {
            // Create new appointment
            axios
              .post(
                '/' + localStorage.getItem('clinic') + '/agenda',
                appuntamento
              )
              .then((res) => {
                if (res) {
                  this.$store.dispatch(
                    'commonComponents/openNotificationModal',
                    {
                      message: 'Dati inseriti con successo nel sistema!'
                    }
                  )
                  this.$emit('render')
                  this.$emit('close')
                }
              })
          }
        }
      }
    },
    closeModal() {
      if (this.isQuick) this.$emit('render')
      this.$emit('close')
    },
    openConfirmDelete() {
      this.$emit('openConfirmModal')
    },
    dropDownSearchCustomer() {
      this.isActiveSearchCustomer = true
    },
    dropDownSearchPet() {
      this.isActiveSearchPet = true
    },

    closeDropDownSearchCustomer() {
      this.isActiveSearchCustomer = false
    },

    closeDropDownSearchPet() {
      this.isActiveSearchPet = false
    },
    goToCustomer() {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/customer/' +
            this.newAppointment.customer_id
        )
        .then((res) => {
          const customer = res.data
          this.$goToCustomer(customer)
        })
    },
    goToPet() {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/pet/' +
            this.newAppointment.pet_id
        )
        .then((res) => {
          const pet = res.data
          this.$goToPet(pet)
        })
    },

    keyOnCustomer() {
      this.isActiveSearchCustomer = true
      this.isActiveSearchPet = false
    },
    keyOnPet() {
      this.isActiveSearchCustomer = false
      this.isActiveSearchPet = true
    },

    onChildClick(value, filter) {
      if (filter[0] == 'customer') {
        console.log(filter[0])
        this.newAppointment.client = value.last_name + ' ' + value.first_name
        this.newAppointment.customer_id = value.id
        this.clientData = value
      }
      if (filter[0] == 'pet') {
        this.newAppointment.pet = value.name
        this.newAppointment.pet_id = value.id
        this.petData = value
      }
      this.isActiveSearchPet = false
      this.isActiveSearchCustomer = false
    },
    onDrop() {
      let div = document.getElementById('new-appointment-modal')
      document.getElementById('draggable-div').style.opacity = 1
      div.classList.add('bg-black')
      div.classList.add('bg-opacity-50')
    },
    onDrag(event) {
      event.srcElement.style.opacity = 0.1
      let div = document.getElementById('new-appointment-modal')
      div.classList.remove('bg-black')
      div.classList.remove('bg-opacity-50')
    },
    switchFocus(value) {
      switch (value) {
        case 1:
          this.$refs.input1.focus()
          break
        case 2:
          this.$refs.input2.focus()

          break
        case 3:
          this.$refs.input3.focus()
          this.isActiveSearchCustomer = true
          this.isActiveSearchPet = false
          break
        case 4:
          this.$refs.input4.focus()
          this.isActiveSearchCustomer = false
          this.isActiveSearchPet = true
          break
        case 5:
          this.$refs.input5.focus()
          break
        case 6:
          this.$refs.input6.focus()
          break
        case 7:
          this.$refs.input7.focus()
          break
        case 8:
          this.$refs.input8.focus()
          break
        case 9:
          this.$refs.input9.$refs.search.focus()
          break
        case 10:
          this.$refs.input10.$refs.search.focus()
          break
        case 11:
          this.$refs.input11.focus()
          break
      }
    },
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction'
    })
  }
})
</script>

<style>
.notapproved {
  border: 1px solid #ff8f1c;
}

.flatpickr-months .flatpickr-month {
  background-color: #f4f4f4 !important;
  height: 55px;
}

.light.shortcut-buttons-flatpickr-wrapper {
  background-color: #f4f4f4 !important;
  color: #3a3a3a !important;
}
.flatpickr-day.selected {
  background-color: #ff8f1c !important;
  border: #ff8f1c !important;
}
.shortcut-buttons-flatpickr-wrapper {
  padding: 10px;
}
.shortcut-buttons-flatpickr-buttons {
  width: 100%;
  justify-content: center;
  gap: 12px;
}

.shortcut-buttons-flatpickr-button {
  font-weight: bold;
  padding: 3px 20px;
  background-color: #646464;
  border-radius: 15px;
  color: white;
  font-family: nunito;
  font-weight: bold;
  border: solid 1px #64646400;
}
.shortcut-buttons-flatpickr-button:hover {
  border: solid 1px #646464;
  color: #646464;
  background-color: white;
  transition: ease-in;
  transition-duration: 300ms;
}

.appuntamenti-select .vs__search {
  color: #d4d8e1 !important;
}
.appuntamenti-select .vs__dropdown-toggle {
  border: solid 1px #dedfe0 !important;
}

.vs__dropdown {
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.drag-box {
  cursor: move;
}

/* >>> {
  --vs-controls-size: 0.7;
  --vs-controls-color: #94a3b8;
  --vs-border-color: #e2e8f0;
  --vs-border-radius: 5px;

  --vs-dropdown-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

  --vs-dropdown-option--active-color: #333435;
  --vs-dropdown-option-padding: 3px 20px;
  --vs-dropdown-option--active-bg: #edf2f7;

  --vs-selected-bg: #edf2f7;
  --vs-selected-border-color: none;
  --vs-selected-color: #333435;

  --vs-dropdown-option--active-bg: #aebfcf;
  --vs-search-input-placeholder-color: #d4d8e1;
  --vs-font-size: 0.9rem;
} */
</style>
