<template>
  <div class="p-5 text-center">
    <div class="flex gap-4 mb-4">
      <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
        >Filtra per key</span
      >
      <v-select
        v-model="selectedKeys"
        class="w-1/2"
        multiple
        label="key"
        placeholder="Seleziona una o più key"
        :options="keys"
        :reduce="(value) => value.id"
        @option:selected="getMedical"
        @option:deselected="getMedical"
      />
    </div>
    <SchedeClinicheTable
      v-if="docs"
      class="col-span-2 row-span-1"
      :table-config-clinic-doc="tableConfig_clinic_doc"
      :total-records="docsTotalRecords"
      :pagination="paginationDocs"
      :for-page-option="forPageOptionDocs"
      :documents="docs"
      :selected-patient="true"
      :searching="searchingDocs"
      :global="true"
      @getMedical="setFiltersDocs"
      @changePagination="changePagination"
      @changeNumPages="changeNumPages"
      @showPdfPreview="handlePdfPreview"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import axios from '../../gateway/backend-api'
import moment from 'moment'
import SchedeClinicheTable from '../../components/tables/SchedeClinicheTable.vue'

export default defineComponent({
  components: { SchedeClinicheTable },
  props: [],

  setup() {
    const store = useStore()
    // ----- DATA -----
    const tableConfig_clinic_doc = ref([
      {
        key: 'date',
        title: 'Data Creazione',
        type: 'date'
      },

      {
        key: 'document',
        title: 'Documento',
        type: 'string'
      },
      {
        key: 'author',
        title: 'Autore',
        type: 'string'
      },
      {
        key: 'reason',
        title: 'Motivo della visita',
        type: 'none'
      },
      {
        key: 'client',
        title: 'Cliente',
        type: 'none'
      },
      {
        key: 'pet',
        title: 'Paziente',
        type: 'string'
      }
    ])

    const forPageOptionDocs = ref([5, 10, 15])
    const docsTotalRecords = ref(0)
    const paginationDocs = ref({
      page: 1,
      forPage: forPageOptionDocs.value[0]
    })
    const docs = ref([])
    const filtersDocs = ref([])
    const keyDocs = ref([])
    const keys = ref([])
    const selectedKeys = ref([])

    const getKeys = () => {
      axios
        .get('/' + localStorage.getItem('clinic') + '/medicalkey') // scarico la lista di documenti
        .then((res) => {
          keys.value = res.data
        })
    }

    const getMedical = () => {
      var filters = null

      let params = { type: 'all' }

      params['page'] = paginationDocs.value.page
      params['per_page'] = paginationDocs.value.forPage
      params['filters'] = filtersDocs.value
      params['keys_id'] = selectedKeys.value

      if (filters != null) {
        Object.entries(filters).forEach(([k, v]) => {
          if (v) {
            params[k] = v
          }
        })
      }

      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/medical?type=clinical_card',
          params
        )
        .then((res) => {
          docs.value = res.data.data
          docsTotalRecords.value = res.data.total
          store.dispatch('commonComponents/hideLoadingModalAction')
          //searchingDocs.value = false
        })
        .catch(() => store.dispatch('commonComponents/hideLoadingModalAction'))
    }

    const handlePdfPreview = (doc) => {
      console.log('voglio vedere il pdf')
      store.dispatch('commonComponents/showLoadingModalAction')
      axios({
        url:
          '/' +
          localStorage.getItem('clinic') +
          '/download/' +
          doc.pet.id +
          '/medical/' +
          doc.medicaltemplate_id +
          '/pdf',
        method: 'POST',
        responseType: 'blob'
      })
        .then((resp) => {
          const url = window.URL.createObjectURL(
            new Blob([resp.data], { type: 'application/pdf' })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            doc.document_type +
              '_' +
              doc.pet.name +
              '_' +
              moment.unix(doc.last_update).format('DD-MM-YYYY') +
              '.pdf'
          )
          document.body.appendChild(link)
          store.dispatch('commonComponents/openPdfModal', {
            title:
              doc.document_type +
              '_' +
              doc.pet.name +
              '_' +
              moment.unix(doc.last_update).format('DD-MM-YYYY') +
              '.pdf',
            link: url,
            background: false,
            print: () => {
              console.log('PRINT')
            },
            download: () => {
              link.click()
            },
            xml: null
          })

          store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const changePagination = (value) => {
      paginationDocs.value.page = value.page
      paginationDocs.value.forPage = value.forPage
      docs.value = []
      getMedical()
    }

    const changeNumPages = (elem) => {
      paginationDocs.value.forPage = elem.target.value
      docs.value = []

      getMedical()
    }

    const setFiltersDocs = (value) => {
      filtersDocs.value = value
      docs.value = []
      //searchingDocs.value = true
      getMedical()
    }

    const setKeyDocs = (value) => {
      keyDocs.value = value
      docs.value = []
      //searchingDocs.value = true
      getMedical()
    }

    // ----- METHODS -----

    // ----- COMPUTED -----

    return {
      tableConfig_clinic_doc,
      getMedical,
      forPageOptionDocs,
      docsTotalRecords,
      paginationDocs,
      docs,
      handlePdfPreview,
      setFiltersDocs,
      setKeyDocs,
      changePagination,
      changeNumPages,
      getKeys,
      keys,
      selectedKeys
    }
  },
  mounted() {
    this.getMedical()
    this.getKeys()
  }
})
</script>
