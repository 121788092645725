<template>
  <div>
    <div>
      <div id="print">
        <div
          v-for="(dateAppointments, date) in sortedAppointmentsByDate"
          :key="date"
          class="my-2"
        >
          <div style="border: solid 2px #bababa">
            <h2 class="text-center font-bold uppercase text-sm pt-0.5">
              {{ date }}
            </h2>
          </div>
          <table class="w-full appointment_table m">
            <thead>
              <tr>
                <th style="width: 50px">Inizio</th>
                <th style="width: 50px">Fine</th>
                <th style="width: 220px">Motivo</th>
                <th style="width: 100px">Cliente</th>
                <th style="width: 100px">Pet</th>
                <th style="width: 130px">Veterinario</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="appointment in dateAppointments"
                :key="appointment.start"
              >
                <td style="width: 50px">{{ appointment.start }}</td>
                <td style="width: 50px">{{ appointment.end }}</td>
                <td style="width: 220px">{{ appointment.title }}</td>
                <td style="width: 100px">
                  {{
                    appointment.customer.name +
                    ' ' +
                    appointment.customer.lastname +
                    ' ' +
                    appointment.customer.ragsoc
                  }}
                </td>
                <td style="width: 100px">{{ appointment.pet }}</td>
                <td style="width: 130px">
                  <div v-for="vet in appointment.staff" :key="vet">
                    {{ vet }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="picker-div" class="picker-container">
      <input
        v-show="false"
        id="report-date-day"
        ref="dailyPicker"
        type="text"
        class="date-agenda mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="Seleziona data del report"
        @input="(e) => handleClick(e)"
      />
    </div>
    <div id="calendar-container">
      <Fullcalendar
        id="calendar_print"
        ref="fullCalendar"
        :key="componentKey"
        :options="calendarOptions"
        :editable="
          permissions &&
          permissions.agenda &&
          permissions.agenda.edit_agenda &&
          permissions.agenda.edit_agenda.enabled === true
        "
        @click="seeDate"
        class=" "
      />
    </div>

    <div v-if="openModalAppointment">
      <NewAppointment
        :appointment-data="appointmentToOpen"
        :footer-modal-appointment="footerModify"
        :modal-appointment-title="modalAppointmentTitle"
        :preview="false"
        @close="toggleModal"
        @openConfirmModal="toggleConfirm"
        @aggiungi="newDate"
        @render="update"
      />
    </div>
    <div v-if="openConfirmDelete">
      <ConfirmDelete
        @removeEvent="removeEvent(this)"
        @closeconfirm="toggleConfirm"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import '@fullcalendar/core/vdom'
import Fullcalendar from '@fullcalendar/vue3'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import InteractionPlugin from '@fullcalendar/interaction'
import itLocale from '@fullcalendar/core/locales/it'
import ListPlugin from '@fullcalendar/list'
import ConfirmDelete from '../../views/calendar/ConfirmDelete.vue'
import NewAppointment from '../../views/calendar/NewAppointment.vue'
import axios from '../../gateway/backend-api'
import moment from 'moment'
import 'moment/locale/it'

import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

const Demo = defineComponent({
  components: {
    Fullcalendar,
    ConfirmDelete,
    NewAppointment
  },

  props: [
    'footerModalAppointment',
    'newAppointment',
    'filterVets',
    'filterRooms',
    'viewCalendar',
    'clients',
    'pets'
  ],

  data() {
    return {
      loggedElements: new Set(),
      // colorIndex: 0,
      vetColor: [],
      appointmentsByDate: {},
      // printTable: false,
      appointments: [],
      componentKey: 0,
      eventToRemove: null,
      appointmentToOpen: {
        isQuick: false,
        customer_id: '',
        client: '',
        pet_id: '',
        pet: '',
        title: ''
      },
      idNum: 1,
      newEvent: {
        id: '',
        title: '',
        start: '',
        end: '',
        id_client: '',
        client: '',
        id_pet: '',
        pet: '',
        description: '',
        room: '',
        vete: '',
        id_vet: ''
      },
      modalAppointmentTitle: 'Aggiorna appuntamento',
      footerModify: true,
      openConfirmDelete: false,
      openModalAppointment: false,
      eventClicked: 0,
      clickOnAggiungi: 0,
      calendarOptions: {
        scrollTime: '08:00',
        plugins: [DayGridPlugin, TimeGridPlugin, InteractionPlugin, ListPlugin],
        customButtons: {
          vistaOggi: {
            text: 'Oggi',
            click: this.openCalendar
          }
        },
        headerToolbar: {
          right: 'listDay,timeGridDay,timeGridWeek,dayGridMonth',
          center: 'title',
          left: 'prev,vistaOggi,next'
        },
        views: {
          dayGrid: {
            titleFormat: { year: 'numeric', month: 'long' }
          },
          week: {
            titleFormat: {
              month: 'long',
              day: 'numeric',
              weekday: 'short'
            }
          },
          day: {
            titleFormat: {
              // will produce something like "Tuesday, September 18, 2018"
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              weekday: 'long'
            }
          }
        },
        initialView: 'timeGridWeek',
        defaultView: 'agendaDay',
        dragScroll: true,
        selectable: true,
        selectMirror: true,
        //initialDate: new Date(),
        navLinks: true,
        editable: true,
        dayMaxEvents: true,
        nowIndicator: true,
        weekends: true,
        locale: itLocale,
        firstDay: '1',
        weekNumbers: true,
        weekText: 'S',
        eventDrop: this.catchDrop,
        eventDropInfo: this.eventDropInfo,
        eventDragStop: this.deleteOnDrag,
        eventDidMount: this.eventDidMount,
        eventReceive: this.eventReceive,
        eventWillUnmount: this.eventWillUnmount,
        dayHeaderContent: this.dayHeaderContent,
        // select: this.addEvent,
        // eventChange: this.hola,
        drop: this.newAppointmentDrag,
        select: this.newDate,
        eventClick: this.getEventId,
        dayHeaderDidMount: this.dayHeaderDidMount,
        eventContent: this.customEventContent,
        viewDidMount: this.viewDidMount,
        events: [],
        eventOverlap: true,
        eventBackgroundColor: '#395ec1',
        eventBorderColor: '#395ec1',
        droppable: true,
        slotEventOverlap: false
      },
      startDate: null,
      endDate: null,
      reportDate: null
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {
    clients: function () {
      this.loadCalendar()
    },
    pets: function () {
      this.loadCalendar()
    },
    filterVets: function () {
      this.loadCalendar()
    },
    filterRooms: function () {
      this.loadCalendar()
    }
  },
  mounted() {
    this.loadCalendar(), flatpickr.localize(Italian)

    flatpickr('.date-agenda', {
      appendTo: document.getElementById('picker-div'),
      plugins: [
        require('shortcut-buttons-flatpickr')({
          button: [
            {
              label: 'Oggi'
            },
            {
              label: 'Domani'
            },
            { label: 'Chiudi' }
          ],

          label: '',
          onClick: (index, fp) => {
            let date
            switch (index) {
              case 0:
                date = new Date()
                break
              case 1:
                date = new Date(Date.now() + 24 * 60 * 60 * 1000)
                break

              case 2:
                fp.close()
                break
            }
            fp.setDate(date)
          }
        })
      ],
      maxDate: '',
      dateFormat: 'd/m/Y'
    })

    this.goDate(moment().format('DD/MM/YYYY'))
  },
  created() {
    this.vetColor = []
    this.setCalendarHeight()
    this.calendarOptions.initialView = this.viewCalendar
    window.addEventListener('resize', this.setCalendarHeight)
  },
  methods: {
    createTableForPrint() {
      this.appointmentsByDate = {}

      this.calendarOptions.events.forEach((event) => {
        moment.locale('it')
        const rawDate = moment(event.start).format('YYYY-MM-DD')
        const appointmentTable = {
          title: event.title,
          date: rawDate,
          start: moment(event.start).format('HH:mm'),
          end: moment(event.end).format('HH:mm'),
          pet: event.pet_name,
          staff: [],
          customer: { name: '', lastname: '', ragsoc: '' }
        }

        const staffUser = event.staff_users
        staffUser.forEach((staff) => {
          appointmentTable.staff.push(staff.first_name + ' ' + staff.last_name)
        })

        if (event.customer != null) {
          if (event.customer.first_name != null) {
            appointmentTable.customer.name = event.customer.first_name
          }
          if (event.customer.last_name != null) {
            appointmentTable.customer.lastname = event.customer.last_name
          }
          if (event.customer.ragione_sociale != null) {
            appointmentTable.customer.ragsoc = event.customer.ragione_sociale
          }
        }

        if (!this.appointmentsByDate[rawDate]) {
          this.appointmentsByDate[rawDate] = []
        }

        this.appointmentsByDate[rawDate].push(appointmentTable)
      })

      const sortedDates = Object.keys(this.appointmentsByDate).sort(
        (a, b) => new Date(a) - new Date(b)
      )

      this.sortedAppointmentsByDate = {}

      sortedDates.forEach((date) => {
        const formattedDate = moment(date).format('dddd, DD/MM/YYYY')
        this.sortedAppointmentsByDate[formattedDate] =
          this.appointmentsByDate[date]
      })

      Object.keys(this.sortedAppointmentsByDate).forEach((date) => {
        this.sortedAppointmentsByDate[date].sort((a, b) => {
          const startTimeA = moment(a.start, 'HH:mm')
          const startTimeB = moment(b.start, 'HH:mm')
          return startTimeA.diff(startTimeB)
        })
      })
    },

    setCalendarHeight() {
      const windowHeight = window.innerHeight
      if (windowHeight > 800) {
        this.calendarOptions.height = '72vh'
      } else {
        this.calendarOptions.height = '68vh'
      }
    },

    eventReceive(info) {
      this.eventToRemove = info.event

      if (info.event.title == 'Nuovo Appuntamento') {
        info.event.remove()
      }
    },
    eventDropInfo() {},
    update() {
      this.forceRerender()
      this.loadCalendar()
    },
    forceRerender() {
      this.componentKey += 1
    },
    seeDate() {
      this.calendar = this.$refs.fullCalendar.getApi()
      //Mon Jul 17 2023 00:00:00 GMT+0200 (Central European Summer Time)

      let start = this.$FormatEpochMin(
        this.$FormatDateTimeISO(this.calendar.view.activeStart.toISOString())
      )
      let end = this.$FormatEpochMin(
        this.$FormatDateTimeISO(this.calendar.view.activeEnd.toISOString())
      )

      if (this.startDate !== start || this.endDate !== end) {
        this.startDate = start
        this.endDate = end
        console.log(this.$FormatDateTimeISO(this.startDate))
        if (this.calendar.view.type === 'dayGridMonth') {
          this.calendarOptions.initialDate = moment(
            this.$FormatDateTimeISO(
              this.calendar.view.activeStart.toISOString()
            ),
            'DD/MM/YYYY HH:mm'
          )
            .add(6, 'days')
            .toDate()
        } else {
          this.calendarOptions.initialDate =
            this.calendar.view.activeStart.toISOString()
        }

        this.$emit('changeViewFormat', this.calendar.view.type)
        this.calendarOptions.initialView = this.calendar.view.type
        this.loadCalendar()
      } else {
        this.startDate = start
        this.endDate = end
      }
    },
    loadCalendar() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      this.calendar = this.$refs.fullCalendar.getApi()
      if (!this.startDate || !this.endDate) {
        let start = this.$FormatEpochMin(
          this.$FormatDateTimeISO(this.calendar.view.activeStart.toISOString())
        )
        let end = this.$FormatEpochMin(
          this.$FormatDateTimeISO(this.calendar.view.activeEnd.toISOString())
        )

        this.startDate = start
        this.endDate = end
      }

      if (
        this.filterVets.length > 0 ||
        this.filterRooms.length > 0 ||
        this.clients.length > 0 ||
        this.pets.length > 0
      ) {
        let filter_vets = [],
          filter_rooms = [],
          filter_clients = [],
          filter_pets = []

        if (this.filterVets.length > 0) {
          filter_vets = this.filterVets.map((elem) => {
            return elem.id
          })
          //filter_param.push('staff_users')
        }

        if (this.filterRooms.length > 0) {
          filter_rooms = this.filterRooms.map((elem) => {
            return elem.id
          })
          //filter_param.push('rooms')
        }
        if (this.clients.length > 0) {
          filter_clients = this.clients.map((elem) => {
            return elem.id
          })
        }
        if (this.pets.length > 0) {
          filter_pets = this.pets.map((elem) => {
            return elem.id
          })
        }
        axios
          .post('/' + localStorage.getItem('clinic') + '/agenda/index', {
            // ids: filter_vets,
            // ids_extra: filter_rooms,
            // filterParameter: filter_param,
            active_filters: {
              clinic_rooms: filter_rooms,
              staff_users: filter_vets,
              customers: filter_clients,
              pets: filter_pets
            },
            startDate: this.startDate,
            endDate: this.endDate
          })
          .then((res) => {
            this.$emit('changeViewFormat', this.calendar.view.type)
            this.$emit(
              'setClientsPets',
              res.data.customers_list,
              res.data.pets_list,
              res.data.staffs_list,
              res.data.rooms_list
            )
            this.calendarOptions.initialView = this.calendar.view.type
            this.bindCalendar(res.data.data)
          })
          .catch(() => {
            this.componentKey = 1
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      } else {
        axios
          .post('/' + localStorage.getItem('clinic') + '/agenda/index', {
            startDate: this.startDate,
            endDate: this.endDate
          })
          .then((res) => {
            // da ricontrollare
            // questo comando non c'era
            this.calendarOptions.initialDate = moment(
              this.endDate,
              'X'
            ).format()

            this.bindCalendar(res.data.data, this.startDate, this.endDate)
            this.$emit(
              'setClientsPets',
              res.data.customers_list,
              res.data.pets_list,
              res.data.staffs_list,
              res.data.rooms_list
            )
          })
          .catch(() => {
            this.componentKey = 1
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      }
    },

    customEventContent(info) {
      const extendedProps = info.event.extendedProps
      const start = this.$FormatDateTimeHoursISO(extendedProps.start_datetime)
      const end = this.$FormatDateTimeHoursISO(extendedProps.end_datetime)
      const vet_color = extendedProps.staff_users?.[0]?.color || ''
      const backgroundColor = vet_color ? vet_color : '#395ec1'
      const textColor =
        this.calculateBrightness(backgroundColor) > 128 ? 'black' : 'white'

      let viewTypeContent

      switch (info.view.type) {
        case 'listDay':
          viewTypeContent = `
        <div> ${info.event.title} </div>
      `
          break

        case 'timeGridWeek':
          viewTypeContent = `
        <div class="flex items-start gap-x-2  text-xxs font-small " style="background-color: ${backgroundColor}; color:${textColor}">
          <div class="event-time flex-0 whitespace-nowrap"> ${start} - ${end} </div>
          <div class="flex-1 flex items-center gap-x-1 ">
            <div class="event-customer whitespace-nowrap "><span> ${
              extendedProps.customer && extendedProps.customer.first_name
                ? '&#8226; ' +
                  extendedProps.customer.first_name.charAt(0) +
                  '. '
                : '&#8226; ' + info.event.title
            }</span><span class="whitespace-nowrap" >${
            extendedProps.customer ? extendedProps.customer.last_name : ''
          }</span><span class="whitespace-nowrap"> ${
            extendedProps.customer
              ? extendedProps.customer.ragione_sociale != null
                ? extendedProps.customer.ragione_sociale
                : ''
              : ''
          }</span></div>

            <div class="event-pet whitespace-nowrap">  ${
              extendedProps.pet_name ? '&#8226; ' + extendedProps.pet_name : ''
            }</div>
          </div>
        </div>
      `
          break

        case 'timeGridDay':
          viewTypeContent = `
        <div class= "timeGridDay text-xs font-medium" style="background-color: ${backgroundColor}; color:${textColor}">
          <div class="event-time"> ${start} - ${end} </div> &#8226;
          <div class="event-customer"> ${
            extendedProps.customer && extendedProps.customer.first_name
              ? extendedProps.customer.first_name
              : ''
          }</span><span class="whitespace-nowrap">${
            extendedProps.customer && extendedProps.customer.last_name
              ? '&#8226;' + extendedProps.customer.last_name
              : ''
          }</span><span style=""> ${
            extendedProps.customer
              ? extendedProps.customer.ragione_sociale != null
                ? '&#8226;' + extendedProps.customer.ragione_sociale
                : ''
              : ''
          }</span></div>
          <div class="event-pet">  ${
            extendedProps.pet_name ? '&#8226;' + extendedProps.pet_name : ''
          }</div><span> ${
            info.event && info.event.title ? '&#8226; ' + info.event.title : ''
          }</span>
        </div>
      `
          break

        case 'dayGridMonth':
          viewTypeContent = `
        <div class="flex items-center gap-1 text-xs font-medium p-0.5" style="background-color: ${backgroundColor}; color:${textColor}">
          <div class="event-time"> ${start} - ${end}  </div> &#8226;
          <div class="event-customer"><span> ${
            extendedProps.customer && extendedProps.customer.first_name
              ? extendedProps.customer.first_name.charAt(0) + '. '
              : info.event.title
          }</span><span class="">${
            extendedProps.customer && extendedProps.customer.last_name
              ? extendedProps.customer.last_name
              : ''
          }</span><span style=""> ${
            extendedProps.customer
              ? extendedProps.customer.ragione_sociale != null
                ? extendedProps.customer.ragione_sociale
                : ''
              : ''
          }</span></div>
          &#8226;
          <div class="event-pet">  ${
            extendedProps.pet_name ? extendedProps.pet_name : ''
          }</div>
        </div>
      `
          break

        default:
          break
      }

      // Customize the event's HTML here
      const content = document.createElement('div')
      content.classList.add('appuntamento')
      content.innerHTML = `
    ${viewTypeContent}
  `

      return { domNodes: [content] }
    },

    bindCalendar(data) {
      this.calendarOptions.events = data

      for (let i = 0; i < this.calendarOptions.events.length; i++) {
        if (
          this.calendarOptions.events[i].staff_users &&
          this.calendarOptions.events[i].staff_users[0] != undefined &&
          this.calendarOptions.events[i].staff_users[0].color != null
        ) {
          this.calendarOptions.events[i].color =
            this.calendarOptions.events[i].staff_users[0].color
        }

        let convertedStart = new Date(
          this.calendarOptions.events[i].start_datetime * 1000
        ).toISOString()
        let convertedEnd = new Date(
          this.calendarOptions.events[i].end_datetime * 1000
        ).toISOString()
        this.calendarOptions.events[i].start = convertedStart
        this.calendarOptions.events[i].end = convertedEnd
      }
      //per risolvere mese +1 alla selezione, tolto componentKey +=1
      this.componentKey = 1
      this.$store.dispatch('commonComponents/hideLoadingModalAction')
      this.createTableForPrint()
    },
    newDate(info) {
      if (
        this.permissions &&
        this.permissions.agenda &&
        this.permissions.agenda.store_agenda &&
        this.permissions.agenda.store_agenda.enabled === true
      ) {
        this.modalAppointmentTitle = 'Nuovo appuntamento'
        this.footerModify = false
        this.appointmentToOpen = {
          start_datetime: this.$FormatEpoch(info.start),
          end_datetime: this.$FormatEpoch(info.end)
        }
        this.toggleModal()
      }
    },

    newAppointmentDrag(info) {
      if (
        this.permissions &&
        this.permissions.agenda &&
        this.permissions.agenda.store_agenda &&
        this.permissions.agenda.store_agenda.enabled === true
      )
        if (info.draggedEl.innerText == 'Appuntamento Rapido') {
          // gestione appuntamento rapido

          let date = this.$FormatEpoch(info.date)
          let quickEvent = {
            title: info.draggedEl.innerText,
            customer_id: 0,
            description: '',
            clinic_rooms: [
              {
                id: 1
              }
            ],
            staff_users: [],
            color: '',
            pet_id: 0,
            start_datetime: date,
            end_datetime: Number(date + 3600)
          }
          this.appointmentToOpen = quickEvent
          this.appointmentToOpen.isQuick = true
          this.footerModify = false
          this.modalAppointmentTitle = 'Nuovo appuntamento'
          this.toggleModal()
        } else {
          // gestione nuovo appuntamento

          this.appointmentToOpen = {
            start_datetime: this.$FormatEpoch(info.date),
            end_datetime: this.$FormatEpoch(info.date)
          }

          //this.calendarOptions.events.push(this.newEvent)
          this.eventClicked = this.calendarOptions.events.length
          this.toggleModal()
          this.footerModify = false
          this.modalAppointmentTitle = 'Nuovo appuntamento'
        }
    },
    getEventId(info) {
      if (
        this.permissions &&
        this.permissions.agenda &&
        this.permissions.agenda.edit_agenda &&
        this.permissions.agenda.edit_agenda.enabled === true
      ) {
        this.eventClicked = info.event.id
        this.appointmentToOpen = this.calendarOptions.events.find((elem) => {
          if (this.eventClicked == elem.id) {
            return elem
          }
        })
        if (this.appointmentToOpen?.isquick) {
          this.appointmentToOpen.isQuick = this.appointmentToOpen.isquick
        }
        this.footerModify = true
        this.modalAppointmentTitle = 'Aggiorna appuntamento'
        this.toggleModal()
      }
    },
    toggleModal() {
      this.openModalAppointment = !this.openModalAppointment
      if (!this.openModalAppointment) this.loadCalendar()
    },
    toggleConfirm() {
      this.toggleModal()
      this.openConfirmDelete = !this.openConfirmDelete
    },
    removeEvent(info) {
      if (
        this.permissions &&
        this.permissions.agenda &&
        this.permissions.agenda.edit_agenda &&
        this.permissions.agenda.edit_agenda.enabled === true
      ) {
        var event = info.calendar.getEventById(this.eventClicked)
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/agenda/' +
              event.id +
              '/delete'
          )
          .then(() => {
            event.remove()
          })

        this.eventClicked = 0
        this.openConfirmDelete = !this.openConfirmDelete
        this.openModalAppointment = false
      }
    },
    catchDrop(info) {
      let event = JSON.parse(JSON.stringify(info))
      let convertedStart = this.$FormatEpochMin(new Date(event.event.start))
      let convertedEnd = this.$FormatEpochMin(new Date(event.event.end))
      let editDrop = event.event.extendedProps
      editDrop.id = event.event.id
      editDrop.title = event.event.title
      editDrop.start_datetime = convertedStart
      editDrop.isQuick = event.event.extendedProps.isquick
      editDrop.end_datetime = convertedEnd
      if (editDrop.customer_id === null) editDrop.customer_id = 0
      if (editDrop.pet_id === null) editDrop.pet_id = 0
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/agenda/' +
            editDrop.id +
            '/edit',
          editDrop
        )
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.loadCalendar()
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    deleteOnDrag(info) {
      var x = info.jsEvent.clientX
      var y = info.jsEvent.clientY
      var externalEvents = document.getElementById('calendar-container')
      var offset = externalEvents.getBoundingClientRect()
      if (
        x < offset.left ||
        y > offset.bottom ||
        x > offset.right ||
        y < offset.top
      ) {
        this.toggleConfirm()
        this.eventClicked = info.event.id
      }
    },

    customHeader() {
      const listTable = document.querySelector('.fc-list-table')

      if (listTable) {
        const tBody = listTable.getElementsByTagName('tbody')[0]
        if (tBody != undefined) {
          var newTR = tBody.insertRow(1)
          newTR.setAttribute('id', 'fullcalendar-nuova-tr')
          newTR.classList.add('new-row-calendar')

          var veterinario = newTR.insertCell()
          var veterinarioText = document.createTextNode('Veterinario')
          veterinario.appendChild(veterinarioText)

          var durata = newTR.insertCell()
          var durataText = document.createTextNode('Durata')
          durata.appendChild(durataText)

          var motivo = newTR.insertCell()
          var motivoText = document.createTextNode(`Motivo `)
          motivo.appendChild(motivoText)

          var cliente = newTR.insertCell()
          var clienteText = document.createTextNode('Cliente')
          cliente.appendChild(clienteText)

          var paziente = newTR.insertCell()
          var pazienteText = document.createTextNode('Paziente')
          paziente.appendChild(pazienteText)

          var sala = newTR.insertCell()
          var salaText = document.createTextNode('Sala')
          sala.appendChild(salaText)

          var childNodes = tBody.childNodes

          tBody.insertBefore(newTR, childNodes[1])
        }
      }
    },

    viewDidMount(info) {
      var fcNewRow = document.getElementById('fullcalendar-nuova-tr')
      if (fcNewRow != undefined) fcNewRow.parentNode.removeChild(fcNewRow)
      if (info.view.type === 'listDay') {
        this.customHeader()
      }
    },
    eventWillUnmount(info) {
      this.viewDidMount(info)
    },

    // Function to calculate brightness of a hex color
    calculateBrightness(hexColor) {
      var r = parseInt(hexColor.slice(1, 3), 16)
      var g = parseInt(hexColor.slice(3, 5), 16)
      var b = parseInt(hexColor.slice(5, 7), 16)

      // Use a formula to calculate brightness
      var brightness = (r * 299 + g * 587 + b * 114) / 1000
      return brightness
    },

    eventDidMount(info) {
      // info.event.setExtendedProp('first_name', 'Melu')

      this.calendar = this.$refs.fullCalendar.getApi()
      if (info.view.type === 'listDay') {
        let newTd = document.createElement('td')
        let vets = ''
        let backgroundColor =
          info.event.extendedProps.staff_users?.[0]?.color || '#395ec1'

        for (let i = 0; i < info.event.extendedProps.staff_users.length; i++) {
          const user = info.event.extendedProps.staff_users[i]
          const fullName = `${
            user.first_name ? user.first_name.charAt(0) : ''
          }. ${user.last_name}`
          vets += fullName + ' '
        }

        newTd.textContent = vets

        newTd.style.backgroundColor = backgroundColor

        if (backgroundColor) {
          var brightness = this.calculateBrightness(backgroundColor)
          if (brightness > 128) {
            newTd.style.color = 'black'
          } else {
            newTd.style.color = 'white'
          }
        }

        var firstChild = info.el.firstElementChild

        info.el.insertBefore(newTd, firstChild)

        var tbodyRef = this.calendar.el.getElementsByTagName('tbody')[0]
        tbodyRef.firstChild.firstChild.colSpan = '8'

        var toInject = []

        if (info.event.extendedProps.customer) {
          let c_name = ''
          if (info.event.extendedProps.customer.ragione_sociale)
            c_name += info.event.extendedProps.customer.ragione_sociale + ' '

          if (info.event.extendedProps.customer.first_name)
            c_name += info.event.extendedProps.customer.first_name + ' '

          if (info.event.extendedProps.customer.last_name)
            c_name += info.event.extendedProps.customer.last_name + ' '

          toInject.push(c_name)
        } else {
          toInject.push('--')
        }

        if (info.event.extendedProps.pet_id) {
          toInject.push(info.event.extendedProps.pet_name)
        } else {
          toInject.push('--')
        }

        // MODIFICARE QUI PER LE STANZE
        let rooms = ''
        for (let i = 0; i < info.event.extendedProps.clinic_rooms.length; i++) {
          rooms += info.event.extendedProps.clinic_rooms[i].name + ' '
        }
        toInject.push(rooms)

        // Inserisce i dati nella riga in visualizzazione Agenda
        for (var i = 0; i < toInject.length; i++) {
          var columnElement = document.createElement('td')
          columnElement.textContent = toInject[i]
          info.el.append(columnElement)
        }
      }
    },

    goToday() {
      this.calendar.changeView('listDay', new Date())
    },

    goDate() {
      this.loadingSearch = true

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      this.calendar.changeView('timeGridDay', new Date(this.reportDate))
      this.$store.dispatch('commonComponents/hideLoadingModalAction')

      this.loadCalendar()
    },

    openCalendar() {
      document.getElementById('report-date-day').click()
    },

    handleClick(event) {
      let startDate
      let endDate
      startDate = moment.utc(moment(event.target.value, 'DD/MM/YYYY'))
      endDate = moment
        .utc(moment(event.target.value, 'DD/MM/YYYY'))
        .add('24', 'hours')

      let start = startDate.toISOString()
      this.reportDate = start
      /*endDate =
        moment.utc(moment(event.target.value, 'DD/MM/YYYY HH:mm')).unix() +
        86399*/
      let end = endDate.toISOString()

      this.startDate = this.$FormatEpochMin(this.$FormatDateTimeISO(start))
      this.endDate = this.$FormatEpochMin(this.$FormatDateTimeISO(end))

      console.log('inizio: ', start)
      console.log('fine: ', end)

      document.getElementById(event.target.id).style.position = 'relative'
      this.goDate()
    }
  }
})

export default Demo
</script>

<style>
.fc-list-event-graphic {
  display: none;
}

.fc-timegrid-event {
  /* height: fit-content !important; */

  /* padding: 4px; */
}

.timeGridWeek {
  display: flex;
  /* flex-direction: column; */
  align-items: start;
  justify-items: start;
}

.timeGridDay {
  display: flex;
  align-items: center;
}

.dayGridMonth {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
  justify-items: center;
}

.event-customer {
  display: flex;
  gap: 2px;
  align-items: center;
  flex-wrap: nowrap;
}

.fc-popover-body {
  overflow: auto;
  max-height: 25vh;
}

.appuntamento {
  width: 100% !important;
  overflow-x: hidden;
  text-transform: uppercase;
}

.event-time {
  font-weight: 700;
}

.picker-container {
  top: 15% !important;
  left: 14% !important;
  right: auto !important;
  display: flex !important;
  position: absolute !important;
}
</style>
