<template>
  <div
    class="tab-content relative bg-white intro-y box px-5 py-1 overflow-auto content-container scrollbar_custom"
  >
    <div class="paginator-container">
      <div class="prova mr-5 my-2 flex justify-between">
        <div class="mt-1">
          <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

          <select
            class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            @change="(event) => changeNumPages(event)"
          >
            <option v-for="show in forPageOption" :key="show" :value="show">
              {{ show }}
            </option>
          </select>
          <span class="text-md font-bold ml-2 mt-1"> elementi</span>
        </div>
      </div>
      <Paginator
        :for-page-option="pagination.forPage"
        :total-records="totalRecords"
        @changePagination="(event) => changePagination(event)"
      />
    </div>

    <!-- BEGIN: Data List -->

    <div
      class="table-container fatt-att-cont scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
    >
      <table v-if="tableConfigClinicDoc && documents" class="w-full">
        <thead class="t-header h-10">
          <tr>
            <th
              v-for="(item, itemKey) in table_config"
              :key="itemKey"
              class="px-2"
            >
              <span v-if="item.title == 'Data Creazione'"> Data Modifica </span>
              <span v-else> {{ item.title }} </span>
            </th>
            <th v-if="confirmCheck" class="t-header px-4">Conferma</th>
            <th class="t-header px-4">Azione rapide</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(elem, index) in table_config"
              :key="index"
              class="text-center px-1 py-3"
            >
              <select
                v-if="elem.type === 'select'"
                v-model="elem.value"
                class="w-full border rounded-md h-7 text-gray-500"
                @change="filterHandler(elem)"
              >
                <option selected></option>
                <option
                  v-for="(item, i) in elem.option"
                  :key="i"
                  :value="item.value"
                >
                  {{ item.name ? item.name : item }}
                </option>
              </select>
              <input
                v-else
                v-model="elem.value"
                :type="elem.type"
                min="0"
                :name="elem.title"
                class="form-control h-7"
                :class="{
                  invisible: elem.type === 'none',
                  'p-0': elem.type === 'date'
                }"
                :placeholder="elem.title"
                @change="filterHandler(elem)"
              />
            </td>
            <td v-if="confirmCheck" class="text-center px-1 py-3"></td>
          </tr>
          <!-- CONTROLLARE PERCHE' NON FILTRI -->
          <tr
            v-for="(doc, docKey) in documents"
            :key="docKey"
            class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
            :class="{ 'bg-theme-2': docKey % 2 === 0 }"
          >
            <td
              class="text-center text-xs uppercase border-r-2 border-theme-46"
            >
              {{
                doc.last_update != doc.creation_date
                  ? $FormatDateMin(doc.creation_date)
                  : ''
              }}
              {{ $FormatDateMin(doc.last_update) }}
            </td>
            <!-- <td class="text-center border-r border-gray-500">
            <LockIcon v-if="doc.lock === true" />
          </td> -->

            <td
              class="text-center text-xs uppercase border-r-2 border-theme-46"
            >
              {{ doc.layout_id.document_type }}
              {{
                Number(clinic_id_local) !== Number(doc.clinic_id)
                  ? '(' + doc.clinic?.name + ')'
                  : null
              }}
            </td>
            <td
              class="text-center text-xs uppercase border-r-2 border-theme-46"
            >
              {{
                doc.staff_user &&
                doc.staff_user.first_name &&
                doc.staff_user.last_name
                  ? doc.staff_user.first_name + ' ' + doc.staff_user.last_name
                  : doc.staff_user && doc.staff_user.username
                  ? doc.staff_user.username
                  : ''
              }}
            </td>

            <td
              class="text-center text-xs uppercase border-r-2 border-theme-46"
            >
              {{ doc.layout_id.reason ?? '' }}
            </td>
            <td v-if="global" class="flex flex-col">
              <template v-if="doc.pet && doc.pet.customers">
                <div
                  v-for="cust in doc.pet.customers"
                  :key="cust"
                  class="tooltipbuttonprofile flex mb-2"
                  @click="handleGoToCustomer(cust.id)"
                >
                  <ChevronsRightIcon
                    class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41 mr-2"
                  />
                  <span
                    class="tooltipbuttontext"
                    style="margin-top: -15px; margin-left: 110px"
                    >Apri anagrafica</span
                  >
                  <span class="whitespace-nowrap">{{
                    cust ? cust.last_name + ' ' + cust.first_name : '--'
                  }}</span>
                </div>
              </template>
              <div v-else>--</div>
            </td>

            <td v-if="!global" class="">
              <div class="flex justify-evenly items-center py-1">
                <input
                  class="form-check-switch"
                  :checked="doc.send_to_petcorner"
                  type="checkbox"
                  @click="
                    (event) => handleSetPetcorner(event.target.checked, doc)
                  "
                />
              </div>
            </td>
            <td v-else class="">
              <div
                v-if="doc.pet"
                class="tooltipbuttonprofile flex mb-2"
                @click.stop="() => handleGoToPet(doc.pet.id)"
              >
                <ChevronsRightIcon
                  class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                  style="margin-right: 5px"
                />
                {{ doc.pet ? doc.pet.name : '--' }}
                <span
                  class="tooltipbuttontext"
                  style="margin-top: -15px; margin-left: 90px"
                  >Apri anagrafica</span
                >
              </div>
              <div v-else>--</div>
            </td>
            <td
              v-if="confirmCheck"
              class="text-center text-xs uppercase border-r-2 border-theme-46"
            >
              <input
                class="form-check-switch"
                :checked="doc.is_checked"
                type="checkbox"
                @click="(event) => confirmCheckBox(event.target.checked, doc)"
              />
            </td>
            <td class="">
              <div class="flex items-center gap-1 justify-between px-3">
                <div
                  v-if="
                    !global && Number(clinic_id_local) === Number(doc.clinic_id)
                  "
                >
                  <a
                    v-if="
                      permissions['schede cliniche'] &&
                      permissions['schede cliniche']?.edit_medicaltemplate
                        ?.enabled === true
                    "
                    class="tooltipbutton mx-auto my-2 cursor-pointer fill-current px-3 hover:text-white"
                    @click="$emit('handleEdit', doc)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="w-3.5"
                    >
                      <path
                        d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                      />
                    </svg>
                    <span
                      class="tooltipbuttontext"
                      style="margin-top: -10px; margin-left: 50px"
                      >Modifica</span
                    >
                  </a>
                </div>

                <a
                  class="tooltipbutton w-5 h-5 flex items-center justify-center hover:text-white p-2 text-lg"
                  @click="$emit('showPdfPreview', doc)"
                >
                  <i class="fas fa-file-pdf text-base" />
                  <span class="tooltipbuttontext" style="margin-top: -10px"
                    >Visualizza PDF</span
                  >
                </a>
                <a
                  v-if="
                    permissions['schede cliniche'] &&
                    permissions['schede cliniche']?.delete_medicaltemplate
                      ?.enabled === true &&
                    Number(clinic_id_local) === Number(doc.clinic_id)
                  "
                  class="my-2 cursor-pointer hover:text-white"
                  @click="deleteRecord(doc)"
                >
                  <i class="fas fa-times text-center pr-3 text-base" />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="documents && documents.length === 0"
        class="w-full flex justify-center mt-2"
      >
        <span v-if="searching">
          <RotateCwIcon class="animate-spin w-10 scale-150" /> Caricamento in
          corso...
        </span>
        <span v-else>Nessun Risultato</span>
      </div>
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, computed, ref, toRaw } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'
// import _ from 'lodash'
import moment from 'moment'
import Paginator from '../../components/paginator/Main'

export default defineComponent({
  components: {
    Paginator
  },

  props: {
    tableConfigClinicDoc: {
      type: [Array],
      default: function () {
        return []
      }
    },
    documents: {
      type: [Array],
      default: function () {
        return []
      }
    },
    forPageOption: {
      type: [Array],
      default: function () {
        return [5, 10, 15]
      }
    },
    selectedPatient: Boolean,
    searching: Boolean,
    totalRecords: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    pagination: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    global: {
      type: [Boolean],
      default: function () {
        return false
      }
    },
    confirmCheck: {
      type: [Boolean],
      default: function () {
        return false
      }
    }
  },
  emits: [
    'handleSendMessage',
    'handleDetailsModal',
    'changePagination',
    'changeNumPages',
    'getMedical',
    'handleEdit',
    'showPdfPreview'
  ],
  setup(props, { emit }) {
    const store = useStore()
    const clinic_id_local = localStorage.getItem('clinic')

    const active_filters = ref({})
    const filterActive = ref(false)

    const filterHandler = (elem) => {
      if (elem.type == 'date') {
        active_filters.value[elem.key] = moment
          .utc(moment(elem.value).format('DD/MM/YYYY'), 'DD/MM/YYYY')
          .unix()
      } else {
        if (elem.value && elem.value !== '')
          active_filters.value[elem.key] = elem.value
        else active_filters.value[elem.key] = null
      }

      checkIfFilterActive()

      if (filterActive.value) emit('getMedical', active_filters.value)
    }

    const selectedKeys = ref(null)

    if (selectedKeys.value && selectedKeys.value.length > 0) {
      active_filters.value['keys_id'] = selectedKeys.value
    }

    const tablesRefresh = ref(false)

    const refreshTable = () => {
      tablesRefresh.value = true
      // getMedical(CC)
      // getMedical(SPECIALISTIC)
      // getMedical(REPORT)
      // getMedical(SUPPORT)
      // getMedical(LAB)
      // getMedical('all')
      // // aggiorno le tabelle

      // terminateAction()
    }

    const checkIfFilterActive = () => {
      var count = 0
      Object.entries(active_filters.value).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) filterActive.value = true
      else filterActive.value = false
    }

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (props.pagination.page != value.page) {
        emit('changePagination', value)
      }
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      emit('changeNumPages', elem)
    }

    const handleSetPetcorner = (checked, doc) => {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medical/set-send-to-petcorner',
          {
            medical_id: doc.medicaltemplate_id,
            send_to_petcorner: checked
          }
        ) // scarico la configurazione della tabella
        .then(() => {
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Successo'
          })
        })
        .catch(() => {
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore'
          })
        })
    }

    const confirmCheckBox = (checked, doc) => {
      axios.post(
        '/' + localStorage.getItem('clinic') + '/medical/confirmCheckBox',
        {
          medical_id: doc.medicaltemplate_id,
          checked: checked
        }
      )
    }

    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return JSON.parse(JSON.stringify(props.tableConfigClinicDoc)).filter(
        (elem) => !(elem.key === 'patient' && props.selectedPatient)
      )
    })

    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    return {
      table_config,
      changePagination,
      active_filters,
      filterHandler,
      checkIfFilterActive,
      changeNumPages,
      filterActive,
      handleSetPetcorner,
      confirmCheckBox,
      permissions,
      tablesRefresh,
      refreshTable,
      selectedKeys,
      clinic_id_local
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer',
      user: 'getUser'
    })
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      updateClientData: 'anagraficaClienti/updateClientData'
    }),
    handleGoToPet: function (id) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/' + id)
        .then((res) => {
          this.$goToPet(res.data)
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() =>
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        )
    },
    handleGoToCustomer(customer_id) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + customer_id)
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$goToCustomer(res.data)
        })
        .catch(() =>
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        )
    },
    tabAlreadyOpen: function () {
      this.$store.dispatch('commonComponents/openNotificationModal', {
        message: 'Tab già aperta'
      })
    },
    maxTabOpen: function () {
      this.$store.dispatch('commonComponents/openNotificationModal', {
        message: 'Numero massimo di tab aperte'
      })
    },
    // refreshTable() {
    //   this.$store.dispatch('commonComponents/openNotificationModal', {
    //     message: 'Logica Refresh'
    //   })
    // },
    handleEdit() {},
    deleteRecord(doc) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare la scheda clinica?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/medical/' +
                doc.pet_id +
                '/' +
                doc.medicaltemplate_id +
                '/delete'
            )
            .then((res) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: res.data.msg
              })
              this.$emit('getMedical', toRaw(this.active_filters))
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    }
  }
})
</script>
