<template>
  <!--BEGIN unpaid list-->
  <div
    class="my-2 mx-3 bg-white px-2 pt-6 pb-2 rounded-md shadow-md border border-theme-44 border-opacity-30"
  >
    <div
      v-if="
        permissions['conto aperto'] &&
        permissions['conto aperto']?.store_account?.enabled === true &&
        permissions['conto aperto']?.view_account?.enabled === true
      "
      class="flex-1 text-right mx-1 my-2 flex flex-col gap-2 xs:flex-row justify-end"
    >
      <button
        type="file"
        class="orange-btn-inverted px-2 py-1 flex flex-wrap items-end content-center justify-center"
        @click="showTariffario"
      >
        <PlusIcon class="h-5 w-5 mr-1 margin-bottom: 0.2" /> Tariffario
      </button>

      <!-- TODO aggiunta riga libera direttamente nel conto aperto -->
      <button
        v-if="
          permissions &&
          permissions?.fatturazione?.create_empty_row_billing?.enabled === true
        "
        type="file"
        class="orange-btn-inverted px-2 py-1 flex flex-wrap items-end content-center justify-center"
        @click="openNewLine"
      >
        <PlusIcon class="h-5 w-5 mr-1 margin-bottom: 0.2" /> Nuovo
      </button>

      <button
        type="file"
        class="orange-btn-inverted px-2 py-1 flex flex-wrap items-end content-center justify-center"
        @click="showAcconto"
      >
        <PlusIcon class="h-5 w-5 mr-1 margin-bottom: 0.2" /> Acconto
      </button>
      <div v-if="openTariffario">
        <TariffarioModal @close="showTariffario" @addRow="addTariff" />
      </div>
      <div v-if="accontoModal">
        <AccontoModal @close="accontoModal = false" @addRow="addAcc" />
      </div>
    </div>
    <div
      v-if="
        permissions['conto aperto'] &&
        permissions['conto aperto']?.view_account?.enabled === true
      "
    >
      <div class="prova mr-5 my-2 flex justify-between">
        <div class="mt-1">
          <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>
          <select
            class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            @change="(event) => changeNumPages(event)"
          >
            <option v-for="show in forPageOption" :key="show" :value="show">
              {{ show }}
            </option>
          </select>
          <span class="text-md font-bold ml-2 mt-1"> elementi</span>
        </div>
      </div>
      <Paginator
        :for-page-option="pagination.forPage"
        :total-records="totalRecords"
        @changePagination="changePagination"
      />
    </div>

    <!-- BEGIN: Data List Dynamic -->
    <div
      v-if="
        permissions['conto aperto'] &&
        permissions['conto aperto']?.view_account?.enabled === true
      "
      class="table-container scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
    >
      <table
        v-if="tableHeader_unpaid.config"
        class="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <thead class="">
          <tr>
            <th
              v-for="(item, itemKey) in tableHeader_unpaid.config"
              :key="itemKey"
              class="px-6 py-3 text-white border-b-2 border-theme-41 whitespace-nowrap text-center font-semibold bg-theme-40 uppercase"
            >
              <div v-if="item.icon">
                <i :class="item.icon" class="text-sm" />
              </div>
              <div v-else>
                {{
                  item.key === 'total'
                    ? iva === false
                      ? item.title + ' senza IVA'
                      : item.title + ' con IVA'
                    : item.title
                }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(elem, index) in tableHeader_unpaid.input"
              :key="index"
              class="text-center pr-3 pb-3"
            >
              <select
                v-if="elem.type === 'select'"
                v-model="table_filter[elem.key]"
                class="border rounded-md h-7 text-gray-500 mt-2"
              >
                <option selected></option>
                <option v-for="(item, i) in elem.option" :key="i" :value="item">
                  {{ item.name ? item.name : item }}
                </option>
              </select>
              <input
                v-else
                v-model="elem.value"
                :type="elem.type"
                min="0"
                :name="elem.title"
                class="form-control h-7 mt-2"
                :class="{
                  invisible: elem.type === 'none',
                  'p-0': elem.type === 'date'
                }"
                :placeholder="elem.title"
                @change="filterHandler(elem)"
              />
            </td>
          </tr>
          <tr
            v-for="(row, index) in documentsToShow"
            :key="index"
            class="h-12 text-center text-base font-normal hover:bg-theme-44 cursor-pointer"
            :class="{ 'bg-theme-2': index % 2 === 0 }"
          >
            <td class="text-center border-r border-theme-45">
              <TableShotColor :stato="row.status" />
            </td>
            <Cell
              :elementi="$FormatDateMin(row.last_update)"
              class="border-r border-theme-45 text-center"
            />
            <td class="text-center border-r border-theme-45">
              <p>
                {{
                  row.tariffario_alias && row.tariffario_alias !== ''
                    ? row.tariffario_alias
                    : row.tariffario_description ?? row.description
                }}
                <br />
                <span v-if="row.type !== 'alias'" class="text-xs">{{
                  row.tariffario_description
                }}</span>
              </p>
            </td>

            <td class="text-center border-r border-theme-45">
              <template v-if="row.pet_name">
                {{ row.pet_name }}
              </template>
              <i
                v-else
                class="fas fa-paw w-4 h-4 mr-2 tooltipbutton"
                @click="
                  row.status != 'completed' &&
                  row.status != 'debito' &&
                  row.status != 'to_pay' &&
                  row.status != 'bozza' &&
                  permissions['conto aperto'] &&
                  permissions['conto aperto']?.edit_account?.enabled === true
                    ? openSelectPet(row.id)
                    : () => {}
                "
                ><span
                  v-if="
                    row.status != 'completed' &&
                    row.status != 'debito' &&
                    row.status != 'to_pay' &&
                    row.status != 'bozza' &&
                    permissions['conto aperto'] &&
                    permissions['conto aperto']?.edit_account?.enabled === true
                  "
                  class="tooltipbuttontext"
                  style="
                    font-family: Roboto;
                    text-transform: uppercase;
                    font-weight: 400;
                    margin-left: -0.5rem;
                  "
                  >Aggiungi Pet</span
                ></i
              >
            </td>
            <td
              class="text-center border-r border-theme-45"
              @click="
                row.status != 'completed' &&
                row.status != 'debito' &&
                row.status != 'to_pay' &&
                row.status != 'bozza' &&
                permissions['conto aperto'] &&
                permissions['conto aperto']?.edit_account?.enabled === true
                  ? openSelectVet(row.id, row.performed_by)
                  : () => {}
              "
            >
              <template v-if="row.performed_by">
                <span v-for="p in row.performed_by" :key="p">
                  {{ p.full_name }} <br />
                </span>
              </template>
              <template v-else>
                <button v-if="!selectedVet[index]" class="tooltipbutton">
                  <span
                    class="tooltipbuttontext"
                    style="
                      width: 160px;
                      font-family: Roboto;
                      text-transform: uppercase;
                      font-weight: 400;
                      margin-left: 0.5rem;
                    "
                    >Aggiungi Veterinario</span
                  >
                  <i class="fas fa-user-md w-4 h-4 mr-2" />
                </button>
                <select v-else>
                  <option v-for="vet in vets" :key="vet.id">
                    {{ vet.last_name }} {{ vet.first_name }}
                  </option>
                </select>
              </template>
            </td>
            <!-- <td class="text-center border-r border-theme-45">
              <span v-if="row.deleted_at"
                ><i class="fas fa-check text-gray-400"
              /></span>
              <span v-else><i class="fas fa-times text-theme-41" /></span>
            </td> -->
            <Cell
              :elementi="
                Math.round((row.price * 1 + Number.EPSILON) * 100) / 100 + '€'
              "
              class="border-r border-theme-45 text-center items-center justify-center"
            />
            <td class="border-r border-theme-45 text-center">
              <input
                v-if="
                  row.status != 'completed' &&
                  row.status != 'debito' &&
                  row.status != 'to_pay' &&
                  row.status != 'bozza' &&
                  permissions['conto aperto'] &&
                  permissions['conto aperto']?.edit_account?.enabled === true
                "
                type="number"
                :value="row.qty"
                class="w-12 custom-input"
                :readonly="row.status == 'completed' && row.status == 'debito'"
                @change="(event) => updateQty(row.id, event.target.value)"
              />
              <span v-else>{{ row.qty }}</span>
            </td>
            <td class="border-r border-theme-45 text-center">
              
              <span >{{ row.tax_rate_percentage ?? ivaSetup }} %</span>
            </td>
            <td
              class="border-r border-theme-45 text-center items-center justify-center"
            >
              <input
                v-if="
                  row.status != 'completed' &&
                  row.status != 'debito' &&
                  row.status != 'to_pay' &&
                  row.status != 'bozza' &&
                  permissions['conto aperto'] &&
                  permissions['conto aperto']?.edit_account?.enabled === true
                "
                type="number"
                :value="row.sconto_price"
                class="w-12 custom-input"
                :readonly="row.status == 'completed' && row.status == 'debito'"
                @change="
                  (event) =>
                    updateSconto(row.id, event.target.value, row.priceiva)
                "
              />
              <span v-else>{{ row.sconto_price }}</span> €
            </td>
            <Cell
              :elementi="parseFloat(row.total).toFixed(2) + '€'"
              class="border-r border-theme-45 text-center"
            />
            <td
              v-if="
                row.status != 'completed' &&
                row.status != 'to_pay' &&
                row.status != 'bozza' &&
                permissions['conto aperto'] &&
                permissions['conto aperto']?.store_account?.enabled === true
              "
              class="flex mt-3 text-xl"
            >
              <a
                v-if="
                  permissions['conto aperto'] &&
                  permissions['conto aperto']?.delete_account &&
                  permissions['conto aperto']?.delete_account?.enabled === true
                "
                class="cursor-pointer border-r border-theme-45 mb-2"
                @click="
                  deleteRow((pagination.page - 1) * pagination.forPage + index)
                "
              >
                <i class="fas fa-times px-3" style="margin-bottom: 20%" />
              </a>
              <input
                v-model="
                  multipleChecks[
                    (pagination.page - 1) * pagination.forPage + index
                  ]
                "
                type="checkbox"
                class="mx-3 mt-1 custom-checkbox"
                @click="
                  (event) =>
                    getSum(
                      (pagination.page - 1) * pagination.forPage + index,
                      event
                    )
                "
              />
            </td>
          </tr>
          <tr v-if="sumCheckedService && sumCheckedService >= 0">
            <td v-for="i in tableHeader_unpaid.config.length - 2" :key="i"></td>
            <td class="border border-theme-45 text-center font-bold">
              tot:
              {{
                Math.round(
                  (sumCheckedService * (iva ? ivaPerc : 1) + Number.EPSILON) *
                    100
                ) / 100
              }}
              €
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="tableHeader_unpaid.config && totalRecords == ''"
        class="w-full flex justify-center mt-2 py-2"
      >
        Nessun Risultato
      </div>
    </div>
    <!-- END: Data List Dynamic -->
    <!-- BEGIN: Emetti nuova fattura -->
    <div
      v-if="
        permissions['conto aperto'] &&
        permissions['conto aperto']?.store_account?.enabled === true &&
        permissions['conto aperto']?.view_account?.enabled === true
      "
      id="button-new-invoice"
      class="text-right m-5"
    >
      <button
        id="new-invoice"
        class="orange-btn px-3 py-2"
        @click="openInvoiceModal"
      >
        Emetti nuova fattura
      </button>
      <button
        id="new-invoice"
        class="orange-btn px-3 py-2 ml-3"
        @click="createNotaDebito()"
      >
        Attestazione di debito
      </button>
    </div>
    <div v-if="invoiceModalOpen">
      <NewInvoiceModal
        :services="services"
        :client="customerDetails"
        :only-acconto="newAccontoFattura"
        :nota-debito="notaDebito"
        :modify="false"
        :title="newInvoiceTitle"
        @close="closeInvoiceModal"
        @responseModal="
          (t, r) => {
            newAccontoFattura = false
            invoiceModalOpen = false
            $emit('showConfirm', t, r)
          }
        "
      />
    </div>
    <SelectPatientModal
      v-if="selectPetModal || selectVetModal"
      :title="selectModalTitle"
      :client="selectPetModal ? customerDetails : null"
      :vets-selected="selectedVets"
      @close="closeSelectPet"
      @selectPatient="selectPatientConto"
      @selectVet="selectVetConto"
    />

    <NewLineModal
      v-if="newLineModal"
      :title="'Nuova Riga Libera'"
      @confirm="addService"
      @close="
        () => {
          newLineModal = false
        }
      "
    />
    <!-- END: Emetti nuova fattura-->
  </div>
  <!--END unpaid list-->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Paginator from '../paginator/Main.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import NewInvoiceModal from '../new-invoice/NewInvoiceModal.vue'
import TariffarioModal from '../new-invoice/TariffarioModal.vue'
import SelectPatientModal from '../modals/SelectPatientModal.vue'
import NewLineModal from '../modals/NewLineModal.vue'
import AccontoModal from '../new-invoice/AccontoModal.vue'
import TableShotColor from './TableShotColor.vue'
import axios from '../../gateway/backend-api'

const forPageOption = [25, 35, 50]
export default defineComponent({
  components: {
    Paginator,
    Cell,
    NewInvoiceModal,
    TariffarioModal,
    AccontoModal,
    TableShotColor,
    SelectPatientModal,
    NewLineModal
  },
  props: {
    customerDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    active: {
      type: [Boolean],
      default: true
    }
  },
  emits: ['showConfirm'],
  data() {
    return {
      selectPetModal: false,
      selectVetModal: false,
      selectedVets: null,
      selectModalTitle: null,
      vets: [],
      selectedVet: [],
      filterActive: false,
      active_filters: {},
      table_filter: [],
      tableHeader_unpaid: {
        config: [
          {
            key: 'status',
            title: 'Stato'
          },
          {
            key: 'created',
            title: 'Creato il'
          },
          {
            key: 'product',
            title: 'Prestazione/Prodotto'
          },
          // {
          //   key: 'warehouse',
          //   icon: 'fas fa-truck'
          // },
          {
            key: 'patient',
            title: 'Paziente'
          },
          {
            key: 'staff_user',
            title: 'Eseguito da'
          },
          // {
          //   key: 'att',
          //   title: 'Att.'
          // },
          {
            key: 'price',
            title: 'Prezzo'
          },
          {
            key: 'qty',
            title: 'Qtà'
          },
          {
            key: 'vat',
            title: 'Iva %'
          },
          {
            key: 'discount',
            title: 'Prezzo Scontato'
          },
          {
            key: 'total',
            title: 'Totale'
          },
          {
            key: 'quick-actions',
            title: ''
          }
        ],
        input: [
          {
            key: 'status',
            title: 'Stato',
            type: 'select',
            option: []
          },
          {
            key: 'created',
            title: 'Creato il',
            type: 'date'
          },
          {
            key: 'product',
            title: 'Prestazione/Prodotto',
            type: 'text'
          },
          // {
          //   key: 'warehouse',
          //   title: '',
          //   type: 'none'
          // },
          {
            key: 'patient',
            title: 'Paziente',
            type: 'none'
          },
          {
            key: 'staff_user',
            title: 'Eseguito da',
            type: 'text'
          },
          // {
          //   key: 'att',
          //   type: 'none'
          // },
          {
            key: 'price',
            title: 'Prezzo',
            type: 'number'
          },
          {
            key: 'qty',
            title: 'Quantità',
            type: 'number'
          },
          {
            key: 'discount',
            title: 'Sconto',
            type: 'number'
          },
          {
            key: 'total',
            title: 'Totale',
            type: 'number'
          }
        ]
      },
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      serviceRows: [],
      documentsToShow: [],
      multipleChecks: [],
      sumCheckedService: 0,
      sumIds: [],
      invoiceModalOpen: false,
      servizio: '',
      checkedServices: [],
      services: [],
      clientForInvoice: null,
      openTariffario: false,
      accontoModal: false,
      selected_row: null,
      newAccontoFattura: false,
      notaDebito: false,
      newInvoiceTitle: null,
      newLineModal: false
    }
  },
  computed: {
    ...mapGetters({
      iva: 'reusableData/getShowIva',
      permissions: 'reusableData/getPermissions',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc',
      ivaSetup: 'reusableData/getRegimeFiscaleIva'
      
    })
  },
  watch: {
    invoiceModalOpen: function (elem) {
      if (elem) {
        // this.$customStyle(this.invoiceModalOpen, 'grey-topbar', 'z-30')
        // this.$customStyle(this.invoiceModalOpen, 'mob-bar', 'z-30')
      }
    },
    'table_filter.status': function (elem) {
      this.filterSelect(elem, 'status')
    }
  },
  created() {
    var vm = this

    if (vm.active) {
      // Effettuare la chiamata per riempire serviceRows con le prestazioni ancora da fatturare
      vm.getServices()
      //funzione per chiamata veterinari
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff')
        .then((res) => {
          vm.vets = res.data
        })
      //funzione per status account
      axios
        .get('/' + localStorage.getItem('clinic') + '/account/accountStatusMap')
        .then((res) => {
          Object.keys(res.data).forEach(function (k) {
            vm.tableHeader_unpaid.input[0].option.push({
              name: k,
              value: res.data[k]
            })
          })
        })
    }
  },
  methods: {
    showAcconto() {
      this.accontoModal = !this.accontoModal
    },
    showTariffario() {
      this.openTariffario = !this.openTariffario
    },
    controlBeforeInvoice() {
      let plus = 0
      let minus = 0
      for (let i = 0; i < this.services.length; i++) {
        if (this.services[i].stato === 'acconto') {
          minus += this.services[i].price
        } else {
          plus += this.services[i].price
        }
      }
      return plus - minus >= 0
    },

    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getServices()
      }
    },
    changeNumPages: function (elem) {
      if (this.pagination.forPage != elem.target.value) {
        this.pagination.forPage = elem.target.value
        this.getServices()
      }
    },
    fakePagination: function () {
      this.documentsToShow = this.serviceRows.slice(
        (this.pagination.page - 1) * this.pagination.forPage,
        this.pagination.page * this.pagination.forPage
      )
    },
    getServices: function (clear_selections = false) {
      var params = {
        customer_ids: [this.customerDetails.id],
        active_filters: this.active_filters,
        iva: this.iva,
        page: 1, //this.pagination.page,
        per_page: 400 //this.pagination.forPage
      }
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/account', params)
        .then((res) => {
          this.serviceRows = res.data.data
          this.totalRecords = res.data.total

          this.serviceRows.forEach((elem, index) => {
            if (clear_selections) {
              this.multipleChecks[index] = false
            } else {
              if (this.sumIds.includes(elem.id)) {
                this.multipleChecks[index] = true
              } else {
                this.multipleChecks[index] = false
              }

              if (elem.sconto && elem.sconto * 1 !== 0) {
                this.serviceRows[index].sconto_price =
                  Math.round(
                    (elem.price * (this.iva ? this.ivaPerc : 1) +
                      elem.price *
                        (this.iva ? this.ivaPerc : 1) *
                        ((elem.sconto * 1) / 100) +
                      Number.EPSILON) *
                      100
                  ) / 100
              }
            }
          })
          if (clear_selections) this.clearSelections()
          this.fakePagination()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    clearSelections() {
      this.sumCheckedService = 0
      this.sumIds = []
    },
    getSum(i, e, remove = false) {
      let tot = 0
      tot =
        (this.serviceRows[i].price * this.serviceRows[i].qty * 1 +
          ((this.serviceRows[i].price * this.serviceRows[i].qty) / 100) *
            this.serviceRows[i].sconto *
            1) *
        (this.serviceRows[i].cod_tariffa_alias === 'T4U_A0002' ? -1 : 1)

      if ((e && e.target.checked === false) || remove) {
        this.sumIds = this.sumIds.splice(
          this.sumIds.indexOf(this.serviceRows[i].id),
          1
        )
        this.sumCheckedService -= tot
      } else {
        this.sumIds.push(this.serviceRows[i].id)

        this.sumCheckedService += tot
      }
    },
    openInvoiceModal() {
      let count = 0
      let sum = 0
      for (let i = 0; i < this.multipleChecks.length; i++) {
        if (this.multipleChecks[i] == true) {
          if (this.serviceRows[i].cod_tariffa_alias === 'T4U_A0002') {
            sum -=
              this.serviceRows[i].price * this.serviceRows[i].qty * 1 +
              ((this.serviceRows[i].price * this.serviceRows[i].qty) / 100) *
                this.serviceRows[i].sconto
          } else {
            sum +=
              this.serviceRows[i].price * this.serviceRows[i].qty * 1 +
              ((this.serviceRows[i].price * this.serviceRows[i].qty) / 100) *
                this.serviceRows[i].sconto
          }
        }
      }

      if (sum < 0) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di controllare le prestazioni selezionate'
        })
        return
      }

      for (let i = 0; i < this.multipleChecks.length; i++) {
        if (this.multipleChecks[i] == true) {
          count += 1
          //this.checkedServices.push(this.serviceRows[i])
          this.services.push({
            ...this.serviceRows[i],
            id_account: this.serviceRows[i].id
          })
          this.multipleChecks[i] = false
        }
        this.sumIds = []
        this.sumCheckedService = 0
      }
      if (count > 0) {
        this.invoiceModalOpen = !this.invoiceModalOpen
        // if (this.controlBeforeInvoice() === true) {
        //   this.invoiceModalOpen = !this.invoiceModalOpen
        // } else {
        //   this.services = []
        //   this.$store.dispatch('commonComponents/openMessageModal', {
        //     title: 'Attenzione',
        //     subtitle: 'Acconto maggiore delle prestazioni selezionate'
        //   })
        // }
      } else {
        this.$store.dispatch('commonComponents/openMessageModal', {
          title: 'Attenzione',
          subtitle:
            'Devi selezionare almeno una prestazione per passare alla fatturazione'
        })
        this.invoiceModalOpen = false
      }
    },

    closeInvoiceModal() {
      this.newAccontoFattura = false
      this.invoiceModalOpen = false
      this.notaDebito = false
      this.services = []
    },
    openNewLine() {
      this.newLineModal = true
    },
    addService(params) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/account/' +
            this.customerDetails.id,
          params
        )
        .then(() => {
          this.openTariffario = false
          this.accontoModal = false
          this.totalRecords = this.serviceRows.length + 1
          this.getServices()
        })
    },
    // Aggiunge una riga in base alla tariffa selezionata dal tariffario
    addTariff(tariffario, selectedPrice, path, type) {
      console.log(tariffario)
      var params = {
        price: selectedPrice.data,
        //code: tariffario.code_id ? tariffario.code_id : null,
        qty: 1,
        sconto:
          type === 'Distinte'
            ? 0 -
              (tariffario.discount ? tariffario.discount * 1 : 0) +
              (tariffario.greatest ? tariffario.greatest * 1 : 0)
            : 0,
        status: 'pending'
        // tariffario_alias_id: tariffario.tariffario_alias_id
        //   ? tariffario.tariffario_alias_id
        //   : null
      }

      if (tariffario.code_id) params.code = tariffario.code_id
      if (tariffario.tariffario_alias_id)
        params.tariffario_alias_id = tariffario.tariffario_alias_id
      else params.tariffario_packages_id = tariffario.id

      this.addService(params)
    },
    addAcc(value) {
      // to modify
      this.services.push({
        price: value,
        qty: 1,
        sconto: 0,
        status: 'acconto'
      })

      this.newAccontoFattura = true
      this.invoiceModalOpen = true

      //var params = this.addService(params)
    },
    deleteRow(index) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare questa riga?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/account/' +
                this.serviceRows[index]['id'] +
                '/delete'
            )
            .then(() => {
              this.multipleChecks = []
              this.sumCheckedService = 0

              this.getServices(true)
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    },
    chooseVet(index) {
      this.selectedVet[index] = true
    },
    updateQty(id, qty) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/account/edit', {
          qty: qty,
          account_ids: [id]
        })
        .then(() => {
          this.getServices(true)
        })
    },
    updateSconto(id, sconto_price, priceIva) {
      console.log(id, sconto_price, priceIva)
      let sconto = 0
      if (sconto_price && sconto_price !== '') {
        sconto = (100 * (sconto_price - priceIva)) / priceIva
      }

      axios
        .post('/' + localStorage.getItem('clinic') + '/account/edit', {
          sconto: sconto,
          account_ids: [id]
        })
        .then(() => {
          this.getServices(true)
        })
    },
    openSelectPet(id_row) {
      this.selectPetModal = true
      this.selectModalTitle = 'Selezionare paziente'
      this.selected_row = id_row
    },
    openSelectVet(id_row, performed_by) {
      this.selectVetModal = true
      this.selectModalTitle = 'Selezionare Veterinario'
      this.selected_row = id_row
      if (performed_by) this.selectedVets = performed_by
    },
    closeSelectPet() {
      this.selectPetModal = false
      this.selectVetModal = false
      this.selectedVets = null
      this.selected_row = null
      this.selectModalTitle = null
    },
    selectPatientConto(patient) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/account/edit', {
          pet_id: patient.id,
          account_ids: [this.selected_row]
        })
        .then(() => {
          this.getServices()
        })
      this.closeSelectPet()
    },
    selectVetConto(vets) {
      let send = []
      if (vets)
        vets.forEach((el) => {
          send.push(el.id)
        })
      axios
        .post('/' + localStorage.getItem('clinic') + '/account/edit', {
          //staff_user_id: vet.id,
          performed_by: send,
          account_ids: [this.selected_row]
        })
        .then(() => {
          this.getServices()
        })
      this.closeSelectPet()
    },
    createNotaDebito() {
      var pass = true
      for (let i = 0; i < this.multipleChecks.length; i++) {
        if (this.multipleChecks[i] == true) {
          if (this.serviceRows[i].status === 'debito') {
            pass = false
          }
        }
      }
      if (pass === false) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Una delle prestazioni selezionate è già una nota di debito'
        })

        return
      }
      this.notaDebito = true
      this.newInvoiceTitle = 'Nuova Nota di debito'
      this.openInvoiceModal()
    },

    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) {
        this.active_filters[field] = elem.id ? elem.id : elem.value
      } else this.active_filters[field] = undefined

      this.checkIfFilterActive()

      if (this.filterActive) this.getServices()
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getServices()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
  }
})
</script>
