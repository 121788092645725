<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none bg-opacity-50"
    tabindex="-1"
    aria-hidden="false"
  >
    <div
      class="relative top-8 px-2 pb-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white w-11/12"
    >
      <div class="flex justify-end mb-10">
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <div id="modal-content">
        <h2 class="modal-title">
          {{
            title
              ? datiDocumento?.tipoDocumento?.code === 'TD05' && !notaDebito
                ? 'Riepilogo Nota di debito'
                : title
              : 'Nuova fattura'
          }}
        </h2>

        <div
          class="px-4 mt-1 pb-6 overflow-auto scrollbar-custom"
          style="height: 65vh"
        >
          <!-- BEGIN: FORM FATTURAZIONE -->

          <form class="grid grid-cols-2 gap-2">
            <!-- BEGIN: DATI DOCUMENTO -->
            <div class="col-span-2 md:col-span-1 p-2 mt-2">
              <h3 class="stats-subs text-base">DATI DOCUMENTO</h3>

              <div class="grid grid-cols-2 gap-2 mt-2 px-2">
                <div v-if="false" class="flex flex-col gap-1">
                  <label for="" class="form-label mb-0">Tipo cliente</label>
                  <span
                    v-if="clientInvoice"
                    class="custom-input uppercase text-sm"
                    >{{
                      clientInvoice.type == 'PA'
                        ? 'Pubblica Amministrazione'
                        : clientInvoice.type
                    }}</span
                  >
                </div>
                <div class="flex flex-col gap-1">
                  <label for="" class="form-label mb-0">Tipo documento</label>

                  <select
                    v-model="datiDocumento.tipoDocumento"
                    :disabled="
                      documento ||
                      readOnly ||
                      onlyAcconto ||
                      notaCredito ||
                      notaDebito ||
                      true
                    "
                    class="custom-input uppercase text-sm"
                  >
                    <option
                      v-for="n in documentTypes"
                      :key="n.id"
                      :value="n"
                      class="border border-gray-300 uppercase text-sm"
                    >
                      {{ n.description }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-col gap-1">
                  <label for="modal-form-5" class="form-label mb-0">
                    Data documento
                  </label>
                  <input
                    id="modal-form-5"
                    v-model="today"
                    type="text"
                    class="custom-input uppercase text-sm"
                    disabled
                    @change="formatDate"
                  />
                </div>

                <div class="flex flex-col gap-1">
                  <label for="" class="form-label mb-0">Sezionale</label>
                  <select
                    v-if="!sezionali"
                    v-model="datiDocumento.sezionale"
                    :disabled="notaDebito || notaCredito || modify || readOnly"
                    class="custom-input uppercase text-sm"
                  >
                    <template v-if="clientInvoice?.type == 'PA'">
                      <option value="FPA" selected>FPA</option>
                    </template>
                    <template v-else>
                      <option
                        value="FPR"
                        :selected="datiDocumento.sezionale === 'FPR'"
                      >
                        FPR
                      </option>
                      <option
                        value="NC"
                        :selected="datiDocumento.sezionale === 'NC'"
                      >
                        NC
                      </option>
                      <option
                        value="ND"
                        :selected="datiDocumento.sezionale === 'ND'"
                      >
                        ND
                      </option>
                      <!-- <option
                        value="FPRE"
                        :selected="datiDocumento.sezionale === 'FPRE'"
                      >
                        FPRE
                      </option> -->
                    </template>
                  </select>
                  <select
                    v-else
                    v-model="datiDocumento.sezionale"
                    :disabled="
                      notaDebito ||
                      notaCredito ||
                      modify ||
                      readOnly ||
                      fatturaPreventivo
                    "
                    class="custom-input uppercase text-sm"
                  >
                    <template
                      v-for="opt in sezionali.filter(
                        (el) =>
                          (!(notaDebito || notaCredito) && !el.hidden) ||
                          notaDebito ||
                          notaCredito
                      )"
                      :key="opt.id"
                    >
                      <option
                        :value="opt.label"
                        :selected="isDefaultSectional(opt.label)"
                      >
                        {{ opt.label }}
                      </option>
                    </template>
                  </select>
                </div>
                <div
                  v-if="
                    readOnlyElem &&
                    readOnlyElem.status_doc !== 'bozza' &&
                    !notaCredito &&
                    !notaDebito
                  "
                  class="flex flex-col gap-1 text-sm"
                >
                  <label for="" class="form-label mb-0">{{
                    fatturaPreventivo ? 'Progressivo Sezionale' : 'Progressivo'
                  }}</label>

                  <input
                    :value="readOnlyElem.n_bill ? readOnlyElem.n_bill : '---'"
                    class="custom-input uppercase text-sm"
                    disabled
                  />
                </div>
                <div
                  v-else-if="prefissoSezionale && isNewInvoice()"
                  class="flex flex-col gap-1"
                >
                  <label for="" class="form-label mb-0">Suffisso</label>

                  <select
                    v-model="prefisso"
                    :disabled="
                      (modify &&
                        readOnlyElem &&
                        readOnlyElem.status_doc !== 'bozza') ||
                      notaCredito ||
                      true
                    "
                    class="custom-input uppercase text-sm"
                  >
                    <option
                      v-for="el in prefissoSezionale"
                      :key="el"
                      :value="el.id"
                    >
                      {{ el.suffix }} - {{ el.description }}
                    </option>
                  </select>
                </div>

                <div
                  v-if="datiDocumento.sezionale === 'FPA'"
                  class="flex flex-col gap-1"
                >
                  <label for="cigCode" class="form-label mb-0"
                    >Codice CIG</label
                  >
                  <input
                    id="cigCode"
                    v-model="cigCode"
                    type="text"
                    class="custom-input uppercase text-sm"
                  />
                </div>

                <div
                  v-if="datiDocumento.sezionale === 'FPA'"
                  class="flex flex-col gap-1"
                >
                  <label for="cupCode" class="form-label mb-0"
                    >Codice CUP</label
                  >
                  <input
                    id="cupCode"
                    v-model="cupCode"
                    type="text"
                    class="custom-input uppercase text-sm"
                  />
                </div>

                <div
                  v-if="readOnlyElem && readOnlyElem.from_active_bill_n_doc"
                  class="flex flex-col gap-1 text-sm"
                >
                  <label for="" class="form-label mb-0"
                    >Riferimento Fattura</label
                  >

                  <span
                    v-if="readOnlyElem.from_active_bill_n_doc"
                    class="custom-input uppercase text-sm"
                    >{{ readOnlyElem.from_active_bill_n_doc }}</span
                  >
                </div>
                <div
                  v-if="readOnlyElem && readOnlyElem.nota_di_credito_n_doc"
                  class="flex flex-col gap-1 text-sm"
                >
                  <label for="" class="form-label mb-0"
                    >Riferimento Nota di credito</label
                  >
                  <span
                    v-if="readOnlyElem.nota_di_credito_n_doc"
                    class="custom-input uppercase text-sm"
                    >{{ readOnlyElem.nota_di_credito_n_doc }}</span
                  >
                </div>
                <div
                  v-if="datiDocumento?.tipoDocumento?.id === 5"
                  class="flex flex-col gap-1"
                >
                  <label for="cigCode" class="form-label mb-0"
                    >Giorni validità preventivo</label
                  >
                  <input
                    id="dataSaldo"
                    v-model="data_saldo"
                    type="date"
                    :disabled="readOnly"
                    class="custom-input uppercase text-sm"
                  />
                </div>
                <div
                  v-if="datiDocumento?.tipoDocumento?.id === 19"
                  class="flex flex-col gap-1"
                >
                  <label for="cigCode" class="form-label mb-0"
                    >Scadenza preventivo</label
                  >
                  <input
                    id="dataSaldo"
                    v-model="data_preventivo"
                    type="number"
                    :disabled="readOnly"
                    class="custom-input uppercase text-sm"
                  />
                </div>
              </div>
            </div>
            <!-- END: DATI DOCUMENTO -->
            <!-- BEGIN: DATI CLIENTE -->
            <div class="col-span-2 md:col-span-1 p-2 mt-2">
              <h3 class="stats-subs text-base">DATI CLIENTE</h3>
              <div
                v-if="clientInvoice == null"
                class="flex-col items-center justify-center py-2"
              >
                <div class="flex items-center">
                  <div class="flex-1">
                    <input
                      v-model="searchBar"
                      type="text"
                      class="pl-5 ml-1 w-full h-9 border border-gray-300 rounded-full text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                      placeholder="Ricerca rapida nell'anagrafica"
                      @click.stop="dropDownSearch"
                      @keydown="isActiveSearch = true"
                      @keydown.esc="closeDropDownSearch"
                    />
                    <div class="relative">
                      <SearchDrop
                        v-show="isActiveSearch === true"
                        class="w-full search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 left-1/2 top-0"
                        :filter-categories="['customer']"
                        :filter="searchBar"
                        @childToParent="onChildClick"
                        @closeDropDownSearch="isActiveSearch = false"
                      />
                    </div>
                  </div>

                  <div class="w-20 ml-2 pl-3">
                    <SearchIcon
                      class="search__icon dark:text-gray-300 mr-2"
                      @click="toggleModal"
                    />
                    <UserPlusIcon @click="showModalEditClient = true" />
                    <NewClient
                      v-if="showModalEditClient"
                      :title="'Aggiungi un nuovo cliente'"
                      @close="showModalEditClient = false"
                    />
                  </div>
                </div>
              </div>

              <div v-else class="px-2">
                <div class="flex items-center justify-end gap-1 py-1">
                  <button
                    v-if="false"
                    type="button"
                    title="Modifica cliente"
                    class="orange-btn w-1/12 mx-0 px-0 flex justify-center"
                    @click="showModalEditClient = true"
                  >
                    <i class="fas fa-user-edit fa-md" />
                  </button>
                  <NewClient
                    v-if="showModalEditClient"
                    :title="'Modifica cliente'"
                    :client="clientInvoice"
                    @close="showModalEditClient = false"
                  />
                  <!-- Inserire qui modale modifica anagrafica -->
                  <button
                    v-if="!readOnly"
                    type="button"
                    value="Cambia cliente"
                    class="orange-btn w-1/12 mx-0 px-0 flex justify-center tooltipbutton"
                    @click="wantToSearchClient"
                  >
                    <i class="fas fa-people-arrows fa-lmd" />
                    <span
                      class="flex tooltipbuttontext justify-center"
                      style="margin-left: 3.75rem"
                      >Cambia Cliente</span
                    >
                  </button>
                </div>
                <div class="flex flex-col gap-2">
                  <div
                    v-if="clientInvoice.type"
                    class="flex items-center gap-1"
                  >
                    <i
                      class="fas fa-circle text-theme-41"
                      style="font-size: 0.4rem"
                    />
                    <span class="form-label mb-0">TIPO CLIENTE:</span>
                    <span class="form-label mb-0">{{
                      clientInvoice.type == 'PA'
                        ? 'Pubblica Amministrazione'
                        : clientInvoice.type
                    }}</span>
                  </div>
                  <div
                    v-if="clientInvoice.ragione_sociale"
                    class="flex items-center gap-1"
                  >
                    <i
                      class="fas fa-circle text-theme-41"
                      style="font-size: 0.4rem"
                    />
                    <span class="form-label mb-0">RAGIONE SOCIALE:</span>
                    <span class="form-label mb-0">{{
                      clientInvoice.ragione_sociale
                    }}</span>
                  </div>
                  <div v-else class="flex items-center gap-1">
                    <i
                      class="fas fa-circle text-theme-41"
                      style="font-size: 0.4rem"
                    />
                    <span class="form-label mb-0">NOME:</span>
                    <span class="form-label mb-0"
                      >{{ clientInvoice.first_name }}
                      {{ clientInvoice.last_name }}</span
                    >
                  </div>
                  <div class="flex items-center gap-1">
                    <i
                      class="fas fa-circle text-theme-41"
                      style="font-size: 0.4rem"
                    />
                    <span class="form-label mb-0">INDIRIZZO:</span>
                    <span class="form-label mb-0">
                      {{ clientInvoice?.address?.address }},
                      {{ clientInvoice?.address?.zip_code }}
                      {{ clientInvoice?.address?.city?.nome }}
                    </span>
                  </div>
                  <div class="flex items-center gap-1">
                    <i
                      class="fas fa-circle text-theme-41"
                      style="font-size: 0.4rem"
                    />
                    <span class="form-label mb-0">COD. FISCALE:</span>
                    <span class="form-label mb-0">{{
                      clientInvoice.fiscal_code
                    }}</span>
                  </div>
                  <div
                    v-if="clientInvoice.vat_number"
                    class="flex items-center gap-1"
                  >
                    <i
                      class="fas fa-circle text-theme-41"
                      style="font-size: 0.4rem"
                    />
                    <span class="form-label mb-0">P. IVA:</span>
                    <span class="form-label mb-0">{{
                      clientInvoice.vat_number?.vat_number
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: DATI CLIENTE -->
            <!-- END: PRIMA PARTE FORM -->
            <!-- BEGIN: SECONDA PARTE FORM -->
            <!-- BEGIN: PRODOTTI E SERVIZI -->
            <div class="col-span-2 p-2">
              <h3 class="stats-subs text-base">PRODOTTI E SERVIZI</h3>

              <!-- BEGIN: Aggiungi tariffario -->
              <div
                v-if="!readOnly"
                class="flex items-center justify-end gap-1 py-1"
              >
                <button
                  class="orange-btn"
                  type="button"
                  @click="showModalTariff = true"
                >
                  Tariffario
                </button>
                <button
                  v-if="
                    permissions &&
                    permissions?.fatturazione?.create_empty_row_billing
                      ?.enabled === true
                  "
                  class="orange-btn"
                  type="button"
                  @click="addNewLine"
                >
                  Nuovo
                </button>
              </div>
              <TariffarioModal
                v-if="showModalTariff"
                @close="showModalTariff = false"
                @addRow="addTariff"
              />
              <!-- END: Aggiungi tariffario -->
              <div v-if="!readOnly" class="flex flex-col gap-2 py-2 px-3">
                <div
                  v-for="(n, index) in serviceCount"
                  :key="index"
                  draggable="true"
                  class="grid grid-cols-12 gap-2 rounded-md p-1 px-3 shadow-sm border border-theme-44 border-opacity-30 md:text-center items-start"
                  :class="{ 'bg-theme-2': index % 2 !== 0 }"
                  @dragstart="startDrag(index)"
                  @drop="onDrop(serviceRows, index)"
                  @dragover.prevent
                  @dragenter.prevent
                  @click="isSearchingTariff[index] = false"
                >
                  <div
                    class="col-span-12 flex gap-1 justify-between items-start"
                  >
                    <div class="flex-1 grid grid-cols-12 gap-1">
                      <div
                        class="col-span-12 md:col-span-6 lg:col-span-3 justify-end h-full py-1"
                      >
                        <div class="flex flex-col gap-1 justify-end h-full">
                          <label class="form-label mb-0"> Descrizione</label>
                          <input
                            v-model="serviceRows[index].desc"
                            class="custom-input"
                            type="text"
                            placeholder="Descrizione"
                            :readonly="
                              serviceRows[index].cod_tariffa_alias ===
                                'T4U_A0001' ||
                              serviceRows[index].cod_tariffa_alias ===
                                'T4U_A0002' ||
                              onlyAcconto ||
                              serviceRows[index].tariffario_alias_id ||
                              (permissions &&
                                permissions?.fatturazione
                                  ?.create_empty_row_billing?.enabled === false)
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="col-span-12 sm:col-span-4 md:col-span-2 lg:col-span-1 justify-end h-full py-1"
                      >
                        <div class="flex flex-col gap-1 justify-end h-full">
                          <label class="form-label mb-0">Cod. </label>
                          <input
                            :value="
                              serviceRows[index].code_id
                                ? serviceRows[index].code_id
                                : ''
                            "
                            class="custom-input"
                            type="text"
                            placeholder="Codice"
                            :readonly="
                              serviceRows[index].cod_tariffa_alias ===
                                'T4U_A0001' ||
                              serviceRows[index].cod_tariffa_alias ===
                                'T4U_A0002' ||
                              onlyAcconto ||
                              serviceRows[index].tariffario_alias_id ||
                              (permissions &&
                                permissions?.fatturazione
                                  ?.create_empty_row_billing?.enabled === false)
                            "
                          />
                        </div>
                      </div>
                      <div
                        v-if="
                          vets &&
                          /*documento !== 'preventivo' &&*/
                          datiDocumento?.tipoDocumento?.code !== 'TD05' &&
                          datiDocumento?.tipoDocumento?.code !== 'TDP' &&
                          datiDocumento?.tipoDocumento?.code !== 'TD04'
                        "
                        class="col-span-12 md:col-span-6 lg:col-span-2 justify-end h-full py-1"
                      >
                        <div class="flex flex-col gap-1 justify-end h-full">
                          <label class="form-label mb-0"
                            >Prestazione effettuata da :
                          </label>
                          <v-select
                            v-model="serviceRows[index].performed_by"
                            :options="vets"
                            :reduce="
                              (value) => ({
                                id: value.id,
                                full_name: value.full_name
                              })
                            "
                            multiple
                            label="last_name"
                            placeholder="Seleziona veterinario"
                            class="w-full uppercase text-xs"
                          >
                            <template #selected-option="option" class="z-30">
                              {{ option.full_name }}
                            </template>
                            <template #option="option" class="z-40">
                              {{ option.full_name }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                      <div
                        class="col-span-12 sm:col-span-4 md:col-span-1 lg:col-span-1 justify-end h-full py-1"
                      >
                        <div
                          class="flex flex-col gap-1 content-between justify-end h-full"
                        >
                          <label class="form-label mb-0">Quantità</label>

                          <input
                            v-model="serviceRows[index].qty"
                            class="custom-input"
                            type="number"
                            placeholder="Quantità"
                            min="1"
                            :readonly="
                              serviceRows[index].code_id === 'T4U_A0001' ||
                              serviceRows[index].code_id === 'T4U_A0002' ||
                              onlyAcconto
                            "
                            @change="calculate(index, true)"
                          />
                        </div>
                      </div>

                      <div
                        :class="
                          documento === 'preventivo' ||
                          datiDocumento?.tipoDocumento?.id === 19
                            ? 'justify-end py-1 md:col-span-4 lg:col-span-2 h-full'
                            : 'justify-end py-1 col-span-4 md:col-span-2 lg:col-span-1 h-full'
                        "
                      >
                        <div class="flex flex-col gap-1 justify-end h-full">
                          <label class="form-label mb-0">Prezzo</label>
                          <div
                            v-if="
                              documento === 'preventivo' ||
                              datiDocumento?.tipoDocumento?.id === 19
                            "
                            class="grid grid-cols-2 items-baseline relative w-full"
                          >
                            <span class="form-label col-span-1"> Netto</span>
                            <span class="form-label col-span-1"> Ivato</span>
                          </div>
                          <div
                            class="flex items-baseline relative w-full gap-3"
                          >
                            <span class="form-label absolute top-1.5 left-1">
                              €</span
                            >
                            <div
                              v-if="
                                (documento === 'preventivo' ||
                                  datiDocumento?.tipoDocumento?.id === 19) &&
                                iva
                              "
                              class="appearance-none border border-gray-300 rounded w-full py-1.5 p-3 text-theme-48 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                            >
                              <span>
                                {{
                                  Math.round(
                                    (serviceRows[index].price +
                                      Number.EPSILON) *
                                      100
                                  ) / 100
                                }}
                              </span>
                            </div>
                            <input
                              v-else-if="iva"
                              v-model="serviceRows[index].priceIva"
                              class="custom-input pl-5"
                              type="number"
                              placeholder="Prezzo"
                              min="0.00"
                              step="0.01"
                              :readonly="
                                readOnly === true ||
                                serviceRows[index].code_id === 'T4U_A0001' ||
                                serviceRows[index].code_id === 'T4U_A0002' ||
                                !serviceRows[index].newLine ||
                                (permissions &&
                                  permissions?.fatturazione
                                    ?.create_empty_row_billing?.enabled ===
                                    false)
                              "
                              @change="
                                calculate(
                                  index,
                                  permissions &&
                                    permissions?.fatturazione
                                      ?.create_empty_row_billing?.enabled ===
                                      true
                                )
                              "
                            />
                            <input
                              v-else
                              v-model="serviceRows[index].price"
                              class="custom-input pl-5"
                              type="number"
                              placeholder="Prezzo"
                              min="0.00"
                              step="0.01"
                              :readonly="
                                readOnly === true ||
                                serviceRows[index].code_id === 'T4U_A0001' ||
                                serviceRows[index].code_id === 'T4U_A0002' ||
                                !serviceRows[index].newLine ||
                                (permissions &&
                                  permissions?.fatturazione
                                    ?.create_empty_row_billing?.enabled ===
                                    false)
                              "
                              @change="
                                calculate(
                                  index,
                                  permissions &&
                                    permissions?.fatturazione
                                      ?.create_empty_row_billing?.enabled ===
                                      true
                                )
                              "
                            />
                            <span
                              v-if="
                                documento === 'preventivo' ||
                                datiDocumento?.tipoDocumento?.id === 19
                              "
                              class="form-label absolute top-1.5 left-1"
                            >
                              €</span
                            >

                            <div
                              v-if="
                                (documento === 'preventivo' ||
                                  datiDocumento?.tipoDocumento?.id === 19) &&
                                !iva
                              "
                              class="appearance-none border border-gray-300 rounded w-full py-1.5 p-3 text-theme-48 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                            >
                              <span>
                                {{
                                  Math.round(
                                    (serviceRows[index].priceIva +
                                      Number.EPSILON) *
                                      100
                                  ) / 100
                                }}
                              </span>
                            </div>
                            <input
                              v-else-if="
                                documento === 'preventivo' ||
                                datiDocumento?.tipoDocumento?.id === 19
                              "
                              v-model="serviceRows[index].priceIva"
                              class="custom-input pl-5"
                              type="number"
                              placeholder="Prezzo"
                              min="0.00"
                              step="0.01"
                              :disabled="!serviceRows[index].newLine"
                              @change="
                                calculate(
                                  index,
                                  permissions &&
                                    permissions?.fatturazione
                                      ?.create_empty_row_billing?.enabled ===
                                      true
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          (documento === 'preventivo' ||
                            datiDocumento?.tipoDocumento?.id === 19) &&
                          !scontoAbilitato
                        "
                        class="col-span-12 md:col-span-4 lg:col-span-2 justify-end h-full flex flex-row gap-3 lg:gap-0 lg:flex-col items-baseline lg:items-center py-1"
                      >
                        <label class="form-label w-28 md:w-36"
                          >Prezzo max.</label
                        >
                        <div
                          class="grid grid-cols-2 items-baseline relative w-full"
                        >
                          <span class="form-label col-span-1"> Netto</span>
                          <span class="form-label col-span-1"> Ivato</span>
                        </div>

                        <div class="flex items-baseline relative w-full gap-3">
                          <span
                            v-if="
                              (documento !== 'preventivo' &&
                                datiDocumento?.tipoDocumento?.id !== 19) ||
                              ((documento === 'preventivo' ||
                                datiDocumento?.tipoDocumento?.id === 19) &&
                                !iva)
                            "
                            class="form-label absolute top-1.5 left-1"
                          >
                            €</span
                          >
                          <div
                            v-if="
                              (documento === 'preventivo' ||
                                datiDocumento?.tipoDocumento?.id === 19) &&
                              iva
                            "
                            class="appearance-none border border-gray-300 rounded w-full py-1.5 p-3 text-theme-48 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                          >
                            <span>
                              {{
                                Math.round(
                                  (serviceRows[index].price_max +
                                    Number.EPSILON) *
                                    100
                                ) / 100
                              }}
                            </span>
                          </div>
                          <input
                            v-else
                            v-model="serviceRows[index].price_max"
                            class="custom-input pl-5"
                            type="number"
                            placeholder="Prezzo"
                            :min="serviceRows[index].taxable"
                            step="0.01"
                            :readonly="
                              serviceRows[index].qty !== 1 &&
                              permissions &&
                              permissions?.fatturazione
                                ?.create_empty_row_billing?.enabled === false
                            "
                            @change="
                              calculate(
                                index,
                                permissions &&
                                  permissions?.fatturazione
                                    ?.create_empty_row_billing?.enabled === true
                              )
                            "
                          />
                          <span
                            v-if="
                              (documento === 'preventivo' ||
                                datiDocumento?.tipoDocumento?.id === 19) &&
                              iva
                            "
                            class="form-label absolute top-1.5 left-1"
                          >
                            €</span
                          >
                          <div
                            v-if="
                              (documento === 'preventivo' ||
                                datiDocumento?.tipoDocumento?.id === 19) &&
                              !iva
                            "
                            class="appearance-none border border-gray-300 rounded w-full py-1.5 p-3 text-theme-48 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                          >
                            <span>
                              {{
                                Math.round(
                                  (serviceRows[index].price_max_iva +
                                    Number.EPSILON) *
                                    100
                                ) / 100
                              }}
                            </span>
                          </div>
                          <input
                            v-else-if="
                              documento === 'preventivo' ||
                              datiDocumento?.tipoDocumento?.id === 19
                            "
                            v-model="serviceRows[index].price_max_iva"
                            class="custom-input pl-5"
                            type="number"
                            placeholder="Prezzo"
                            :min="serviceRows[index].taxable"
                            step="0.01"
                            @change="calculate(index, false)"
                          />
                        </div>
                      </div>
                      <div
                        v-if="scontoAbilitato && !scontoPercentuale"
                        class="col-span-1 md:col-span-1 lg:col-span-1 justify-end h-full py-1 mx-1"
                      >
                        <div class="flex flex-col gap-1 justify-end h-full">
                          <div class="tooltiptop inline-flex justify-center">
                            <label class="form-label mb-0 mr-1 pt-1"
                              >Prezzo scontato</label
                            >
                            <button type="button ml-2">
                              <InfoIcon class="w-4 pt-0.5" />
                            </button>
                            <span class="tooltiptext"
                              >Si intende il prezzo finale della singola
                              prestazione scontato/maggiorato</span
                            >
                          </div>
                          <div class="flex items-baseline relative w-full">
                            <input
                              v-model="serviceRows[index].disc_price"
                              class="custom-input text-center"
                              type="number"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              step="0.0001"
                              maxlength="8"
                              :readonly="
                                serviceRows[index].cod_tariffa_alias ===
                                  'T4U_A0001' ||
                                serviceRows[index].cod_tariffa_alias ===
                                  'T4U_A0002' ||
                                onlyAcconto
                              "
                              @change="calculate(index, true)"
                              @keydown.enter.prevent=""
                            />
                            <span class="form-label absolute top-1.5 right-2">
                              €</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="scontoAbilitato && scontoPercentuale === true"
                        class="col-span-3 md:col-span-1 lg:col-span-1 justify-end h-full py-1 mx-1"
                      >
                        <div class="flex flex-col gap-1 justify-end h-full">
                          <div class="tooltiptop">
                            <label class="form-label mb-0 mr-1"
                              >Sconto percentuale</label
                            >
                            <button type="button ml-2">
                              <InfoIcon class="w-4" />
                            </button>
                            <span class="tooltiptext">Percentuale</span>
                          </div>
                          <div class="flex items-baseline relative w-full">
                            <input
                              v-model="serviceRows[index].disc"
                              class="custom-input text-center"
                              type="number"
                              step="0.0001"
                              maxlength="5"
                              :readonly="
                                serviceRows[index].cod_tariffa_alias ===
                                  'T4U_A0001' ||
                                serviceRows[index].cod_tariffa_alias ===
                                  'T4U_A0002' ||
                                onlyAcconto
                              "
                              @change="calculate(index, true)"
                              @keydown.enter.prevent=""
                            />
                            <span class="form-label absolute top-1.5 right-2">
                              %</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-span-3 md:col-span-2 lg:col-span-1 justify-end h-full py-1"
                      >
                        <div
                          v-if="
                            serviceRows[index].code_id !== 'T4U_A0001' &&
                            serviceRows[index].code_id !== 'T4U_A0002'
                          "
                          class="flex flex-col gap-1 justify-end h-full"
                        >
                          <label class="form-label mb-0">IVA</label>

                          <select
                            v-model="serviceRows[index].VAT"
                            class="custom-input"
                            @change="calculate(index)"
                          >
                            <option
                              v-for="nn in listVAT"
                              :key="nn.id"
                              :value="nn"
                            >
                              {{ nn.vat_percentage + ' %' }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-span-6 md:col-span-4 lg:col-span-2 justify-end h-full py-1 grid grid-cols-2 gap-2"
                      >
                        <div class="flex flex-col gap-2 justify-end h-full">
                          <span class="form-label mb-0 col-span-1">
                            Imp. Netto</span
                          >
                          <div class="custom-input -mt-1">
                            €
                            <span>
                              {{
                                serviceRows[index].taxable_max
                                  ? Math.round(
                                      (serviceRows[index].taxable_max * 1 +
                                        // (cassa
                                        // ? cassa.vat
                                        // ? (cassa.vat + 100) / 100
                                        // : 1.02
                                        // : 1)
                                        Number.EPSILON) *
                                        100
                                    ) / 100
                                  : Math.round(
                                      (serviceRows[index].taxable * 1 +
                                        // (cassa
                                        // ? cassa.vat
                                        // ? (cassa.vat + 100) / 100
                                        // : 1.02
                                        // : 1)
                                        Number.EPSILON) *
                                        100
                                    ) / 100
                              }}
                            </span>
                          </div>
                        </div>

                        <div class="flex flex-col gap-2 justify-end h-full">
                          <span class="form-label mb-0 col-span-1">
                            Imp. Ivato</span
                          >
                          <div class="custom-input -mt-1">
                            <span>€</span
                            ><span>
                              {{
                                serviceRows[index].taxable_max
                                  ? Math.round(
                                      serviceRows[index].taxable_max *
                                        // (cassa
                                        // ? cassa.vat
                                        // ? (cassa.vat + 100) / 100
                                        // : 1.02
                                        // : 1) *
                                        1 *
                                        ((serviceRows[index].VAT
                                          .vat_percentage +
                                          100) /
                                          100) *
                                        100
                                    ) / 100
                                  : Math.round(
                                      serviceRows[index].taxable *
                                        // (cassa
                                        // ? cassa.vat
                                        // ? (cassa.vat + 100) / 100
                                        // : 1.02
                                        // : 1) *
                                        1 *
                                        ((serviceRows[index].VAT
                                          .vat_percentage +
                                          100) /
                                          100) *
                                        100
                                    ) / 100
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                    v-if="false"
                    class="col-span-12 md:col-span-4 lg:col-span-1 justify-end flex flex-row gap-3 lg:gap-0 lg:flex-col items-baseline lg:items-center py-1"
                  >
                    <label class="form-label w-28 md:w-48">
                      Importo Ivato</label
                    >

                    <div
                      class="appearance-none border border-gray-300 rounded w-full py-1.5 p-3 text-theme-48 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                    >
                      €
                      {{
                        serviceRows[index].taxable_max &&
                        documento === 'preventivo'
                          ? Math.round(
                              serviceRows[index].taxable_max *
                                (1 +
                                  serviceRows[index].VAT.vat_percentage / 100) *
                                100
                            ) / 100
                          : Math.round(
                              serviceRows[index].taxable *
                                (1 +
                                  serviceRows[index].VAT.vat_percentage / 100) *
                                100
                            ) / 100
                      }}
                    </div>
                  </div> -->

                    <div
                      v-if="showRowSettings[index] === false"
                      class="flex-0 mt-8 self-start"
                    >
                      <button
                        class="text-theme-41 mt-6 bg-transparent hover:bg-theme-41 hover:text-white rounded-lg px-2 py-1"
                        @click.prevent="deleteService(n)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="serviceRows[index].taglia_label"
                    class="col-span-12 flex items-center gap-2 justify-start relative w-full"
                  >
                    <span class="form-label mb-0 uppercase"
                      >Taglia selezionata:</span
                    >

                    <span class="form-label mb-0 col-span-1 text-xs">
                      {{ serviceRows[index].taglia_label }}
                    </span>
                  </div>
                  <div
                    v-if="
                      serviceRows[index].pkg_info &&
                      serviceRows[index].pkg_info.sub_packages
                    "
                    class="col-span-12 flex flex-col gap-1 relative"
                  >
                    <span
                      class="whitespace-nowrap form-label mb-0 flex justify-start uppercase"
                      >Dettaglio prestazioni pacchetto:</span
                    >
                    <div class="flex gap-2 items-center flex-wrap">
                      <div
                        v-for="el in serviceRows[index].pkg_info.sub_packages
                          .tariffario_ids.tariffario_price"
                        :key="el"
                        class="flex items-center gap-1"
                      >
                        <div class="bg-theme-41 w-1.5 h-1.5 rounded-full"></div>
                        <span class="text-xs">
                          {{
                            el.alias && el.alias !== ''
                              ? el.alias
                              : el.description
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="serviceRows[index].details_prestazioni"
                    class="col-span-12 flex justify-start relative"
                  >
                    <span class="whitespace-nowrap mr-3"
                      >Dettaglio prestazioni pacchetto:</span
                    >
                    <div class="flex gap-4 items-center flex-wrap">
                      <div
                        v-for="el in serviceRows[index].details_prestazioni"
                        :key="el"
                        class="flex items-center gap-1"
                      >
                        <div class="bg-theme-41 w-1.5 h-1.5 rounded-full"></div>
                        {{
                          el.alias && el.alias !== ''
                            ? el.alias
                            : el.description
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      serviceRows[index].pkg_info &&
                      serviceRows[index].pkg_info.discount &&
                      serviceRows[index].pkg_info.discount !== 0 &&
                      serviceRows[index].pkg_info.discount !== '0'
                    "
                    class="col-span-12 flex justify-start relative w-full"
                  >
                    <span
                      >Sconto applicato:
                      {{ serviceRows[index].pkg_info.discount }} %</span
                    >
                  </div>
                  <div
                    v-if="
                      serviceRows[index].pkg_info &&
                      serviceRows[index].pkg_info.greatest &&
                      serviceRows[index].pkg_info.greatest !== 0 &&
                      serviceRows[index].pkg_info.greatest !== '0'
                    "
                    class="col-span-12 flex justify-start relative w-full"
                  >
                    <span
                      >Maggiorazione applicata:
                      {{ serviceRows[index].pkg_info.greatest }} %</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- END: PRODOTTI E SERVIZI -->
            <!-- START: MOSTRA PRODOTTI E SERVIZI -->

            <div v-if="readOnly" class="col-span-2">
              <div
                v-for="(n, index) in serviceRows"
                :key="index"
                class="grid grid-cols-12 rounded-md py-1 px-3 bg-white shadow-sm border border-theme-44 border-opacity-30"
                :class="{ 'bg-theme-2': index % 2 === 0 }"
              >
                <div
                  class="col-span-12 border-r border-l border-t md:col-span-6 lg:col-span-2 flex items-center gap-1 justify-center lg:flex-col lg:justify-end lg:gap-0 border-b border-theme-44"
                >
                  <label class="form-label mb-0.5">Cod.</label>
                  <span class="">
                    {{ n.code_id ? n.code_id : n.cod_tariffa_alias }}</span
                  >
                </div>
                <div
                  class="col-span-12 border-r border-l md:col-span-6 flex items-center gap-3 justify-center lg:flex-col lg:justify-end lg:gap-0 border-b md:border-l-0 md:border-t border-theme-44"
                  :class="n.performed_by ? 'lg:col-span-2' : 'lg:col-span-3'"
                >
                  <label class="form-label mb-0.5">Descrizione</label>
                  <span class="text-center"> {{ n.desc }}</span>
                </div>
                <div
                  v-if="
                    n.performed_by &&
                    datiDocumento?.tipoDocumento?.code !== 'TD05' &&
                    datiDocumento?.tipoDocumento?.code !== 'TD04'
                  "
                  class="col-span-5 sm:col-span-8 md:col-span-3 lg:col-span-1 flex flex-col items-center justify-end py-1 border border-t-0 border-r-0 lg:border-t lg:border-l-0 border-theme-44"
                >
                  <label class="form-label mb-0.5"
                    >Prestazione effettuata da:</label
                  >

                  <span v-for="m in n.performed_by" :key="m.id" class="">
                    {{ m.full_name }}</span
                  >
                </div>
                <div
                  v-else
                  class="hidden lg:hidden border border-r-0 border-t-0 border-theme-44"
                  :class="
                    datiDocumento.tipoDocumento.description === 'preventivo' &&
                    n.taxable_max
                      ? 'md:hidden'
                      : 'md:block md:col-span-3'
                  "
                ></div>
                <div
                  class="flex flex-col col-span-4 md:col-span-1 lg:col-span-1 items-center justify-end py-1 border border-t-0 lg:border-t lg:border-l-0 border-theme-44"
                >
                  <label class="form-label mb-0.5">Quantità</label>
                  <span class="text-center"> {{ n.qty }}</span>
                </div>

                <div
                  class="col-span-8 md:col-span-3 lg:col-span-2 py-1 border border-t-0 lg:border-l-0 lg:border-t border-theme-44"
                  :class="
                    n.performed_by
                      ? 'border-l border-r-0 md:border-r'
                      : 'border-l-0'
                  "
                >
                  <label
                    class="form-label mb-1 flex justify-center border-b border-theme-44"
                    >Prezzo</label
                  >
                  <div
                    class="grid grid-cols-2 items-baseline relative w-full justify-items-center"
                  >
                    <span class="form-label mb-0.5 col-span-1"> Netto</span>
                    <span class="form-label mb-0.5 col-span-1"> Ivato</span>
                  </div>
                  <div
                    class="grid grid-cols-2 items-baseline relative w-full justify-items-center"
                  >
                    <span class="">
                      €
                      {{
                        Math.round((n.price * 1 + Number.EPSILON) * 100) / 100
                      }}</span
                    >
                    <span class="">
                      €
                      {{
                        Math.round(
                          (n.price *
                            (iva && n.VAT && n.VAT.vat_percentage !== null
                              ? (n.VAT.vat_percentage + 100) / 100
                              : ivaPerc) +
                            Number.EPSILON) *
                            100
                        ) / 100
                      }}</span
                    >
                  </div>
                </div>

                <div
                  v-if="
                    datiDocumento.tipoDocumento.description === 'preventivo' &&
                    n.taxable_max
                  "
                  class="col-span-8 md:col-span-3 lg:col-span-2 py-1 border border-theme-44 lg:border-l-0"
                >
                  <label
                    class="form-label mb-1 flex justify-center border-b w-full border-theme-44"
                    >Prezzo Massimo</label
                  >
                  <div
                    class="grid grid-cols-2 items-baseline relative w-full justify-items-center"
                  >
                    <span class="form-label mb-0.5 col-span-1"> Netto</span>
                    <span class="form-label mb-0.5 col-span-1"> Ivato</span>
                  </div>
                  <div
                    class="grid grid-cols-2 items-baseline relative w-full justify-items-center"
                  >
                    <span class="">
                      €
                      {{
                        Math.round((n.price_max + Number.EPSILON) * 100) / 100
                      }}</span
                    >
                    <span class="">
                      €
                      {{
                        showCassaComputed
                          ? Math.round(
                              (n.price_max *
                                (n.VAT && n.VAT.vat_percentage !== null
                                  ? (n.VAT.vat_percentage + 100) / 100
                                  : ivaPerc) +
                                // (cassa.vat ? (cassa.vat + 100) / 100 : 1.02)
                                Number.EPSILON) *
                                100
                            ) / 100
                          : Math.round(
                              (n.price_max *
                                (n.VAT && n.VAT.vat_percentage !== null
                                  ? (n.VAT.vat_percentage + 100) / 100
                                  : ivaPerc) +
                                Number.EPSILON) *
                                100
                            ) / 100
                      }}</span
                    >
                  </div>
                </div>
                <div
                  v-if="
                    (datiDocumento.tipoDocumento.description !== 'preventivo' &&
                      n.disc_price !== null) ||
                    ((documento === 'preventivo' ||
                      datiDocumento?.tipoDocumento?.id === 19) &&
                      scontoAbilitato)
                  "
                  class="md:col-span-1 flex flex-col py-1 items-center justify-end border border-t-0 md:border-l-0 lg:border-t border-theme-44"
                  :class="n.performed_by ? 'col-span-4' : 'col-span-2'"
                >
                  <div class="tooltiptop">
                    <label class="form-label mb-0 mr-1">Prezzo scontato</label>
                    <button type="button ml-2">
                      <InfoIcon class="w-4" />
                    </button>
                    <span class="tooltiptext"
                      >Si intende il prezzo finale della singola prestazione
                      scontato/maggiorato</span
                    >
                  </div>
                  <span class="">
                    €
                    {{
                      Math.round((n.disc_price + Number.EPSILON) * 100) / 100
                    }}</span
                  >
                </div>
                <div
                  class="col-span-2 md:col-span-1 flex flex-col py-1 items-center justify-end border border-t-0 lg:border-t border-theme-44"
                  :class="
                    n.performed_by
                      ? 'col-span-4 border-l'
                      : 'col-span-2 border-l-0'
                  "
                >
                  <label class="form-label mb-0.5">IVA</label>
                  <span class=""> {{ n.VAT.vat_percentage }}</span>
                </div>
                <div
                  class="col-span-8 md:col-span-3 lg:col-span-2 py-1 border border-t-0 mr-0 lg:border-t border-theme-44"
                  :class="
                    datiDocumento?.tipoDocumento?.description ===
                      'preventivo' && n.taxable_max
                      ? 'border-l'
                      : 'border-l-0'
                  "
                >
                  <label
                    class="form-label mb-0.5 flex justify-center border-b border-theme-44"
                    >Importo
                  </label>
                  <div
                    class="grid grid-cols-2 items-baseline relative w-full justify-items-center"
                  >
                    <span class="form-label mb-0.5 col-span-1"> Netto</span>
                    <span class="form-label mb-0.5 col-span-1"> Ivato</span>
                  </div>
                  <div
                    class="grid grid-cols-2 items-baseline relative w-full justify-items-center"
                  >
                    <span class="">
                      €
                      {{
                        n.taxable_max
                          ? Math.round(
                              (n.taxable_max * 1 +
                                // (cassa
                                // ? cassa.vat
                                // ? (cassa.vat + 100) / 100
                                // : 1.02
                                // : 1)
                                Number.EPSILON) *
                                100
                            ) / 100
                          : Math.round(
                              (n.taxable +
                                // (cassa
                                // ? cassa.vat
                                // ? (cassa.vat + 100) / 100
                                // : 1.02
                                // : 1)
                                Number.EPSILON) *
                                100
                            ) / 100
                      }}</span
                    >
                    <span class="">
                      €
                      {{
                        n.taxable_max
                          ? Math.round(
                              n.taxable_max *
                                1 *
                                ((n.VAT.vat_percentage + 100) / 100) *
                                // (cassa
                                // ? cassa.vat
                                // ? (cassa.vat + 100) / 100
                                // : 1.02
                                // : 1) *
                                100
                            ) / 100
                          : Math.round(
                              n.taxable *
                                1 *
                                ((n.VAT.vat_percentage + 100) / 100) *
                                // (cassa
                                // ? cassa.vat
                                // ? (cassa.vat + 100) / 100
                                // : 1.02
                                // : 1) *
                                100
                            ) / 100
                      }}</span
                    >
                  </div>
                </div>
                <div
                  v-if="n.taglia_label"
                  class="col-span-12 flex justify-start relative w-full"
                >
                  <span>Taglia selezionata:</span>

                  <span class="form-label col-span-1">
                    {{ n.taglia_label }}
                  </span>
                </div>

                <div
                  v-if="serviceRows[index].details_prestazioni"
                  class="col-span-12 flex justify-start relative w-full mt-3"
                >
                  <span class="whitespace-nowrap mr-3"
                    >Dettaglio prestazioni pacchetto:</span
                  >
                  <div class="flex gap-4 items-center flex-wrap">
                    <div
                      v-for="el in serviceRows[index].details_prestazioni"
                      :key="el.alias"
                      class="flex items-center gap-1"
                    >
                      <div class="bg-theme-41 w-1.5 h-1.5 rounded-full"></div>
                      {{ el.alias && el.alias !== '' ? el.alias : '' }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="n.pkg_info && n.pkg_info.sub_packages"
                  class="col-span-12 flex justify-start relative w-full"
                >
                  <span class="whitespace-nowrap mr-3"
                    >Dettaglio prestazioni pacchetto:</span
                  >

                  <span
                    v-for="el in n.pkg_info.sub_packages.tariffario_ids
                      .tariffario_price"
                    :key="el"
                    class="form-label col-span-1"
                  >
                    {{ el.alias }}
                  </span>
                  <div
                    v-if="
                      n.pkg_info &&
                      n.pkg_info.discount &&
                      n.pkg_info.discount !== 0 &&
                      n.pkg_info.discount !== '0'
                    "
                    class="col-span-12 flex justify-start relative w-full"
                  >
                    <span>Sconto applicato: {{ n.pkg_info.discount }} %</span>
                  </div>
                  <div
                    v-if="
                      n.pkg_info &&
                      n.pkg_info.greatest &&
                      n.pkg_info.greatest !== 0 &&
                      n.pkg_info.greatest !== '0'
                    "
                    class="col-span-12 flex justify-start relative w-full"
                  >
                    <span
                      >Maggiorazione applicata:
                      {{ n.pkg_info.greatest }} %</span
                    >
                  </div>
                </div>

                <!-- <div
                  class="col-span-4 lg:col-span-1 flex flex-row gap-3 lg:gap-0 lg:flex-col items-baseline lg:items-center py-1"
                >
                  <label class="form-label">Importo Ivato</label>
                  <span class="">
                    {{
                      n.taxable_max
                        ? Math.round(
                            n.taxable_max *
                              (1 + n.VAT.vat_percentage / 100) *
                              100
                          ) / 100
                        : Math.round(
                            n.taxable * (1 + n.VAT.vat_percentage / 100) * 100
                          ) / 100
                    }}</span
                  >
                </div> -->
              </div>
            </div>
            <div
              v-if="isPreventivo() && !readOnly"
              class="col-span-2 flex justify-end px-6"
            >
              <span class="mx-2">{{
                scontoAbilitato ? 'Sconto abilitato' : 'Sconto disabilitato'
              }}</span>
              <input
                v-model="scontoAbilitato"
                type="checkbox"
                class="form-check-switch"
              />
            </div>
            <div v-if="!readOnly" class="col-span-2 flex justify-end px-6">
              <span class="mx-2">{{
                scontoPercentuale
                  ? 'Sconto in percentuale'
                  : 'Sconto sul prezzo'
              }}</span>
              <input
                v-model="scontoPercentuale"
                type="checkbox"
                class="form-check-switch"
              />
            </div>

            <!-- END: MOSTRA PRODOTTI E SERVIZI -->

            <!-- BEGIN: DATI IVA, CASSA, RITENUTE -->
            <div
              v-if="
                showFooter &&
                documento !== 'preventivo' &&
                datiDocumento?.tipoDocumento?.id !== 19
              "
              class="col-span-2 px-3"
            >
              <div
                v-if="!readOnly"
                class="flex flex-col lg:flex-row text-center justify-end"
              >
                <div class="flex justify-end">
                  <button
                    type="button"
                    class="orange-btn-inverted whitespace-nowrap"
                    @click="cassaEdit"
                  >
                    Cassa previdenziale
                  </button>

                  <div v-if="cassaImporto > 0">Attivo</div>
                  <CassaModal
                    v-if="showModalCassa"
                    :services="serviceRows"
                    :cassa-vecchia="null"
                    :old-checks="checksCassa"
                    :cassa-fattura="showCassaComputed"
                    :is-coming-from-setup="null"
                    @changeCassaFattura="handleCassaFatturaSave"
                    @close="showModalCassa = false"
                    @addCassa="addCassaFromModal"
                  />
                </div>

                <div class="flex justify-end">
                  <div
                    v-if="
                      client.type === 'Persona giuridica' ||
                      client.type === 'PA'
                    "
                  >
                    <button
                      type="button"
                      class="orange-btn-inverted whitespace-nowrap"
                      @click="ritenutaEdit"
                    >
                      Ritenute
                    </button>
                  </div>
                  <!-- <div v-if="ritenutaComputed !== null">Attivo</div> -->
                  <RitenutaModal
                    v-if="showModalRitenuta"
                    :services="serviceRows"
                    :ritenuta-vecchia="invoiceTot.ritenuta"
                    :current-ritenuta="
                      ritenutaComputed ?? ritenutaGlobalDefault
                    "
                    :active="ritenutaComputed ? true : false"
                    :old-checks="checksRitenuta"
                    @close="showModalRitenuta = false"
                    @addRitenuta="addRitenutaFromModal"
                  />
                </div>

                <div class="flex justify-end ml-1">
                  <button
                    type="button"
                    class="orange-btn-inverted whitespace-nowrap"
                    @click="showModalIva = true"
                  >
                    IVA
                  </button>

                  <IvaModal
                    v-if="showModalIva"
                    v-model="ivaSelectedModal"
                    :list-iva="listVAT"
                    :iva-selected="ivaSelectedModal"
                    @close="showModalIva = false"
                    @changeIva="changeIva"
                  />
                </div>

                <div class="">
                  <!-- <div>
                    <button
                      type="button"
                      class="w-full px-5 py-1.5 ml-1 orange-btn-inverted whitespace-nowrap"
                      @click="bolloEdit"
                    >
                      Dati bollo
                    </button>
                  </div> -->
                  <div v-if="bolloImporto > 0">Attivo</div>
                  <BolloModal
                    v-if="showModalBollo"
                    :bollo-vecchio="invoiceTot.bollo"
                    @close="showModalBollo = false"
                    @addBollo="addBolloFromModal"
                  />
                </div>
                <div class="">
                  <!-- <div>
                    <button
                      type="button"
                      class="w-full px-5 py-1.5 ml-1 orange-btn-inverted whitespace-nowrap"
                      @click="showScontoMaggiorazione = true"
                    >
                      Sconto / Magg.
                    </button>
                  </div> -->
                  <div v-if="activeScMag">Attivo</div>
                  <ScontoMaggiorazioneModal
                    v-if="showScontoMaggiorazione"
                    :old-active="activeScMag"
                    @activeScMag="changeActiveScMagFromModal"
                    @close="showScontoMaggiorazione = false"
                  />
                </div>
                <div class="">
                  <!-- <div>
                    <button
                      type="button"
                      class="w-full px-5 py-1.5 ml-1 orange-btn-inverted whitespace-nowrap"
                      @click="showCausale = true"
                    >
                      Causale documento
                    </button>
                  </div> -->
                  <div v-if="activeScMag">Attivo</div>
                  <CausaleModal
                    v-if="showCausale"
                    :old-causale="datiCausale"
                    @activeCausale="changeCausale"
                    @close="showCausale = false"
                  />
                </div>
              </div>
            </div>
            <!-- BEGIN: DATI IVA, CASSA, RITENUTE -->

            <!-- END: SECONDA PARTE FORM -->
            <!-- BEGIN: TERZA PARTE FORM -->
            <div class="col-span-2 grid grid-cols-2 gap-3">
              <!-- BEGIN: DATI PAGAMENTO -->
              <div
                v-if="
                  showFooter &&
                  !(
                    datiDocumento &&
                    datiDocumento.tipoDocumento &&
                    datiDocumento.tipoDocumento.description === 'preventivo' &&
                    readOnlyElem
                  ) &&
                  datiDocumento?.tipoDocumento?.code !== 'TD05'
                "
                class="col-span-2 md:col-span-1 flex-1 flex-col gap-2 p-2"
              >
                <div
                  v-if="
                    datiDocumento?.tipoDocumento?.description !== 'preventivo'
                  "
                  class=""
                >
                  <h3 class="stats-subs text-base">DATI PAGAMENTO</h3>
                </div>
                <div class="px-2 py-3 flex justify-center">
                  <button
                    v-if="showPayment == false && !readOnly"
                    type="button"
                    class="orange-btn-inverted whitespace-nowrap"
                    @click="showPayMode = true"
                  >
                    Inserire dati pagamento
                  </button>
                  <div class="w-full px-6">
                    <div v-if="showPayment == true || readOnly" class="">
                      <div class="text-right">
                        <button
                          v-if="!readOnly"
                          type="button"
                          class="text-theme-41 hover:bg-theme-41 hover:text-white rounded-lg text-sm cursor-pointer"
                          @click="showPayment = false"
                        >
                          <svg
                            class="w-4 h-4"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                      <div v-if="readOnly && !paymentType">
                        <i class="fas fa-wallet"></i>
                        <span></span>
                        {{
                          readOnlyElem &&
                          readOnlyElem.payment_method_id &&
                          paymentType
                            ? 'PAGAMENTO:' +
                              paymentType.code +
                              '-' +
                              paymentType.description
                            : 'NESSUN PAGAMENTO SELEZIONATO'
                        }}
                      </div>
                      <div v-else class="flex flex-col gap-1 items-start">
                        <div class="flex items-center gap-1">
                          <i class="fas fa-wallet text-gray-700"></i>
                          <span class="form-label mb-0"> Pagamento</span>
                        </div>

                        <span>
                          {{ paymentType.code }} - {{ paymentType.alias }} -
                          {{ paymentType.description }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <PayModal
                  v-if="showPayMode"
                  :default-payments="defaultPaymentMethods"
                  :payments="paymentMethods"
                  @add="addPayment"
                  @close="showPayMode = false"
                />
              </div>
              <!-- END: DATI PAGAMENTO -->
              <!-- BEGIN: CALCOLO FATTURA -->
              <div
                v-if="
                  ((documento === 'preventivo' ||
                    (datiDocumento &&
                      datiDocumento.tipoDocumento &&
                      datiDocumento.tipoDocumento.description ===
                        'preventivo')) &&
                    showRiepilogo) ||
                  !(
                    documento === 'preventivo' ||
                    (datiDocumento &&
                      datiDocumento.tipoDocumento &&
                      datiDocumento.tipoDocumento.description === 'preventivo')
                  )
                "
                class="col-span-2 md:col-span-1 flex-1 flex-col gap-3 p-2"
              >
                <h3 class="stats-subs text-base">
                  {{
                    datiDocumento &&
                    datiDocumento.tipoDocumento &&
                    datiDocumento.tipoDocumento.id === 19
                      ? 'CALCOLO PREVENTIVO '
                      : 'CALCOLO FATTURA'
                  }}
                </h3>

                <div class="px-6 py-1 flex flex-col gap-3">
                  <div class="grid grid-cols-3 gap-5 mx-5 lg:mx-10">
                    <div class="col-span-2 form-label mb-0">
                      Importo prodotti e servizi
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTot.servTot !== undefined
                          ? Math.round(
                              (invoiceTot.servTot + Number.EPSILON) * 100
                            ) / 100
                          : '0'
                      }}
                    </div>
                  </div>

                  <div
                    v-if="cassaImporto > 0"
                    class="grid grid-cols-3 gap-5 mx-5 lg:mx-10"
                  >
                    <div class="col-span-2 form-label mb-0">
                      Cassa {{ invoiceTot.cassa.type.cod }}
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTot.servTot !== undefined
                          ? Math.round(
                              (invoiceTot.servTot + Number.EPSILON) * 100
                            ) / 100
                          : '0'
                      }}
                    </div>
                  </div>

                  <div
                    v-if="showCassaComputed"
                    class="grid grid-cols-3 gap-5 mx-5 lg:mx-10"
                  >
                    <div class="col-span-2 form-label mb-0">
                      Cassa previdenziale
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTot.cassaTot != null
                          ? Math.round(
                              (invoiceTot.cassaTot + Number.EPSILON) * 100
                            ) / 100
                          : '0'
                      }}
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-5 mx-5 lg:mx-10">
                    <div class="col-span-2 form-label mb-0">
                      Totale imponibile
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTot.taxableTot !== undefined
                          ? Math.round(
                              (invoiceTot.taxableTot + Number.EPSILON) * 100
                            ) / 100
                          : '0'
                      }}
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-5 mx-5 lg:mx-10">
                    <div class="col-span-2 form-label mb-0">Totale IVA</div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTot.VATTot !== undefined
                          ? Math.round(
                              (invoiceTot.VATTot + Number.EPSILON) * 100
                            ) / 100
                          : '0'
                      }}
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-5 mx-5 lg:mx-10">
                    <div class="col-span-2 form-label mb-0">
                      Totale DOCUMENTO
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTot.total !== undefined
                          ? Math.round(
                              (invoiceTot.total + Number.EPSILON) * 100
                            ) / 100
                          : '0'
                      }}
                    </div>
                  </div>

                  <div
                    v-if="ritenutaImporto > 0 && ritenutaComputed"
                    class="grid grid-cols-3 gap-5 mx-5 lg:mx-10"
                  >
                    <div class="col-span-2 form-label mb-0">
                      Ritenuta d'acconto -
                      {{ ritenutaComputed.type.description }}
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        Math.round((ritenutaImporto + Number.EPSILON) * 100) /
                        100
                      }}
                    </div>
                  </div>

                  <div
                    v-if="bolloImporto > 0"
                    class="grid grid-cols-3 gap-5 mx-5 lg:mx-10"
                  >
                    <div class="col-span-2 form-label mb-0">Bollo</div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{ invoiceTot.bollo.value }}
                    </div>
                  </div>

                  <div
                    v-if="
                      documento === 'preventivo' ||
                      (datiDocumento &&
                        datiDocumento.tipoDocumento &&
                        datiDocumento.tipoDocumento.description &&
                        datiDocumento.tipoDocumento.description ===
                          'preventivo')
                    "
                    class="grid grid-cols-3 gap-5 mx-5 lg:mx-10"
                  >
                    <div class="col-span-2 form-label mb-0">
                      Totale IVATO MINIMO
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTotMin.toPay !== undefined
                          ? Math.round(invoiceTotMin.toPay * 100) / 100
                          : '0'
                      }}
                    </div>

                    <div class="col-span-2 form-label mb-0">
                      Totale IVATO MASSIMO
                    </div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTotMax.toPay !== undefined
                          ? Math.round(invoiceTotMax.toPay * 100) / 100
                          : '0'
                      }}
                    </div>
                  </div>

                  <div v-else class="grid grid-cols-3 gap-5 mx-5 lg:mx-10">
                    <div class="col-span-2 form-label mb-0">Netto a pagare</div>
                    <div class="col-span-1 form-label mb-0">
                      €
                      {{
                        invoiceTot.toPay !== undefined
                          ? Math.round(
                              (invoiceTot.toPay + Number.EPSILON) * 100
                            ) / 100
                          : '0'
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- END: CALCOLO FATTURA -->
            </div>

            <!-- END: TERZA PARTE FORM -->
          </form>

          <!-- END: FORM FATTURAZIONE -->
        </div>
        <!-- BEGIN: Note a piè di pagina -->
        <!-- <div v-if="showFooter && !readOnly" class="flex flex-row justify-start"> -->
        <div
          v-if="!readOnly"
          class="flex flex-row items-center gap-1 justify-start px-6"
        >
          <div class="flex flex-col justify-center">
            <button
              v-if="!checkNote"
              type="button"
              class="orange-round w-7 h-7"
              @click="showNoteModal = true"
            >
              <i class="fas fa-plus fill-current"></i>
            </button>
            <button
              v-else
              type="button"
              class="orange-round w-7 h-7"
              @click="showNoteModal = true"
            >
              <i class="fas fa-edit text-xs pl-0.5"></i>
            </button>

            <NoteModal
              v-if="showNoteModal"
              :old-note="datiNotaPiePagina"
              @close="showNoteModal = false"
              @addNote="editNote"
            />
          </div>

          <label class="form-label mb-0"
            >Note a piè di pagina <span v-if="checkNote">:</span></label
          >

          <div v-if="checkNote" class="font-medium text-gray-700 uppercase">
            Attivo
          </div>
        </div>

        <div
          v-else-if="
            readOnlyElem &&
            (readOnlyElem.setup_footnote || readOnlyElem.footnotes)
          "
          class="flex flex-col ml-5"
        >
          <span><b>Nota a piè di pagina:</b></span>

          <span
            v-html="
              readOnlyElem.setup_footnote
                ? readOnlyElem.setup_footnote.text
                : readOnlyElem.footnotes
            "
          >
          </span>
        </div>
        <!-- END: Note a piè di pagina -->
        <div
          v-if="
            (documento === 'preventivo' ||
              datiDocumento?.tipoDocumento?.id === 19) &&
            false
          "
          class="flex items-start"
        >
          <input
            type="checkbox"
            class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
            :value="showRiepilogoInvoice"
            @change="() => (showRiepilogoInvoice = !showRiepilogoInvoice)"
          />
          <label for="flexCheckDefault" class="ml-1">
            Aggiungere i calcoli nel pdf?
          </label>
        </div>
        <!-- BEGIN: Stampa e invia -->
        <div v-if="!readOnly" class="flex items-center gap-3 justify-end px-3">
          <button
            class="whitespace-nowrap orange-btn px-5"
            :class="blockSendButtons ? 'disabled' : ''"
            @click="stampaRiepilogo()"
          >
            <i class="fas fa-save mr-1"></i> Salva
          </button>

          <!-- <div class="mx-4 my-2">
            <button
              class="w-full px-5 py-1.5 ml-1 orange-btn whitespace-nowrap"
            >
              Anteprima
            </button>
          </div>
          <div class="mx-4 my-2">
            <button
              class="w-full px-5 py-1.5 ml-1 orange-btn whitespace-nowrap"
            >
              Invia fattura elettronica
            </button>
          </div> -->

          <button
            v-if="
              ((readOnlyElem && readOnlyElem.status_doc === 'bozza') ||
                !readOnlyElem) &&
              !notaCredito &&
              !notaDebito
            "
            class="whitespace-nowrap orange-btn px-5"
            :class="blockSendButtons ? 'disabled' : ''"
            @click="stampaRiepilogo('bozza')"
          >
            <i class="fas fa-edit mr-1"></i> Salva bozza
          </button>
        </div>
        <!-- END: Stampa e invia -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NewClient from '../../views/nuovo-cliente/NewClient.vue'
import TariffarioModal from './TariffarioModal.vue'
import PayModal from './PayModal.vue'
import CassaModal from './CassaModal.vue'
import RitenutaModal from './RitenutaModal.vue'
import IvaModal from './IvaModal.vue'
import BolloModal from './BolloModal.vue'
import ScontoMaggiorazioneModal from './ScontoMaggiorazioneModal.vue'
import CausaleModal from './CausaleModal.vue'
import NoteModal from './NoteModal.vue'
import SearchDrop from '../../components/search-drop/SearchDrop.vue'
import axios from '../../gateway/backend-api'
import flatpickr from 'flatpickr'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import _ from 'lodash'

// const EmptyRow = {
//   cod: '',
//   desc: '',
//   qty: '',
//   price: '',
//   disc: '',
//   taxable: ''
// }

export default {
  name: 'InvoiceServices',
  components: {
    NewClient,
    SearchDrop,
    TariffarioModal,
    PayModal,
    CassaModal,
    RitenutaModal,
    IvaModal,
    BolloModal,
    ScontoMaggiorazioneModal,
    CausaleModal,
    NoteModal
  },
  props: {
    services: {
      type: [Array],
      default: function () {
        return []
      }
    },
    client: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    documento: {
      type: [String],
      default: function () {
        return ''
      }
    },
    showFooter: {
      type: [Boolean],
      default: function () {
        return true
      }
    },
    readOnly: Boolean,
    readOnlyElem: {
      type: [Object],
      default: function () {
        return null
      }
    },
    title: {
      type: [String],
      default: function () {
        return ''
      }
    },
    mediumSize: Boolean,
    notaDebito: Boolean,
    onlyAcconto: Boolean,
    fatturaPreventivo: Boolean,
    notaCredito: Boolean,
    modify: Boolean
  },
  emits: ['close', 'responseModal', 'refreshTables'],
  data() {
    return {
      datiDocumento: {
        tipoCliente: '',
        tipoDocumento: '',
        sezionale: this.notaDebito
          ? 'ND'
          : this.notaCredito
          ? 'NC'
          : this.client.type == 'PA'
          ? 'FPA'
          : 'FPR',
        progressivo: 1,
        data: ''
      },
      today: '',
      showModalEditClient: false,
      showPayMode: false,
      showModalTariff: false,
      showModalCassa: false,
      showModalIva: false,
      showModalRitenuta: false,
      showModalBollo: false,
      showCausale: false,
      showNoteModal: false,
      showScontoMaggiorazione: false,
      indexRowFromTariff: '',
      showRowSettings: [],
      isSearchingTariff: [],
      isSearchingCod: [],
      serviceCount: 0, //this.services.length,
      serviceRows: [],
      paymentType: '',
      showPayment: false,
      cliccato: false,
      cupCode: '',
      cigCode: '',
      data_saldo: null,
      data_preventivo: null,
      invoiceTot: [
        // {
        //   servTot: '',
        //   taxableTot: '',
        //   VATTot: '',
        //   total: '',
        //   toPay: '',
        //   cassa: '',
        //   bollo: {
        //     id: '',
        //     value: ''
        //   },
        //   ritenuta: ''
        // }
      ],
      invoiceTotMax: [],
      invoiceTotMin: [],
      clientInvoice: this.client,
      isActiveSearch: false,
      searchBar: '',
      idDrag: '',
      idDrop: '',
      bolloImporto: 0,
      cassaImporto: 0,
      ritenutaImporto: 0,
      checksRitenuta: [],
      checksCassa: [],
      activeScMag: false,
      datiCausale: {
        check: false,
        text: ''
      },
      checkNote: false,
      datiNotaPiePagina: {},
      blockSendButtons: true,
      showRiepilogoInvoice: false,
      futureVariable: true,
      cassaFattura: null,
      ritenutaFattura: null,
      ritenutaChecked: null,
      vets: null,
      prefisso: null,
      scontoAbilitato: true,
      scontoPercentuale: null,
      ivaSelectedModal: null
    }
  },

  computed: {
    ...mapGetters({
      clinicDetails: 'main/clinicSetup',
      staffDetails: 'getUser',
      paymentMethods: 'reusableData/getPaymentMethods',
      defaultPaymentMethods: 'reusableData/getDefaultPaymentMethods',
      documentTypes: 'reusableData/getDocumentTypes',
      listVAT: 'reusableData/getListVat',
      iva: 'reusableData/getShowIva',
      showRiepilogo: 'reusableData/getShowRiepilogoStampa',
      permissions: 'reusableData/getPermissions',
      cassa: 'reusableData/getCassaPrevidenzialeActive',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc',
      ivaGlobal: 'reusableData/getRegimeFiscaleIva',
      ritenutaGlobal: 'reusableData/getRitenutaAccontoActive',
      ritenutaGlobalDefault: 'reusableData/getRitenutaAccontoDefault',
      prefissoSezionale: 'reusableData/getPrefissoSezionale',
      scontoPercentualeGlobal: 'reusableData/getScontoPercentualeActive',
      sezionali: 'reusableData/getSezionali',
      scadenzaPDefault: 'reusableData/getScadenzaDefault'
    }),
    showCassaComputed: function () {
      if (
        this.documento === 'preventivo' ||
        this.datiDocumento?.tipoDocumento?.id === 19
      )
        return false
      if (this.cassaFattura !== null) return this.cassaFattura
      if (this.readOnlyElem)
        if (
          this.readOnlyElem?.bill_setup &&
          this.readOnlyElem?.bill_setup?.cassa !== null
        )
          return this.readOnlyElem.bill_setup.cassa
        else return false

      if (this.cassa) return true
      else return false
    },
    ritenutaComputed: function () {
      if (this.client.type === 'Persona fisica') return null
      if (
        this.documento === 'preventivo' ||
        this.datiDocumento?.tipoDocumento?.id === 19
      )
        return null
      //if (this.readOnlyElem && !this.ritenutaFattura) return null
      if (this.readOnlyElem && this.ritenutaChecked === null)
        if (
          this.readOnlyElem?.bill_setup &&
          this.readOnlyElem?.bill_setup?.ritenuta &&
          this.readOnlyElem?.bill_setup?.ritenuta !== null
        )
          return {
            aliquota: this.readOnlyElem.bill_setup.ritenuta.ritenutaPerc,
            type: {
              description:
                this.readOnlyElem.bill_setup.ritenuta.ritenutaDescription,
              code: this.readOnlyElem.bill_setup.ritenuta.ritenutaTipo
            },
            causale: {
              code: this.readOnlyElem.bill_setup.ritenuta.ritenutaCaus
            }
          }
        else return null

      if (this.ritenutaChecked === false) return null
      if (this.ritenutaFattura !== null) return this.ritenutaFattura
      if (this.ritenutaGlobal) return this.ritenutaGlobal
      else return null
    }
  },
  watch: {
    scontoAbilitato: function (v) {
      this.serviceRows = this.serviceRows.map((row) => {
        if (v) {
          row.taxable_max = null
          row.taxable_max_iva = null
          row.price_max = null
          row.price_max_iva = null
        } else {
          row.disc_price = null
          row.disc = null
        }

        return row
      })

      this.serviceRows.forEach((_, index) => this.calculate(index))
    },
    ivaSelectedModal: function () {
      this.listVAT.forEach((el) => {
        if (el.vat_percentage === this.ivaSelectedModal) {
          this.serviceRows.forEach((value, index) => {
            value.VAT = el
            this.calculate(index)
          })
        }
      })
    }
  },
  mounted: function () {
    if (this.scontoPercentualeGlobal && !this.readOnlyElem)
      this.scontoPercentuale = this.scontoPercentualeGlobal
    //document.addEventListener('click', this.closeDropDownSearch)

    flatpickr.localize(Italian)
    flatpickr('.date', {
      dateFormat: 'd/m/Y',
      allowInput: true,
      allowInvalidPreload: true
    })
    if (!this.readOnly && this.readOnlyElem && this.readOnlyElem.bill_rows) {
      this.serviceCount = this.readOnlyElem.bill_rows.length
    }
    if (!this.readOnlyElem) this.ivaSelectedModal = this.ivaGlobal
  },

  async created() {
    if (
      (!this.readOnlyElem ||
        (this.readOnlyElem && this.readOnlyElem.status_doc === 'bozza')) &&
      this.prefissoSezionale &&
      this.isNewInvoice()
    ) {
      if (this.prefissoSezionale.length === 1) {
        this.prefisso = this.prefissoSezionale[0].id
      }
    }
    this.showRiepilogoInvoice = this.showRiepilogo

    this.getDocumentType()
    this.getStaff()
    if (this.scadenzaPDefault) this.data_preventivo = this.scadenzaPDefault

    //Alla creazione del componente riempio la tabella con i servizi da fatturare passati in precedenza

    if (this.readOnlyElem && Object.keys(this.readOnlyElem).length) {
      if (
        this.readOnlyElem.clinic_sectional &&
        this.readOnlyElem.clinic_sectional.prefix &&
        !(this.notaDebito || this.notaCredito)
      ) {
        this.datiDocumento.sezionale = this.readOnlyElem.clinic_sectional.prefix
      }

      if (this.fatturaPreventivo) this.datiDocumento.sezionale = 'FPR'
      if (this.readOnlyElem.bill_setup.cigCode)
        this.cigCode = this.readOnlyElem.bill_setup.cigCode
      if (this.readOnlyElem.bill_setup.data_saldo)
        this.data_saldo = this.readOnlyElem.bill_setup.data_saldo
      if (this.readOnlyElem.bill_setup.data_preventivo)
        this.data_preventivo = this.readOnlyElem.bill_setup.data_preventivo
      if (this.readOnlyElem.bill_setup.cupCode)
        this.cupCode = this.readOnlyElem.bill_setup.cupCode
      if (this.readOnlyElem.bill_type_id && this.modify) {
        this.documentTypes.forEach((el) => {
          if (el.id === this.readOnlyElem.bill_type_id)
            this.datiDocumento.tipoDocumento = el
        })
      }
      if (this.readOnlyElem?.bill_setup?.percentage_discount) {
        console.log('if')
        this.scontoPercentuale =
          this.readOnlyElem.bill_setup.percentage_discount
      } else {
        console.log('else')
        this.scontoPercentuale = false
      }
      if (this.isPreventivo()) this.scontoAbilitato = false
      // this.DatiDocumento.progressivo = this.readOnlyElem.clinic_sezional_id
      for (let i = 0; i < this.readOnlyElem.bill_rows.length; i++) {
        let disc = 0
        let disc_price = null
        if (this.readOnlyElem.bill_rows[i].discount) {
          this.scontoAbilitato = true
          disc =
            this.readOnlyElem.bill_rows[i].quantity *
            ((this.readOnlyElem.bill_rows[i].price *
              (this.readOnlyElem.bill_rows[i].discount * 1)) /
              100)

          disc_price =
            Math.round(
              (this.readOnlyElem.bill_rows[i].price *
                (this.showCassaComputed
                  ? this.cassa && this.cassa.vat
                    ? (this.cassa.vat + 100) / 100
                    : 1.02
                  : 1) *
                (this.readOnlyElem.bill_rows[i].pension_fund_tax_rate !== null
                  ? (100 +
                      this.readOnlyElem.bill_rows[i].pension_fund_tax_rate) /
                    100
                  : this.ivaPerc) +
                (this.readOnlyElem.bill_rows[i].price *
                  (this.showCassaComputed
                    ? this.cassa && this.cassa.vat
                      ? (this.cassa.vat + 100) / 100
                      : 1.02
                    : 1) *
                  (this.readOnlyElem.bill_rows[i].pension_fund_tax_rate !== null
                    ? (100 +
                        this.readOnlyElem.bill_rows[i].pension_fund_tax_rate) /
                      100
                    : this.ivaPerc) *
                  this.readOnlyElem.bill_rows[i].discount) /
                  100 +
                Number.EPSILON) *
                100
            ) / 100
        } else {
          disc =
            this.readOnlyElem.bill_rows[i].quantity *
            this.readOnlyElem.bill_rows[i].price
        }

        let disc_max = disc
        console.log('disc max', disc_max)

        if (
          this.readOnlyElem.bill_rows[i].taxable_max &&
          this.readOnlyElem.bill_rows[i].discount
        )
          disc_max =
            this.readOnlyElem.bill_rows[i].quantity *
            ((this.readOnlyElem.bill_rows[i].taxable_max *
              (this.readOnlyElem.bill_rows[i].discount * 1)) /
              100)

        let tempIva = this.listVAT[0]
        this.listVAT.forEach((el) => {
          if (
            el.vat_percentage ===
            this.readOnlyElem.bill_rows[i].pension_fund_tax_rate
          ) {
            tempIva = el
            this.ivaSelectedModal = el
          }
        })

        this.serviceRows.push({
          code_id: this.readOnlyElem.bill_rows[i].cod_tariffa_alias,
          cod_tariffa_alias: this.readOnlyElem.bill_rows[i].cod_tariffa_alias,
          desc: this.readOnlyElem.bill_rows[i].description,
          qty: this.readOnlyElem.bill_rows[i].quantity,
          price:
            this.readOnlyElem.bill_rows[i].price *
            (this.showCassaComputed
              ? this.cassa && this.cassa.vat
                ? (this.cassa.vat + 100) / 100
                : 1.02
              : 1),
          priceIva:
            Math.round(
              (this.readOnlyElem.bill_rows[i].price *
                (this.showCassaComputed
                  ? this.cassa && this.cassa.vat
                    ? (this.cassa.vat + 100) / 100
                    : 1.02
                  : 1) *
                (this.readOnlyElem.bill_rows[i].pension_fund_tax_rate !== null
                  ? (100 +
                      this.readOnlyElem.bill_rows[i].pension_fund_tax_rate) /
                    100
                  : this.ivaPerc) +
                Number.EPSILON) *
                100
            ) / 100,

          disc: this.readOnlyElem.bill_rows[i].discount,
          disc_price: disc_price,
          //id_account: this.readOnlyElem.bill_rows[i].id,
          id: this.modify === true ? this.readOnlyElem.bill_rows[i].id : null,
          taxable:
            this.readOnlyElem.bill_rows[i].price *
            this.readOnlyElem.bill_rows[i].quantity *
            (this.showCassaComputed
              ? this.cassa && this.cassa.vat
                ? (this.cassa.vat + 100) / 100
                : 1.02
              : 1),

          price_max: this.readOnlyElem.bill_rows[i].taxable_max
            ? parseFloat(
                this.readOnlyElem.bill_rows[i].taxable_max *
                  (this.showCassaComputed
                    ? this.cassa && this.cassa.vat
                      ? (this.cassa.vat + 100) / 100
                      : 1.02
                    : 1)
              )
            : null,
          price_max_iva: this.readOnlyElem.bill_rows[i].taxable_max
            ? Math.round(
                (this.readOnlyElem.bill_rows[i].taxable_max *
                  this.ivaPerc *
                  (this.showCassaComputed
                    ? this.cassa && this.cassa.vat
                      ? (this.cassa.vat + 100) / 100
                      : 1.02
                    : 1) +
                  Number.EPSILON) *
                  100
              ) / 100
            : null,
          taxable_max: this.readOnlyElem.bill_rows[i].taxable_max
            ? parseFloat(
                this.readOnlyElem.bill_rows[i].taxable_max *
                  this.readOnlyElem.bill_rows[i].quantity *
                  (this.showCassaComputed
                    ? this.cassa && this.cassa.vat
                      ? (this.cassa.vat + 100) / 100
                      : 1.02
                    : 1)
              )
            : null,
          taxable_max_iva: this.readOnlyElem.bill_rows[i].taxable_max
            ? Math.round(
                (this.readOnlyElem.bill_rows[i].taxable_max *
                  this.readOnlyElem.bill_rows[i].quantity *
                  this.ivaPerc *
                  (this.showCassaComputed
                    ? this.cassa && this.cassa.vat
                      ? (this.cassa.vat + 100) / 100
                      : 1.02
                    : 1) +
                  Number.EPSILON) *
                  100
              ) / 100
            : null,
          VAT: tempIva,
          tariffario_alias_id:
            this.readOnlyElem.bill_rows[i].tariffario_alias_id,
          details_prestazioni: this.readOnlyElem.bill_rows[i]
            .details_prestazioni
            ? this.readOnlyElem.bill_rows[i].details_prestazioni
            : null,
          performed_by: this.readOnlyElem.bill_rows[i].performed_by ?? null,
          tariffario_packages_id:
            this.readOnlyElem.bill_rows[i].tariffario_packages_id ?? null
        })

        // if (
        //   this.readOnlyElem.bill_setup &&
        //   this.readOnlyElem.bill_setup.ritenuta
        // ) {
        //   this.ritenutaFattura = {
        //     aliquota: this.readOnlyElem.bill_setup.ritenuta.ritenutaPerc,
        //     type: {
        //       description:
        //         this.readOnlyElem.bill_setup.ritenuta.ritenutaDescription,
        //       code: this.readOnlyElem.bill_setup.ritenuta.ritenutaTipo
        //     },
        //     causale: {
        //       code: this.readOnlyElem.bill_setup.ritenuta.ritenutaCaus
        //     }
        //   }
        // }

        this.showRowSettings.push(false)
        this.calculate(i)
      }
      if (this.readOnlyElem.clinic_sectional?.clinic_suffix) {
        this.prefisso = this.readOnlyElem.clinic_sectional.clinic_suffix.id
      }
      if (this.readOnlyElem.tax_stamp_value) {
        this.bolloImporto = this.readOnlyElem.tax_stamp_value.value
      }
    } else if (this.services.length) {
      let tmp = null

      let tempIva = this.listVAT[0]
      if (this.ivaPerc) {
        this.listVAT.forEach((el) => {
          if (el.vat_percentage === this.ivaGlobal) tempIva = el
        })
      }
      for (let i = 0; i < this.services.length; i++) {
        if (this.services[i].price) {
          let disc_price = null
          if (this.services[i].sconto && this.services[i].sconto * 1 !== 0) {
            disc_price =
              Math.round(
                (this.services[i].price *
                  1 *
                  ((100 + tempIva.vat_percentage) / 100) +
                  (this.services[i].price *
                    1 *
                    ((100 + tempIva.vat_percentage) / 100) *
                    this.services[i].sconto) /
                    100 +
                  Number.EPSILON) *
                  100
              ) / 100
          }

          tmp = {
            code_id: !this.onlyAcconto
              ? this.services[i].code
                ? this.services[i].code
                : this.services[i].cod_tariffa_alias
              : 'T4U_A0001',
            cod_tariffa_alias: !this.onlyAcconto
              ? this.services[i].cod_tariffa_alias
              : 'T4U_A0001',
            desc: !this.onlyAcconto
              ? this.services[i].tariffario_alias &&
                this.services[i].tariffario_alias !== ''
                ? this.services[i].tariffario_alias
                : this.services[i].tariffario_description &&
                  this.services[i].tariffario_description !== ''
                ? this.services[i].tariffario_description
                : this.services[i].description
              : 'Acconto',
            qty: this.services[i].qty,
            price:
              !this.onlyAcconto &&
              (this.services[i].cod_tariffa_alias === 'T4U_A0002' ||
                this.services[i].cod_tariffa_alias === 'T4U_A0001')
                ? -this.services[i].price
                : this.services[i].price -
                  this.getArrotondamentoNetto(this.services[i].price),
            disc: this.services[i].sconto,
            disc_price: disc_price,
            taxable:
              !this.onlyAcconto &&
              (this.services[i].cod_tariffa_alias === 'T4U_A0002' ||
                this.services[i].cod_tariffa_alias === 'T4U_A0001')
                ? -this.services[i].price * this.services[i].quantity
                : this.services[i].price * this.services[i].quantity,
            id_account:
              this.services && this.services.length > 0
                ? this.services[i].id
                : null,
            id:
              this.services &&
              this.services.length === 0 &&
              this.modify === true
                ? this.services[i].id
                : null,
            performed_by: this.services[i].performed_by ?? null,
            details_prestazioni: this.services[i].details_prestazioni ?? null,
            tariffario_packages_id:
              this.services[i].type === 'package' &&
              this.services[i].tariffario_alias_id
                ? this.services[i].tariffario_alias_id
                : null,
            tariffario_alias_id:
              this.services[i].type !== 'package' &&
              this.services[i].tariffario_id
                ? this.services[i].tariffario_id
                : null,
            newLine:
              this.services[i].cod_tariffa_alias === 'T4U_A0002' ||
              this.services[i].cod_tariffa_alias === 'T4U_A0001' ||
              this.services[i].tariffario_alias_id ||
              this.services[i].tariffario_packages_id ||
              this.onlyAcconto
                ? false
                : true,
            priceIva: this.services[i].priceiva
              ? Math.round(
                  (this.services[i].priceiva * 1 + Number.EPSILON) * 100000000
                ) / 100000000
              : null
          }
          tmp.VAT = this.services[i].pension_fund_tax_rate_id != null 
                    ? await this.listVAT.find(el => el.id === this.services[i].pension_fund_tax_rate_id )
                    : tempIva
          this.serviceRows.push(tmp)
          this.serviceCount++
          this.showRowSettings.push(false)
          this.calculate(i)
        }
      }
    }

    let temp = new Date()

    this.today = this.$FormatDateMin(this.$FormatEpoch(temp))
    this.datiDocumento.data = this.$FormatEpochMin(this.today)
    if (this.readOnlyElem && Object.keys(this.readOnlyElem).length) {
      this.today = this.$FormatDateMin(this.readOnlyElem.datetime)
      let setup_id_to_search = null
      if (this.readOnlyElem.payment_method_id) {
        setup_id_to_search = this.readOnlyElem.payment_method_id
      } else {
        setup_id_to_search = this.readOnlyElem.setup_payment_method_id
      }
      for (let i = 0; i < this.paymentMethods.length; i++) {
        if (this.paymentMethods[i].id === setup_id_to_search) {
          this.paymentType = this.paymentMethods[i]
          this.showPayment = true
        }
      }
    }

    this.invoiceTot.bollo = {
      id: '',
      value: 0
    }

    this.paymentMethods.forEach((el) => {
      if (el.is_default) {
        this.paymentType = el
        this.showPayment = true
      }
    })
  },

  methods: {
    isDefaultSectional(opt) {
      if (
        opt === 'ND' &&
        (this.datiDocumento.sezionale === 'ND' || this.notaDebito)
      ) {
        console.log('true')
        return true
      }
      if (
        opt === 'NC' &&
        (this.datiDocumento.sezionale === 'NC' || this.notaCredito)
      ) {
        console.log('true')
        return true
      }
      if (opt === 'FPA' && this.clientInvoice?.type === 'PA') {
        console.log('true')
        return true
      }

      console.log('false')
      return false
    },
    closeModal() {
      this.$emit('close')
    },
    // Inserisce una nuova riga servizio vuota
    addAcconto() {
      this.showRowSettings.push(false)
      this.isSearchingCod.push(false)
      this.isSearchingTariff.push(false)
      this.serviceRows.push({
        code_id: '',
        desc: 'Acconto',
        qty: '1',
        price: '',
        disc: '',
        taxable: '',
        VAT: this.listVAT[0]
      })
      this.serviceCount += 1
    },
    // Elimina la riga del servizio selezionato
    deleteService(index) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Attenzione',
        subtitle: 'Sei sicuro di voler eliminare questa riga?',
        background: false,
        confirm: () => {
          if (this.serviceCount > 1) {
            this.serviceRows.splice(index - 1, 1)
            this.showRowSettings.splice(index - 1, 1)
            this.checksRitenuta.splice(index - 1, 1)
            this.checksCassa.splice(index - 1, 1)
            this.serviceCount -= 1
            this.calculateTotal()
          } else {
            this.$store.dispatch('commonComponents/openMessageModal', {
              title: 'Attenzione',
              subtitle: 'La fattura deve contenere almeno una prestazione'
            })
          }
        },
        decline: () => {
          //this.changeRowSettings(index - 1)
        }
      })

      // if (confirm('Sei sicuro di voler eliminare questa riga?')) {
      //   if (this.serviceCount > 1) {
      //     this.serviceRows.splice(index - 1, 1)
      //     this.showRowSettings.splice(index - 1, 1)
      //     this.checksRitenuta.splice(index - 1, 1)
      //     this.checksCassa.splice(index - 1, 1)
      //     this.serviceCount -= 1
      //     this.calculateTotal()
      //   } else {
      //     this.$store.dispatch('commonComponents/openMessageModal', {
      //       title: 'Attenzione',
      //       subtitle: 'La fattura deve contenere almeno una prestazione'
      //     })
      //   }
      // } else {
      //   this.changeRowSettings(index - 1)
      // }
    },
    // Duplica la riga del servizio selezionato inserendone una nuova alla riga successiva
    duplicateService(index) {
      let duplicateService = {
        code_id: this.serviceRows[index - 1].code_id,
        desc: this.serviceRows[index - 1].desc,
        qty: this.serviceRows[index - 1].qty,
        price: this.serviceRows[index - 1].price,
        disc: this.serviceRows[index - 1].disc,
        taxable: this.serviceRows[index - 1].taxable,

        VAT: this.serviceRows[index - 1].VAT,
        tariffario: this.serviceRows[index - 1].tariffario
      }

      if (this.serviceRows[index - 1].taxable_max) {
        duplicateService[' taxable_max'] =
          this.serviceRows[index - 1].taxable_max
      }

      this.serviceRows.splice(index, 0, duplicateService)
      this.showRowSettings.splice(index - 1, 0, false)
      this.isSearchingCod.splice(index - 1, 0, false)
      this.isSearchingTariff.splice(index - 1, 0, false)
      this.checksRitenuta.splice(index, 0, false)
      this.checksCassa.splice(index, 0, false)
      this.serviceCount += 1
      this.calculateTotal()
      this.changeRowSettings()
      // !this.showRowSettings
      // this.changeRowSettings(index)
    },
    // Aggiunge una riga dei servizi in fattura con i dati selezionati dal tariffario
    addTariff(nomenclatore, price, price_path, type, not_close) {
      if (
        JSON.stringify(this.serviceRows[0]) ===
        JSON.stringify({
          tariffario_alias_id: '',
          code_id: '',
          desc: '',
          qty: '',
          price: '',
          disc: '',
          taxable: 0,
          taxable_max: 0,
          price_max: null,
          price_max_iva: null,
          VAT: this.listVAT[0]
        })
      ) {
        this.serviceRows.splice(1 - 1, 1)
        this.showRowSettings.splice(1 - 1, 1)
        this.checksRitenuta.splice(1 - 1, 1)
        this.checksCassa.splice(1 - 1, 1)
        this.serviceCount -= 1
      }
      let tempIva = this.listVAT[0]
      if (this.ivaPerc) {
        this.listVAT.forEach((el) => {
          if (this.ivaSelectedModal == 0) {
            if (el.vat_percentage === 0) tempIva = el
          } else if (el.vat_percentage === this.ivaGlobal) tempIva = el
        })
      }
      this.serviceRows.push({
        tariffario_alias_id:
          !type || type === 'Prestazioni' || type === 'Distinte'
            ? nomenclatore.tariffario_alias_id
            : null,
        tariffario_packages_id:
          !type || type === 'Prestazioni' ? null : nomenclatore.id,
        code_id: !type || type === 'Prestazioni' ? nomenclatore.code_id : null, // 'pkg_' + nomenclatore.id
        desc:
          nomenclatore.alias && nomenclatore.alias !== ''
            ? nomenclatore.alias
            : nomenclatore.description,
        qty: 1,
        price:
          type === 'Distinte'
            ? price.data
            : price.data -
              (nomenclatore.discount
                ? price.data * ((nomenclatore.discount * 1 + 100) / 100)
                : 1) +
              (nomenclatore.greatest
                ? price.data * ((nomenclatore.greatest * 1 + 100) / 100)
                : 1),
        priceIva:
          Math.round(
            ((price.data -
              (nomenclatore.discount
                ? price.data * ((nomenclatore.discount * 1 + 100) / 100)
                : 1) +
              (nomenclatore.greatest
                ? price.data * ((nomenclatore.greatest * 1 + 100) / 100)
                : 1)) *
              this.ivaPerc +
              Number.EPSILON) *
              100000000
          ) / 100000000,
        disc:
          type === 'Distinte'
            ? 0 -
              (nomenclatore.discount ? nomenclatore.discount * 1 : 0) +
              (nomenclatore.greatest ? nomenclatore.greatest * 1 : 0)
            : 0,
        taxable: '',
        taxable_max:
          this.documento === 'preventivo' ||
          this.datiDocumento?.tipoDocumento?.id === 19
            ? price.data -
              (nomenclatore.discount
                ? price.data * ((nomenclatore.discount * 1 + 100) / 100)
                : 1) +
              (nomenclatore.greatest
                ? price.data * ((nomenclatore.greatest * 1 + 100) / 100)
                : 1)
            : 0,
        taxable_max_iva:
          Math.round(
            ((price.data -
              (nomenclatore.discount
                ? price.data * ((nomenclatore.discount * 1 + 100) / 100)
                : 1) +
              (nomenclatore.greatest
                ? price.data * ((nomenclatore.greatest * 1 + 100) / 100)
                : 1)) *
              this.ivaPerc +
              Number.EPSILON) *
              100000000
          ) / 100000000,

        VAT: tempIva,
        price_path: price_path,
        pkg_info:
          !type || type === 'Prestazioni'
            ? null
            : {
                ...nomenclatore,
                price: Math.round((price.data + Number.EPSILON) * 100) / 100
              },
        prestazione_info:
          !type || type === 'Prestazioni'
            ? {
                price: Math.round((price.data + Number.EPSILON) * 100) / 100,
                desc:
                  nomenclatore.alias && nomenclatore.alias !== ''
                    ? nomenclatore.alias
                    : nomenclatore.description,
                code_id: nomenclatore.code_id
              }
            : null,
        taglia_label: price && price.alias ? price.alias : '',
        performed_by: this.staffDetails
          ? [
              {
                id: this.staffDetails.id,
                full_name: this.staffDetails.full_name
              }
            ]
          : null
      })

      this.serviceCount += 1
      this.calculate(this.serviceRows.length - 1)
      this.calculateTotal()
      this.showRowSettings.splice(this.serviceRows.length - 1, 0, false)
      this.isSearchingCod.splice(this.serviceRows.length - 1, 0, false)
      this.isSearchingTariff.splice(this.serviceRows.length - 1, 0, false)
      this.checksRitenuta.splice(this.serviceRows.length - 1, 0, false)
      this.checksCassa.splice(this.serviceRows.length - 1, 0, false)
      if (!not_close) this.showModalTariff = false
    },
    addNewLine() {
      let tempIva = this.listVAT[0]
      if (this.ivaPerc) {
        this.listVAT.forEach((el) => {
          if (this.ivaSelectedModal == 0) {
            if (el.vat_percentage === 0) tempIva = el
          } else if (el.vat_percentage === this.ivaGlobal) tempIva = el
        })
      }
      this.serviceRows.push({
        tariffario_alias_id: null,
        tariffario_packages_id: null,
        code_id: null,
        desc: '',
        qty: 1,
        price: null,
        priceIva: null,
        price_max: null,
        price_max_iva: null,
        disc: '',
        taxable: '',
        taxable_max: null,
        taxable_max_iva: null,
        VAT: tempIva,
        newLine: true,
        performed_by: this.staffDetails
          ? [
              {
                id: this.staffDetails.id,
                full_name: this.staffDetails.full_name
              }
            ]
          : null
      })

      this.serviceCount += 1
      this.calculate(this.serviceRows.length - 1)
      this.calculateTotal()
      this.showRowSettings.splice(this.serviceRows.length - 1, 0, false)
      this.isSearchingCod.splice(this.serviceRows.length - 1, 0, false)
      this.isSearchingTariff.splice(this.serviceRows.length - 1, 0, false)
      this.checksRitenuta.splice(this.serviceRows.length - 1, 0, false)
      this.checksCassa.splice(this.serviceRows.length - 1, 0, false)
    },

    changeIva: function (value) {
      this.ivaSelectedModal = value
      this.showModalIva = false
    },
    // Aggiungi metodo di pagamento selezionato dal modale di inserimento dati pagamento
    addPayment: function (value) {
      this.paymentType = value
      this.showPayMode = false
      this.showPayment = true
    },
    // Calcola per la riga passata come parametro i valori dello sconto, imponibile e totale
    calculate(index, updateMaxPrice) {
      if (this.serviceRows[index].priceIva) {
        if (this.serviceRows[index].newLine)
          this.serviceRows[index].price =
            this.serviceRows[index].priceIva /
            ((100 + this.serviceRows[index].VAT.vat_percentage) / 100)
        else
          this.serviceRows[index].priceIva =
            this.serviceRows[index].price *
            ((100 + this.serviceRows[index].VAT.vat_percentage) / 100)
      }
      if (!this.scontoPercentuale) {
        if (
          this.serviceRows[index].disc_price !== null &&
          this.serviceRows[index].disc_price !== undefined &&
          this.serviceRows[index].disc_price !== ''
        ) {
          let t =
            Math.round(
              ((100 *
                (this.serviceRows[index].disc_price -
                  this.serviceRows[index].priceIva)) /
                this.serviceRows[index].priceIva +
                Number.EPSILON) *
                100
            ) / 100

          this.serviceRows[index].disc_price =
            this.serviceRows[index].priceIva +
            (this.serviceRows[index].priceIva * t) / 100

          if (t > 100) {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message:
                'Il prezzo maggiorato non può superare il valore percentuale del 100%'
            })
            this.serviceRows[index].disc = 0
            this.serviceRows[index].disc_price =
              this.serviceRows[index].priceIva
            return
          }

          this.serviceRows[index].disc = t
        } else {
          this.serviceRows[index].disc = 0
        }
      } else {
        if (this.serviceRows[index].disc) {
          this.serviceRows[index].disc =
            Math.round((this.serviceRows[index].disc + Number.EPSILON) * 100) /
            100
          this.serviceRows[index].disc_price =
            this.serviceRows[index].priceIva +
            (this.serviceRows[index].priceIva * this.serviceRows[index].disc) /
              100
        }
      }
      if (
        this.serviceRows[index].price_max &&
        this.serviceRows[index].price_max < this.serviceRows[index].price &&
        (this.documento === 'preventivo' ||
          this.datiDocumento?.tipoDocumento?.id === 19)
      ) {
        this.serviceRows[index].taxable_max = this.serviceRows[index].price
      }
      if (
        (this.documento === 'preventivo' ||
          this.datiDocumento?.tipoDocumento?.id === 19) &&
        this.iva
      ) {
        this.serviceRows[index].price =
          this.serviceRows[index].priceIva /
          (this.serviceRows[index].VAT &&
          this.serviceRows[index].VAT.vat_percentage != null
            ? (this.serviceRows[index].VAT.vat_percentage + 100) / 100
            : this.ivaPerc)
        if (this.serviceRows[index].price_max_iva)
          this.serviceRows[index].price_max =
            this.serviceRows[index].price_max_iva / this.ivaPerc
      } else {
        if (this.serviceRows[index].newLine && this.iva === true) {
          this.serviceRows[index].price =
            this.serviceRows[index].priceIva /
            (this.serviceRows[index].VAT &&
            this.serviceRows[index].VAT.vat_percentage != null
              ? (this.serviceRows[index].VAT.vat_percentage + 100) / 100
              : this.ivaPerc)
        } else {
          this.serviceRows[index].priceIva =
            Math.round(
              ((this.serviceRows[index].price * 1 +
                this.getArrotondamentoNetto(
                  this.serviceRows[index].price * 1
                )) *
                (this.serviceRows[index].VAT &&
                this.serviceRows[index].VAT.vat_percentage != null
                  ? (this.serviceRows[index].VAT.vat_percentage + 100) / 100
                  : this.ivaPerc) +
                Number.EPSILON) *
                100000000
            ) / 100000000

          // this.serviceRows[index].priceIva += this.getArrotondamentoNetto(
          //   this.serviceRows[index].price
          // )

          this.serviceRows[index].taxable_max_iva =
            Math.round(
              (this.serviceRows[index].price_max * this.ivaPerc +
                Number.EPSILON) *
                100000000
            ) / 100000000
        }
      }
      let disc
      if (
        this.serviceRows[index].disc == 0 ||
        this.serviceRows[index].disc == ''
      ) {
        disc = 0
      } else {
        disc =
          this.serviceRows[index].qty *
          ((this.serviceRows[index].price * this.serviceRows[index].disc) / 100)
      }

      this.serviceRows[index].taxable =
        this.serviceRows[index].qty * (this.serviceRows[index].price * 1) +
        this.getArrotondamentoNetto(this.serviceRows[index].price * 1) +
        disc

      if (this.serviceRows[index].price_max) {
        this.serviceRows[index].taxable_max =
          this.serviceRows[index].qty * this.serviceRows[index].price_max + disc

        this.serviceRows[index].taxable_max_iva =
          Math.round(
            (this.serviceRows[index].qty *
              this.serviceRows[index].price_max *
              this.ivaPerc +
              Number.EPSILON) *
              100000000
          ) / 100000000
      }

      //}

      if (updateMaxPrice) {
        this.serviceRows[index].taxable =
          (this.serviceRows[index].price * 1 +
            this.getArrotondamentoNetto(this.serviceRows[index].price * 1)) *
            this.serviceRows[index].qty +
          disc

        this.serviceRows[index].taxable_max =
          this.serviceRows[index].qty * this.serviceRows[index].price_max

        this.serviceRows[index].taxable_max_iva =
          Math.round(
            (this.serviceRows[index].qty *
              this.serviceRows[index].price_max *
              this.ivaPerc +
              Number.EPSILON) *
              100000000
          ) / 100000000
      }

      this.calculateTotal()
    },
    // Calcola il totale della fattura e l'IVA
    calculateTotal() {
      if (this.ritenutaComputed !== null) {
        this.ritenutaImporto = 0
        this.serviceRows.forEach((el) => {
          this.ritenutaImporto +=
            ((el.taxable * 1) /
              (this.showCassaComputed
                ? this.cassa
                  ? (this.cassa.vat + 100) / 100
                  : 1.02
                : 1)) *
            ((this.ritenutaComputed.aliquota * 1) / 100)
        })

        this.invoiceTot.ritenuta = this.ritenutaImporto
      }
      this.calculateTotalGlobal('min')

      this.calculateTotalGlobal('max')

      let temp = { ...this.datiDocumento }

      if (
        this.documento === 'preventivo' ||
        (temp &&
          temp.tipoDocumento &&
          temp.tipoDocumento.description &&
          temp.tipoDocumento.description === 'preventivo')
      ) {
        this.invoiceTot = this.invoiceTotMax
      } else {
        this.invoiceTot = this.invoiceTotMin
      }
    },
    calculateTotalGlobal(choice) {
      if (choice === 'min') {
        this.invoiceTotMin.servTot = 0
        this.invoiceTotMin.taxableTot = 0
        this.invoiceTotMin.VATTot = 0
        this.invoiceTotMin.total = 0
        this.invoiceTotMin.toPay = 0
        this.invoiceTotMin.cassaTot = 0
      } else {
        this.invoiceTotMax.servTot = 0
        this.invoiceTotMax.taxableTot = 0
        this.invoiceTotMax.VATTot = 0
        this.invoiceTotMax.total = 0
        this.invoiceTotMax.toPay = 0
        this.invoiceTotMax.cassaTot = 0
      }

      let partialTot = 0,
        partialVAT = 0,
        partialCassa = 0
      for (let i = 0; i < this.serviceRows.length; i++) {
        if (choice === 'min' || !this.serviceRows[i].taxable_max) {
          partialTot += this.serviceRows[i].taxable
          if (this.showCassaComputed) {
            partialCassa +=
              this.serviceRows[i].taxable -
              this.serviceRows[i].taxable /
                (this.cassa && this.cassa.vat
                  ? (this.cassa.vat + 100) / 100
                  : 1.02)
          }

          partialVAT += this.serviceRows[i].VAT
            ? (this.serviceRows[i].taxable / 100) *
              this.serviceRows[i].VAT.vat_percentage
            : 0
        } else {
          partialTot += this.serviceRows[i].taxable_max
          if (this.showCassaComputed) {
            partialCassa +=
              this.serviceRows[i].taxable -
              this.serviceRows[i].taxable /
                (this.cassa && this.cassa.vat
                  ? (this.cassa.vat + 100) / 100
                  : 1.02)
          }

          partialVAT += this.serviceRows[i].VAT
            ? (this.serviceRows[i].taxable_max / 100) *
              this.serviceRows[i].VAT.vat_percentage
            : 0
        }
      }

      if (this.cassaImporto > 0) {
        let cassaVAT =
          this.invoiceTot.cassa && this.invoiceTot.cassa.aliquota
            ? (this.invoiceTot.cassa.value *
                this.invoiceTot.cassa.VAT.vat_percentage) /
              100
            : 0
        partialVAT += cassaVAT
      }

      if (choice === 'min') {
        this.invoiceTotMin.servTot = partialTot - partialCassa
        this.invoiceTotMin.cassaTot = partialCassa
        this.invoiceTotMin.VATTot = partialVAT

        this.invoiceTotMin.total =
          this.invoiceTotMin.servTot +
          this.invoiceTotMin.VATTot +
          //this.cassaImporto +
          this.invoiceTotMin.cassaTot

        this.invoiceTotMin.taxableTot =
          this.invoiceTotMin.servTot +
          //this.cassaImporto +
          this.invoiceTotMin.cassaTot

        this.invoiceTotMin.toPay =
          this.invoiceTotMin.total - this.ritenutaImporto
      } else {
        this.invoiceTotMax.servTot = partialTot - partialCassa
        this.invoiceTotMin.cassaTot = partialCassa
        this.invoiceTotMax.VATTot = partialVAT

        this.invoiceTotMax.total =
          this.invoiceTotMax.servTot +
          this.invoiceTotMax.VATTot +
          // this.cassaImporto +
          this.invoiceTotMax.cassaTot

        this.invoiceTotMax.taxableTot =
          this.invoiceTotMax.servTot +
          // this.cassaImporto +
          this.invoiceTotMax.cassaTot

        this.invoiceTotMax.toPay =
          this.invoiceTotMax.total - this.ritenutaImporto
      }
    },

    wantToSearchClient() {
      this.clientInvoice = null
    },
    dropDownSearch: function () {
      this.isActiveSearch = !this.isActiveSearch
    },
    closeDropDownSearch: function () {
      this.isActiveSearch = false
    },
    // Funzione che imposta il cliente a cui fatturare in base a quello scelto nella search drop
    onChildClick(value) {
      this.clientInvoice = value
      this.closeDropDownSearch()
    },
    // Funzione che ritorna la tariffa selezionata nella search drop e imposta i valori della riga index
    onTariffClick(value) {
      this.serviceRows[this.indexRowFromTariff] = {
        code_id: value.code_id,
        desc: value.description,
        qty: 1,
        price: value.price,
        disc: '',
        taxable: '',
        VAT: this.listVAT[0]
        // tariffario: tariffario.id_tariff,
        // idInTariffario: tariffario.tariff[index].id,
        // serviceInTariffario: tariffario.tariff[index]
      }
      this.isSearchingCod[this.indexRowFromTariff] = false
      this.isSearchingTariff[this.indexRowFromTariff] = false
      this.calculate(this.indexRowFromTariff)
      this.calculateTotal()
    },
    // Funzione richiamata al momento del drag
    startDrag(index) {
      this.idDrag = index
      this.showRowSettings[index] = false
    },
    // Funzione richiamata al momento del drop dove sposto gli elementi degli array
    onDrop(list, index) {
      if (this.idDrag < index) {
        list.splice(index + 1, -1, list[this.idDrag])
        list.splice(this.idDrag, 1)
        this.checksRitenuta.splice(
          index + 1,
          -1,
          this.checksRitenuta[this.idDrag]
        )
        this.checksRitenuta.splice(this.idDrag, 1)
        this.checksCassa.splice(index + 1, -1, this.checksCassa[this.idDrag])
        this.checksCassa.splice(this.idDrag, 1)
      } else {
        list.splice(index, -1, list[this.idDrag])
        list.splice(this.idDrag + 1, 1)
        this.checksRitenuta.splice(index, -1, this.checksRitenuta[this.idDrag])
        this.checksRitenuta.splice(this.idDrag + 1, 1)
        this.checksCassa.splice(index, -1, this.checksCassa[this.idDrag])
        this.checksCassa.splice(this.idDrag + 1, 1)
      }
    },
    changeRowSettings(index) {
      this.showRowSettings[index] = !this.showRowSettings[index]
    },
    changeShowSearchTariff(index) {
      this.isSearchingTariff[index] = !this.isSearchingTariff[index]
    },
    changeShowSearchCod(index) {
      this.isSearchingCod[index] = !this.isSearchingCod[index]
    },
    searchServiceFromTariffs(index) {
      this.indexRowFromTariff = index
      this.isSearchingTariff[index] = true
    },
    searchCodFromTariffs(index) {
      this.indexRowFromTariff = index
      this.isSearchingCod[index] = true
    },
    // Funzione che ritorna le scelte selezionabili come tipo documento
    getDocumentType() {
      if (this.onlyAcconto) {
        this.documentTypes.map((elem) => {
          if (elem.code === 'TD02') {
            this.datiDocumento.tipoDocumento = elem
          }
        })
      } else if (this.notaCredito) {
        this.documentTypes.map((elem) => {
          if (elem.code === 'TD04') {
            this.datiDocumento.tipoDocumento = elem
          }
        })
      } else if (this.notaDebito) {
        this.documentTypes.map((elem) => {
          if (elem.code === 'TD05') {
            this.datiDocumento.tipoDocumento = elem
          }
        })
      } else {
        if (this.readOnly && Object.keys(this.readOnlyElem).length) {
          for (let i = 0; i < this.documentTypes.length; i++) {
            if (this.readOnlyElem.bill_type_id === this.documentTypes[i].id) {
              this.datiDocumento.tipoDocumento = this.documentTypes[i]
            }
          }
        } else {
          if (!_.isEmpty(this.documento)) {
            for (let i = 0; i < this.documentTypes.length; i++) {
              if (
                this.documentTypes[i].description
                  .toLowerCase()
                  .includes(this.documento.toLowerCase())
              ) {
                this.datiDocumento.tipoDocumento = this.documentTypes[i]
              }
            }
          } else {
            for (let i = 0; i < this.documentTypes.length; i++) {
              if (this.documentTypes[i].description === 'fattura') {
                this.datiDocumento.tipoDocumento = this.documentTypes[i]
              }
            }
          }
        }
      }
    },
    getStaff() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff', {
          roles: [3, 2]
        })
        .then((res) => {
          this.vets = res.data.map((el) => ({
            id: el.id,
            full_name: el.full_name
          }))
        })
    },

    // Ritorna i dati inseriti nel modale relativo al bollo
    addBolloFromModal(value) {
      this.bolloImporto = value.value
      this.invoiceTot.bollo = value
      this.showModalBollo = false
    },
    // Ritorna i dati inseriti nel modale relativo al bollo
    addCassaFromModal(value, checksCassa) {
      this.checksCassa = checksCassa
      this.showModalCassa = false
      this.cassaImporto = value.value
      this.invoiceTot.cassa = value
      this.calculateTotal()
    },
    // Ritorna i dati inseriti nel modale relativo al bollo
    addRitenutaFromModal(value, checksRitenuta, active) {
      this.ritenutaChecked = active
      if (!active) {
        this.ritenutaFattura = null
        this.showModalRitenuta = false
        this.ritenutaImporto = 0
        this.invoiceTot.ritenuta = null
      } else {
        this.ritenutaFattura = value
        this.checksRitenuta = checksRitenuta
        this.showModalRitenuta = false
        this.ritenutaImporto = value.value
        this.invoiceTot.ritenuta = value
      }
      this.calculateTotal()
    },
    cassaEdit() {
      if (this.cassaFattura === null)
        if (this.cassa) this.cassaFattura = true
        else this.cassaFattura = false
      if (this.cassaImporto == 0) {
        let cassaNew = {
          value: 0,
          type: '',
          aliquota: '',
          onPerc: 100
        }
        this.invoiceTot.cassa = cassaNew
      }
      this.showModalCassa = true
    },
    bolloEdit() {
      if (this.bolloImporto == 0) {
        let bolloNew = {
          id: '',
          value: 0
        }
        this.invoiceTot.bollo = bolloNew
      }
      this.showModalBollo = true
    },
    ritenutaEdit() {
      if (this.ritenutaImporto == 0) {
        let ritenutaNew = {
          value: 0,
          type: '',
          aliquota: '',
          onPerc: 100,
          causale: ''
        }
        this.invoiceTot.ritenuta = ritenutaNew
      }
      this.showModalRitenuta = true
    },
    editNote(valueNote, valueCheck) {
      this.showNoteModal = false
      this.checkNote = valueCheck
      this.datiNotaPiePagina = valueNote
    },
    changeActiveScMagFromModal(value) {
      this.activeScMag = value
      this.showScontoMaggiorazione = false
    },
    changeCausale(value) {
      this.datiCausale.text = value.text
      this.datiCausale.check = value.check
      this.showCausale = false
    },
    // Stampa in console gli elementi che dovranno essere inseriti in fattura
    stampaRiepilogo(bozza) {
      // if (this.fatturaPreventivo) {
      //   this.moveToFatturaFunc()
      //   return
      // }

      if (
        this.invoiceTot &&
        this.invoiceTot.total &&
        this.invoiceTot.total < 0
      ) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message:
            'Il totale della fattura non può essere un valore negativo. Si prega di controllare.'
        })
        return
      }

      if (
        !bozza &&
        this.datiDocumento.tipoDocumento.code !== 'TDP' &&
        !this.notaDebito &&
        this.datiDocumento.tipoDocumento.code !== 'TD05'
      ) {
        if (this.paymentType === '' || this.paymentType === null) {
          this.openMessageModal(
            'Metodo pagamento obbligatorio',
            'Si prega di inserire un metodo di pagamento'
          )
          return
        }
      }

      if (this.serviceRows.length === 0) {
        this.openMessageModal(
          'Nessuna prestazione inserita',
          'Si prega di inserire almeno una prestazione'
        )
        return
      }

      if (
        !bozza &&
        (!this.modify ||
          (this.modify &&
            this.readOnlyElem &&
            this.readOnlyElem.status_doc === 'bozza')) &&
        this.prefissoSezionale &&
        this.prefisso === null
      ) {
        this.openMessageModal(
          'Dato mancante',
          'Si prega di selezionare il suffisso del sezionale'
        )
        return
      }

      let temp = {}
      for (let i = 0; i < this.serviceRows.length; i++) {
        if (!this.serviceRows[i].desc || this.serviceRows[i].desc === '') {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Si prega di inserire tutte le descrizioni'
          })
          return
        }

        if (
          !this.serviceRows[i].price ||
          this.serviceRows[i].price === '' ||
          this.serviceRows[i].price === 0 ||
          !this.serviceRows[i].qty ||
          this.serviceRows[i].qty === '' ||
          this.serviceRows[i].qty === 0
        ) {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Si prega di inserire tutti i prezzi correttamente'
          })
          return
        }

        // if (
        //   this.serviceRows[i].qty === '' ||
        //   this.serviceRows[i].price === '' ||
        //   this.serviceRows[i].desc === ''
        // ) {
        //   this.openMessageModal(
        //     'Dati incompleti',
        //     'Si prega di controllare i dati delle prestazioni e inserire tutti i dati'
        //   )
        //   return
        // }
        this.serviceRows[i].lineRow = i + 1
        if (this.checksRitenuta[i]) {
          this.serviceRows[i].ritenuta = {
            type: this.invoiceTot.ritenuta.type,
            aliquota: this.invoiceTot.ritenuta.aliquota,
            onPerc: this.invoiceTot.ritenuta.onPerc,
            causale: this.invoiceTot.ritenuta.causale
          }
        }
        if (this.checksCassa[i]) {
          this.serviceRows[i].cassa = {
            type: this.invoiceTot.cassa.type,
            aliquota: this.invoiceTot.cassa.aliquota,
            onPerc: this.invoiceTot.cassa.onPerc,
            VAT: this.invoiceTot.cassa.VAT
          }
        }
        // Controllo se la riga proviene da una scelta dal tariffario
        if (this.serviceRows[i].serviceInTariffario != null) {
          if (
            this.serviceRows[i].code_id ==
              this.serviceRows[i].serviceInTariffario.code_id &&
            this.serviceRows[i].desc ==
              this.serviceRows[i].serviceInTariffario.description &&
            this.serviceRows[i].price ==
              this.serviceRows[i].serviceInTariffario.price &&
            this.serviceRows[i].VAT.id ==
              this.serviceRows[i].serviceInTariffario.vat.id
          ) {
            temp['row_' + i] = {
              ritenuta: this.serviceRows[i].ritenuta,
              cassa: this.serviceRows[i].cassa,
              tariffario: this.serviceRows[i].tariffario,
              idInTariffario: this.serviceRows[i].idInTariffario,
              qty: this.serviceRows[i].qty,
              disc: this.serviceRows[i].disc
            }
          } else {
            // Devo controllare quali info sono cambiate e inserire solo quelle oltrechè il tariffario e la tariffa
            temp['row_' + i] = {
              ritenuta: this.serviceRows[i].ritenuta,
              cassa: this.serviceRows[i].cassa,
              tariffario: this.serviceRows[i].tariffario,
              idTariffaNelTariffarioc: this.serviceRows[i].idInTariffario,
              qty: this.serviceRows[i].qty,
              disc: this.serviceRows[i].disc
            }
            // Eseguo i controlli campo per campo per capire quali sono cambiati
            if (
              this.serviceRows[i].code_id !=
              this.serviceRows[i].serviceInTariffario.code_id
            ) {
              temp['row_' + i].code_id = this.serviceRows[i].code_id
            }
            if (
              this.serviceRows[i].tariffario_alias_id !=
              this.serviceRows[i].serviceInTariffario.tariffario_alias_id
            ) {
              temp['row_' + i].tariffario_alias_id =
                this.serviceRows[i].tariffario_alias_id
            }
            if (
              this.serviceRows[i].desc !=
              this.serviceRows[i].serviceInTariffario.description
            ) {
              temp['row_' + i].desc = this.serviceRows[i].desc
            }
            if (
              this.serviceRows[i].price !=
              this.serviceRows[i].serviceInTariffario.price
            ) {
              temp['row_' + i].price = this.serviceRows[i].price
            }
            if (
              this.serviceRows[i].VAT.id !=
              this.serviceRows[i].serviceInTariffario.vat.id
            ) {
              temp['row_' + i].DatiBollo.VAT = {
                id: this.serviceRows[i].VAT.id
              }
            }
            if (
              this.serviceRows[i].taxable_max &&
              Math.round(
                (this.serviceRows[i].taxable_max + Number.EPSILON) * 100
              ) /
                100 >
                Math.round(
                  (this.serviceRows[i].taxable + Number.EPSILON) * 100
                ) /
                  100
            ) {
              temp['row_' + i].price_max = this.serviceRows[i].price_max
              temp['row_' + i].taxable_max = this.serviceRows[i].taxable_max
              temp['row_' + i]['range'] = true
            }
          }
        } else {
          // Se la riga non proviene da alcun tariffario la riporto così com'è
          temp['row_' + i] = {
            ...this.serviceRows[i],
            DatiCassa: {
              VAT: { xml_id: this.serviceRows[i].VAT.id },
              type: { xml_id: this.serviceRows[i].VAT.id },
              aliquota: this.serviceRows[i].VAT.vat_percentage
            },
            sconto: {
              type: 'percentuale',
              value: this.serviceRows[i].disc
            }
          }

          if (
            this.serviceRows[i].price_path &&
            this.permissions &&
            this.permissions?.fatturazione?.create_empty_row_billing
              ?.enabled === false
          ) {
            temp['row_' + i]['desc'] = null
            temp['row_' + i]['price'] = null
            temp['row_' + i]['taxable'] = null
            temp['row_' + i]['code_id'] = null
          }

          if (
            this.serviceRows[i].taxable_max &&
            Math.round(
              (this.serviceRows[i].taxable_max + Number.EPSILON) * 100
            ) /
              100 >
              Math.round((this.serviceRows[i].taxable + Number.EPSILON) * 100) /
                100
          ) {
            temp['row_' + i].price_max = this.serviceRows[i].price_max
            temp['row_' + i].taxable_max = this.serviceRows[i].taxable_max
            temp['row_' + i]['range'] = true
          }
        }
        // if (this.modify) {
        //   temp['row_' + i].id = this.readOnlyElem.bill_rows[i].id
        // }

        if (
          this.permissions &&
          this.permissions?.fatturazione?.create_empty_row_billing?.enabled ===
            true &&
          !this.serviceRows[i].newLine
        ) {
          if (
            this.serviceRows[i].tariffario_alias_id &&
            this.serviceRows[i].prestazione_info
          ) {
            if (
              this.serviceRows[i].desc !==
                this.serviceRows[i].prestazione_info.desc ||
              this.serviceRows[i].code_id !==
                this.serviceRows[i].prestazione_info.code_id
            )
              temp['row_' + i].tariffario_alias_id = null
          }
          if (
            this.serviceRows[i].tariffario_packages_id &&
            this.serviceRows[i].pkg_info
          ) {
            if (
              this.serviceRows[i].desc !== this.serviceRows[i].pkg_info.alias ||
              (this.serviceRows[i].price !==
                this.serviceRows[i].pkg_info.price &&
                this.serviceRows[i].pkg_info.greatest &&
                this.serviceRows[i].pkg_info.greatest !== 0 &&
                this.serviceRows[i].pkg_info.greatest !== '0' &&
                this.serviceRows[i].pkg_info.discount &&
                this.serviceRows[i].pkg_info.discount !== 0 &&
                this.serviceRows[i].pkg_info.discount !== '0')
            )
              temp['row_' + i].tariffario_packages_id = null
          }
        }

        temp['row_' + i]['pkg_info'] = null
        temp['row_' + i]['prestazione_info'] = null
      }
      this.datiDocumento.data = this.$FormatEpochMin(this.today)
      if (this.fatturaPreventivo) {
        this.datiDocumento['moved'] = 'preventivo_fatturato'
        this.datiDocumento['preventivo_id'] = this.readOnlyElem.id
      }

      if (
        this.prefissoSezionale &&
        (!this.modify ||
          (this.modify &&
            this.readOnlyElem &&
            this.readOnlyElem.status_doc === 'bozza'))
      ) {
        this.datiDocumento['suffix_id'] = this.prefisso
      }

      if (this.notaCredito) {
        this.datiDocumento['from_active_bill'] = this.readOnlyElem.id
      }

      // if (this.data_saldo) {
      //   this.datiDocumento['data_saldo'] = this.data_saldo
      // }

      let fatturaElettronica = {
        DatiDocumento: this.datiDocumento,
        DatiCliente: { id: this.clientInvoice.id },
        Righe: temp,
        DatiRitenuta: this.invoiceTot.ritenuta,
        DatiCassa: this.invoiceTot.cassa,
        Causale: this.datiCausale,
        DatiPagamento: { setup_id: this.paymentType.id },
        DatiNotaPiePagina: {
          ...this.datiNotaPiePagina,
          setup_id: this.datiNotaPiePagina ? this.datiNotaPiePagina?.id : null
        },
        create_account_row: true
      }
      if (bozza) {
        fatturaElettronica.DatiDocumento.status_doc = bozza
      } else {
        // if (this.modify) {
        //   fatturaElettronica.DatiDocumento.status_doc =
        //     this.readOnlyElem?.status_doc ?? null
        // }
      }
      if (this.invoiceTot.bollo) {
        fatturaElettronica.DatiBollo = {
          setup_id: this.invoiceTot.bollo.id
        }
      }

      if (
        (this.services &&
          this.services.length > 0 &&
          this.fatturaPreventivo === false) ||
        ((this.documento === 'preventivo' ||
          this.datiDocumento?.tipoDocumento?.id === 19) &&
          this.fatturaPreventivo === false) ||
        this.notaCredito === true
      ) {
        fatturaElettronica.create_account_row = false
      }

      if (this.modify) {
        fatturaElettronica.id = this.readOnlyElem.id
      }

      if (this.cupCode) {
        fatturaElettronica.cupCode = this.cupCode
      }

      if (this.cigCode) {
        fatturaElettronica.cigCode = this.cigCode
      }

      if (this.data_saldo) {
        fatturaElettronica.data_saldo = this.data_saldo
      }

      if (this.data_preventivo) {
        fatturaElettronica.data_preventivo = this.data_preventivo
      }

      this.$store.dispatch('commonComponents/showLoadingModalAction')

      if (this.modify === true) {
        this.editBillFunc(fatturaElettronica)
        return
      }

      if (this.onlyAcconto === true) {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/account/' + this.client.id,
            this.services[0]
          )
          .then((res) => {
            fatturaElettronica['Righe']['row_0']['desc'] = 'Acconto'
            fatturaElettronica['Righe']['row_0']['id_account'] = res.data.id
            this.storeBillFunc(fatturaElettronica)
          })
      } else {
        this.storeBillFunc(fatturaElettronica)
      }

      var account_ids = []
      this.serviceRows.forEach((service) => {
        account_ids.push(service.id_account)
      })
      // if (!this.notaDebito) {
      //   axios.post('/' + localStorage.getItem('clinic') + '/account/edit', {
      //     account_ids: account_ids,
      //     status: 'completed'
      //   })
      // }

      this.blockSendButtons = false
      setTimeout(() => {
        this.blockSendButtons = false
      }, '5000')
    },
    isNewInvoice() {
      return (
        (!this.modify ||
          (this.modify &&
            this.readOnlyElem &&
            this.readOnlyElem.status_doc === 'bozza')) &&
        (!this.fatturaPreventivo ||
          (this.fatturaPreventivo &&
            this.readOnlyElem &&
            this.readOnlyElem.status_doc === 'bozza'))
      )
    },
    isPreventivo() {
      return (
        this.documento == 'preventivo' ||
        this.datiDocumento?.tipoDocumento?.id === 19
      )
    },
    storeBillFunc(fatturaElettronica) {
      let bill_setup = {}
      if (
        this.documento === 'preventivo' ||
        this.datiDocumento?.tipoDocumento?.id === 19
      ) {
        bill_setup.cassa = false
      } else {
        if (this.cassaFattura !== null) bill_setup.cassa = this.cassaFattura
        else if (this.cassa) bill_setup.cassa = true
        else bill_setup.cassa = false
      }

      if (this.scontoPercentuale) {
        bill_setup.percentage_discount = this.scontoPercentuale
      }

      if (this.ritenutaComputed)
        bill_setup.ritenuta = {
          ritenutaTipo: this.ritenutaComputed.type.code,
          ritenutaPerc: this.ritenutaComputed.aliquota,
          ritenutaCaus: this.ritenutaComputed.causale.code,
          ritenutaDescription: this.ritenutaComputed.type.description
        }

      fatturaElettronica.bill_setup = bill_setup
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/storeBill',
          fatturaElettronica
        )
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          if (
            typeof this.documento != 'undefined' &&
            this.documento == 'preventivo'
          ) {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Preventivo creato con successo'
            })
          } else {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Fattura creata con successo'
            })

            this.$emit('responseModal', 'Successo', res)
          }

          this.closeModal()
        })
        .catch((err) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$emit('responseModal', 'Errore', err)
        })
    },
    editBillFunc(fatturaElettronica) {
      let bill_setup = {}
      if (
        this.documento === 'preventivo' ||
        this.datiDocumento?.tipoDocumento?.id === 19
      ) {
        bill_setup.cassa = false
      } else {
        if (this.showCassaComputed === true)
          bill_setup.cassa = this.showCassaComputed
        else if (this.cassaFattura !== null)
          bill_setup.cassa = this.cassaFattura
        else if (this.cassa) bill_setup.cassa = true
        else bill_setup.cassa = false
      }

      if (this.scontoPercentuale) {
        bill_setup.percentage_discount = this.scontoPercentuale
      }

      if (this.ritenutaComputed)
        bill_setup.ritenuta = {
          ritenutaTipo: this.ritenutaComputed.type.code,
          ritenutaPerc: this.ritenutaComputed.aliquota,
          ritenutaCaus: this.ritenutaComputed.causale.code,
          ritenutaDescription: this.ritenutaComputed.type.description
        }

      fatturaElettronica.bill_setup = bill_setup
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/editBill',
          fatturaElettronica
        )
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          if (
            this.documento === 'preventivo' ||
            this.datiDocumento?.tipoDocumento?.id === 19
          ) {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Preventivo modificato con successo'
            })
          } else {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Fattura modificato con successo'
            })
            this.$emit('responseModal', 'Successo', res)
          }
          this.$emit('refreshTables')
          this.closeModal()
        })
        .catch((err) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$emit('responseModal', 'Errore', err)
        })
    },
    moveToFatturaFunc() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/bills/' +
            this.readOnlyElem.id +
            '/moveToContoAperto',
          { moved: 'preventivo_fatturato' }
        )
        .then(() => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Fattura creata con successo'
          })
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$emit('close')
          this.$emit('refreshTables')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore!'
          })
        })
    },
    openMessageModal(title, msg) {
      this.$store.dispatch('commonComponents/openMessageModal', {
        title: title,
        subtitle: msg
      })
    },
    handleCassaFatturaSave(value) {
      this.cassaFattura = value
      this.calculateTotal()
    },
    getArrotondamento(netto, lordo) {
      return lordo / this.ivaPerc - netto
    },
    getArrotondamentoNetto(netto, VAT) {
      let lordo_arrotondato =
        Math.round(
          (netto *
            (VAT && VAT.vat_percentage != null
              ? (VAT.vat_percentage + 100) / 100
              : this.ivaPerc) +
            Number.EPSILON) *
            100
        ) / 100
      return (
        lordo_arrotondato /
          (VAT && VAT.vat_percentage != null
            ? (VAT.vat_percentage + 100) / 100
            : this.ivaPerc) -
        netto
      )
    }
  }
}
</script>
<style>
[role='combobox'] {
  --vs-border-width: 2px;
  --vs-bg: rgba(222, 223, 224, 0.942);
  --vs-border-style: solid;
  height: 2rem;
}
.vs__selected {
  --vs-selected-bg: rgba(255, 142, 28, 0.195);
  --vs-selected-border-width: 0.5px;
  --vs-selected-border-style: solid;
  --vs-selected-border-color: #ff8e1c;
  height: 1.5rem;
  padding-left: 10px;
}
.vs__selected-options {
  align-content: center;
}
</style>
