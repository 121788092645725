<template>
  <!-- <button class="btn" @click="$emit('openTest', petDetails)">
    Interfaccia Test
  </button> -->
  <DetailsModal
    v-if="detailsModal && templateSchede"
    :id-template="
      specialisticSelected
        ? specialisticSelected.id
        : schedaPage
        ? templateSchede[0].id
        : templateReferti[0].id
    "
    :title="
      specialisticSelected
        ? specialisticSelected.document_type
        : schedaPage
        ? templateSchede[0].document_type
        : templateReferti[0].document_type
    "
    :modify-scheda-clinica="modifySchedaClinica"
    :new="true"
    :pet_id="pet.id"
    :medical-templates-id="medicalTemplatesID"
    :configuration="
      specialisticSelected
        ? specialisticSelected
        : schedaPage
        ? templateSchede[0]
        : templateReferti[0]
    "
    @close="closeDetailsModal"
    @refresh="refreshTable()"
  />

  <NewPet
    v-if="showModalEditPet"
    ref="newPet"
    :title="'Modifica paziente'"
    :pet="pet"
    @close="showModalEditPet = false"
    @edit_close="editPet()"
    @referente="openReferente"
  />
  <!-- Modale aggiunta nuovo referente esterno -->
  <div v-if="newReferent" class="w-100">
    <NewReferente :title="'referente'" @close="closeNewReferent" />
  </div>
  <!-- Fine modale aggiunta nuovo referente esterno -->
  <NotificationComponent />
  <div class="">
    <!--    BEGIN: Top section customer tab-->
    <div class="grid grid-cols-8 gap-2 p-3">
      <!--      BEGIN: Custumer infos-->
      <div
        class="col-span-8 md:col-span-4 lg:col-span-3 p-2 pb-3 rounded-lg shadow-md bg-white"
      >
        <div class="flex justify-center bg-theme-45 rounded-md py-2">
          <h1 class="text-xl mr-2 nunito text-theme-40 font-bold">Pet</h1>
        </div>

        <div class="flex flex-col p-2">
          <div class="flex flex-col gap-2 items-center text-base mt-1 mx-1">
            <div class="flex mt-1">
              <h2 class="text-center nunito text-xl xl:text-2xl mr-5 uppercase">
                {{ pet.name }}
              </h2>
            </div>
          </div>
          <!--  <div>
              <i class="fas fa-hashtag mr-1" />
              <span class="font-bold">ID: </span>
              <span class="font-normal">{{ pet.id }}</span>
            </div> -->

          <div
            class="flex flex-col gap-2 text-left justify-center mt-2 ml-3 sm:ml-10 md:ml-1 lg:ml-4 pb-2"
          >
            <div class="flex items-center">
              <div
                class=""
                :class="[
                  pet.owners && pet.owners.length && pet.owners.length > 1
                    ? 'flex flex-col'
                    : 'flex items-center'
                ]"
              >
                <div class="flex items-center gap-1">
                  <button
                    class="text-center hover:text-theme-41"
                    @click="openSelectClient"
                  >
                    <svg
                      id="icon-adduser"
                      viewBox="0 0 28 32"
                      class="w-5 fill-current"
                    >
                      <path
                        d="M14.976 21.902h1.554v-1.554c0-0.513 0.131-1.020 0.378-1.467 0.011-0.021 0.026-0.046 0.041-0.070 0.030-0.051 0.058-0.097 0.087-0.142 0.386-0.581 0.957-1.008 1.618-1.215l0.070-0.036 0.149-0.025c0.102-0.023 0.204-0.043 0.303-0.057 0.129-0.018 0.263-0.027 0.392-0.027 0.093 0 0.189 0.004 0.294 0.015 0.023 0.002 0.040 0.004 0.057 0.007 0.072 0.009 0.15 0.020 0.225 0.035 0.026 0.006 0.050 0.010 0.075 0.016s0.049 0.011 0.075 0.018c-1.412-1.495-3.373-2.556-5.611-2.892-0.225-0.035-6.536-0.035-6.761 0-4.479 0.673-7.844 4.241-7.923 8.399-0.046 2.404 0.964 3.449 3.373 3.455 2.684 0.007 5.366 0.006 8.050 0.003h0.749l-0.16-0.754c-0.007-0.030-0.012-0.060-0.018-0.091l-0.008-0.041c-0.011-0.068-0.028-0.169-0.037-0.285v-0.016c-0.006-0.054-0.013-0.137-0.013-0.234 0-1.676 1.364-3.040 3.040-3.040z"
                      ></path>
                      <path
                        d="M25.792 24.016h-1.578s0 0 0 0h-1.318v-2.897c0-0.053-0.003-0.105-0.009-0.158-0.001-0.012-0.002-0.025-0.004-0.037-0.001-0.012-0.002-0.025-0.004-0.037 0-0.001-0.001-0.002-0.002-0.003-0.053-0.375-0.225-0.71-0.478-0.965-0.066-0.066-0.139-0.125-0.215-0.179 0 0 0 0 0 0s-0.002-0.001-0.003-0.002c-0.028-0.020-0.056-0.038-0.085-0.056-0.013-0.008-0.027-0.017-0.040-0.025-0.026-0.015-0.051-0.030-0.078-0.044-0.021-0.011-0.044-0.021-0.066-0.031-0.021-0.010-0.041-0.020-0.063-0.029-0.045-0.019-0.090-0.035-0.135-0.049-0.012-0.004-0.026-0.007-0.038-0.011-0.038-0.011-0.077-0.021-0.116-0.030-0.013-0.003-0.027-0.006-0.040-0.008-0.043-0.008-0.085-0.015-0.129-0.020-0.009 0-0.018-0.002-0.028-0.003-0.054-0.006-0.109-0.009-0.165-0.009-0.072 0-0.141 0.006-0.21 0.016-0.073 0.010-0.143 0.025-0.214 0.043 0 0 0 0 0 0s0 0 0 0c-0.411 0.104-0.762 0.36-0.99 0.705-0.019 0.028-0.036 0.058-0.053 0.087-0.006 0.010-0.012 0.020-0.018 0.030-0.134 0.242-0.21 0.52-0.21 0.817v2.896h-2.897c-0.938 0-1.697 0.759-1.697 1.697 0 0.050 0.006 0.098 0.009 0.148 0.004 0.051 0.011 0.102 0.020 0.152 0 0.006 0.002 0.011 0.003 0.017 0.004 0.025 0.009 0.048 0.013 0.072 0 0 0.002 0 0.003 0 0.176 0.749 0.845 1.306 1.647 1.306h2.897v2.897c0 0.468 0.191 0.893 0.496 1.201 0.308 0.308 0.731 0.496 1.201 0.496 0.937 0 1.697-0.76 1.697-1.697v-2.897h2.897c0.934 0 1.694-0.76 1.694-1.694s-0.76-1.697-1.694-1.697z"
                      ></path>
                      <path
                        d="M18.186 6.94c0 3.833-3.107 6.94-6.94 6.94s-6.94-3.107-6.94-6.94c0-3.833 3.107-6.94 6.94-6.94s6.94 3.107 6.94 6.94z"
                      ></path>
                    </svg>
                  </button>

                  <span class="font-bold text-base md:text-sm xl:text-base">
                    {{
                      pet.owners && pet.owners.length && pet.owners.length > 1
                        ? 'Proprietari:'
                        : 'Proprietario:'
                    }}
                  </span>
                </div>
                <div
                  :class="[
                    pet.owners && pet.owners.length && pet.owners.length > 1
                      ? 'ml-10'
                      : 'ml-0'
                  ]"
                >
                  <div
                    v-if="pet.owners && pet.owners.length"
                    class="text-sm md:text-xs xl:text-sm font-normal flex flex-col items-start gap-1"
                  >
                    <div
                      v-for="(owner, index) in pet.owners"
                      :key="index"
                      class="text-sm md:text-xs xl:text-sm font-normal flex items-center gap-1"
                    >
                      <div @click="handleGoToCustomer(owner)">
                        <ChevronsRightIcon
                          class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                        />
                      </div>
                      <span class="capitalize">
                        {{ owner.last_name }}
                        {{ owner.first_name }}
                        {{ owner.ragione_sociale }}
                      </span>
                      <a
                        v-if="
                          pet.owners &&
                          pet.owners.length &&
                          pet.owners.length > 1
                        "
                        class="hover:text-theme-41 cursor-pointer"
                        @click="addPetOwner(owner, 'remove')"
                      >
                        <i class="fas fa-times text-xs" />
                      </a>
                    </div>
                  </div>

                  <span v-else> -- </span>
                </div>
              </div>
            </div>
            <div class="flex items-center">
              <i
                class="fas fa-user-md text-base md:text-sm xl:text-xl text-center w-5 mr-1"
              />
              <span class="font-bold text-base md:text-sm xl:text-base mr-2"
                >Referente:
              </span>
              <div
                v-if="
                  pet.externals &&
                  (pet.externals.first_name || pet.externals.last_name)
                "
                class="text-sm md:text-xs xl:text-sm font-normal flex items-center gap-1"
              >
                <span class="uppercase">
                  {{ pet.externals.first_name ? pet.externals.first_name : '' }}
                  {{ pet.externals.last_name ? pet.externals.last_name : '' }}
                </span>
              </div>
              <span v-else> -- </span>
            </div>
            <div class="flex items-center">
              <i
                class="fas fa-search text-base md:text-sm xl:text-xl text-center w-5 mr-1"
              />
              <div class="flex flex-col text-sm">
                <div class="flex">
                  <span class="font-bold  mr-2"
                  >Id paziente:
                  </span>
                  <span class="  mr-2"
                  >{{ pet.id }}
                  </span>
                </div>
                <div v-if="external_id" class="flex">
                  <span class="font-bold  mr-2"
                  >Id esterno:
                  </span>
                  <span class="   mr-2"
                  >{{ external_id }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center gap-2 mt-3">
            <div
              class="text-center rounded-xl text-xs lg:text-sm bg-theme-2 shadow px-4 w-28 items-center py-1 h-7 flex mx-1 justify-center"
            >
              <i
                class="fas fa-stethoscope text-sm mr-2"
                :class="[
                  pet.sterilized
                    ? 'text-theme-41 fill-current'
                    : 'text-theme-44 fill-current'
                ]"
              ></i>

              <div class="text-theme-43 text-sm md:text-xs xl:text-sm">
                Sterilizzato
              </div>
            </div>

            <div
              class="text-center rounded-xl text-xs lg:text-sm bg-theme-2 shadow px-4 w-28 items-center py-1 h-7 flex mx-1 justify-center"
            >
              <i
                class="fas fa-heart text-sm mr-2"
                :class="[
                  pet.donor
                    ? 'text-theme-41 fill-current'
                    : 'text-theme-44 fill-current'
                ]"
              ></i>

              <div class="text-theme-43 text-sm md:text-xs xl:text-sm">
                Donatore
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      END: Customer infos-->
      <!--      BEGIN: Customer details-->
      <div
        class="col-span-8 md:col-span-4 lg:col-span-3 p-2 rounded-lg shadow-md bg-white"
      >
        <div class="flex justify-center bg-theme-45 rounded-md py-2">
          <h1 class="text-xl mr-2 nunito text-theme-40 font-bold">
            Dettagli Pet
          </h1>
        </div>
        <div
          class="flex flex-col gap-2 text-left justify-center mt-4 ml-6 sm:ml-12 md:ml-2 lg:ml-8 pb-2"
        >
          <div class="flex items-center">
            <i class="fas fa-paw text-base xl:text-lg text-center w-6 mr-3" />
            <div class="text-left">
              <span class="font-bold text-sm xl:text-base">Specie: </span>
              <span class="text-xs xl:text-sm font-normal ml-1">
                {{ pet.type }}, {{ pet.breed }}
              </span>
            </div>
          </div>
          <div class="flex items-center">
            <i class="fas fa-venus-mars text-lg text-center w-6 mr-3" />
            <div class="text-left">
              <span class="font-bold text-sm xl:text-base">Sesso: </span>
              <span class="text-xs xl:text-sm font-normal ml-1">
                {{ pet.gender }}
              </span>
            </div>
          </div>

          <div v-if="pet.weight" class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 113.66 114.16"
              class="h-5 w-5 text-center mr-3 ml-1 fill-current"
            >
              <g data-name="Layer 2">
                <g data-name="Layer 1">
                  <path
                    d="M56.82 114.16H16.09c-10.24 0-16.08-5.8-16.08-16Q0 64.27 0 30.37c0-10.2 5.86-16.22 16.1-15.91 6.38.19 11.37-1 16.67-5.57C48-4.32 70.81-2.46 84.87 12.17a6.67 6.67 0 0 0 5.39 2.26 76.75 76.75 0 0 1 12.42.57c5.94 1 10.3 6.17 10.86 12.18.1 1.08.11 2.18.11 3.26v67.49c0 10.64-5.66 16.23-16.4 16.23Zm.09-46.26a28.62 28.62 0 1 0 .2-57.23C41.09 10.66 28 23.6 28 39.33A28.86 28.86 0 0 0 56.91 67.9Z"
                  />
                  <path
                    d="M65.47 49.11c0 5.43-3.53 9.26-8 9.7-4 .39-8.14-2.87-9-7a8.34 8.34 0 0 1 5.89-9.94 5.59 5.59 0 0 0 4.21-3.91c1.64-4.24 3.49-8.39 5.28-12.56.91-2.13 1.82-4.46 4.85-3.48 2.31.76 2.7 2.83 1.34 6-1.9 4.45-3.76 8.92-5.76 13.32-.75 1.64-1.06 3 .15 4.6a9.52 9.52 0 0 1 1.04 3.27Z"
                  />
                </g>
              </g>
            </svg>
            <div class="text-left">
              <span class="font-bold text-sm xl:text-base">Peso: </span>
              <span class="text-xs xl:text-sm font-normal ml-1">
                {{ pet.weight }}
              </span>
              <span
                v-if="pet.weight === 1"
                class="text-xs xl:text-sm font-normal ml-1"
              >
                kg</span
              >
              <span v-else class="text-xs xl:text-sm font-normal ml-1">
                kgs</span
              >
            </div>
          </div>

          <div class="flex items-center">
            <i class="fas fa-birthday-cake text-lg text-center w-6 mr-3" />
            <div class="text-left">
              <span class="font-bold text-sm xl:text-base">Età: </span>
              <template v-if="pet.pet_info.birth_date">
                <span class="text-xs xl:text-sm font-normal ml-1">
                  {{ $getDifferenceYears(pet.pet_info.birth_date) }}
                </span>
                <span
                  v-if="$getDifferenceYears(pet.pet_info.birth_date) === 1"
                  class="ttext-xs xl:text-sm font-normal"
                >
                  anno</span
                >
                <span v-else class="text-xs xl:text-sm font-normal ml-1">
                  anni</span
                >

                <span class="text-xs xl:text-sm font-normal ml-1">
                  {{ $getDifferenceMonths(pet.pet_info.birth_date) % 12 }}
                </span>
                <span
                  v-if="$getDifferenceMonths(pet.pet_info.birth_date) === 1"
                  class="ttext-xs xl:text-sm font-normal"
                >
                  mese</span
                >
                <span v-else class="text-xs xl:text-sm font-normal ml-1">
                  mesi</span
                >
              </template>
            </div>
          </div>

          <div v-if="pet.microchip" class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 109.72 109.77"
              class="h-5 w-5 text-center mr-3 ml-1 fill-current"
            >
              <g data-name="Layer 2">
                <g data-name="Layer 1">
                  <path
                    d="M58.09 96.31v9.25c0 2.29-.74 4.19-3.23 4.2s-3.22-1.89-3.17-4.2c.06-3 0-6.06 0-9.25H37.44v9.22c0 2.28-.71 4.2-3.19 4.24s-3.26-1.85-3.21-4.17c.06-3 0-6.07 0-9.38-5 0-9.57-.59-13.17-4.17s-4.27-7.94-4-13.12c-3.31 0-6.43.19-9.51-.08A5.62 5.62 0 0 1 .54 77c-1.49-2.17.28-4.67 3.27-4.79 3.22-.12 6.45 0 9.86 0V58.29h-9C1.52 58.25 0 57.11.14 54.81.31 52 2.32 51.53 4.6 51.53h9.05V37.64H4.46c-3.02 0-4.41-1.19-4.32-3.42.13-2.82 2.1-3.33 4.4-3.34h9.17c-.35-11.76 5.49-17.46 17.36-17.31V4.01c0-2.23.79-3.93 3.13-4s3.26 1.67 3.26 3.87v9.57h14.25V4.29c0-2.27.56-4.06 3.11-4.31 2.05-.21 3.25 1.5 3.29 4.44s0 5.89 0 9h14.25v-9.4c0-2.2.71-4 3.06-4 2.5 0 3.34 1.78 3.33 4.08v9.42c4.89.08 9.49.57 13.07 4.17s4.39 7.91 4.1 13.15c3.25 0 6.36-.15 9.45.08a5.64 5.64 0 0 1 3.69 1.59c1.7 2.09 0 4.92-3.08 5.05-3.22.14-6.45 0-9.85 0v13.9h8.94c3.12 0 4.77 1.23 4.58 3.47-.24 2.72-2.18 3.3-4.5 3.28h-9v13.97h8.91c3.14 0 4.78 1.22 4.6 3.46-.22 2.72-2.15 3.31-4.48 3.3h-9.17c.15 5-.53 9.53-4.13 13.1s-8 4.27-13.12 4.12v8.59c0 .94.18 2.17-.32 2.77-.81 1-2.29 2.31-3.17 2.11a4.33 4.33 0 0 1-2.72-3 98.68 98.68 0 0 1-.13-10.35ZM54.87 82.5H77.8c3.77 0 4.86-1 4.87-4.66V31.98c0-3.58-1.1-4.66-4.59-4.66H31.65c-3.45 0-4.51 1-4.52 4.37v46.43c0 3.38 1.07 4.37 4.52 4.38q11.61.02 23.22 0Z"
                  />
                  <path d="M34.62 75.27V34.55h40.47v40.72Z" />
                </g>
              </g>
            </svg>

            <div class="text-left">
              <span class="font-bold text-sm xl:text-base">Microchip: </span>
              <span class="text-xs xl:text-sm font-normal ml-1">
                {{ pet.microchip }}
              </span>
            </div>
          </div>

          <div class="flex items-center">
            <i class="fas fa-tint text-lg text-center w-6 mr-3" />
            <div class="text-left">
              <span class="font-bold text-sm xl:text-base"
                >Gruppo Sanguigno:
              </span>
              <span class="text-xs xl:text-sm font-normal ml-1">
                {{ pet.pet_info.blood_group }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--      END: Customer details-->

      <!--      BEGIN: Rapid actions-->
      <div class="col-span-8 lg:col-span-2 p-2 rounded-lg shadow-md bg-white">
        <div class="justify-center bg-theme-45 rounded-md py-2">
          <h1 class="text-xl mr-2 nunito text-theme-40 font-bold text-center">
            Azioni rapide
          </h1>
        </div>
        <div class="grid grid-cols-4 gap-2 mt-5 px-6">
          <div
            v-if="permissions && permissions?.pet?.edit_pet?.enabled === true"
            class="col-span-4 sm:col-span-2 lg:col-span-4 quickaction-btn"
            @click="showModalEditPet = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 74 52.2"
              class="ml-1 h-4 fill-current"
              @click="showModalEditPet = true"
            >
              <path
                d="M39.2 50.1c.4-2.2.9-4.5 1.5-7 .2-1.1.9-1.9 1.5-2.4 1.5-1.4 3.1-2.8 4.6-4.2-1.3-4.6-4.5-8.3-9-11.5-3.8-2.7-8.1-4.2-13-2.7-8.3 2.4-15.2 10.9-15.2 18.9-.4 8.5 7.7 13.2 15.4 9.4 2.4-1.2 4.9-1.1 7.4 0 2.2 1 4.5 1.5 6.9 1.2-.1-.5-.1-1 0-1.7Zm-15.4-35c1.6-1.9 2.2-4 2.3-7-.2-.7-.3-2.2-.9-3.5C24.1 1.7 21.4 0 18.6 0c-2.9 0-5.5 2.1-6.2 5-1 3.9-.3 7.5 3 10.5 2.6 2.3 6.2 2.2 8.4-.4Zm9.7.2c2 2.1 5.3 2.4 7.7.7 3.9-2.8 5.1-9.1 2.6-13-2.3-3.5-7.1-4-10.1-1-1.9 1.9-2.7 4.2-2.5 6.6-.1 2.6.6 4.8 2.4 6.7Zm10.8 9.6c.7 3 2.7 5 5.1 5.2 3.2.3 4.9-1.6 6.2-3.8 2.2-3.6 1.8-8.8-.6-11.4-2.5-2.6-6.1-2.6-8.5 0-1.9 2-2.5 4.4-2.6 6.9.2 1 .2 2 .5 3Zm-34.2 3.7c2-2 2.5-4.5 2.8-7.9-.4-1.2-1-3.1-2-4.9-1-1.8-2.8-2.9-5.1-2.6-2.4.2-3.9 1.5-4.8 3.4-1.9 3.7-1 9 2.2 12.1 2 2 4.9 2 6.9 0Zm48 2.1c2.6 2.4 5.1 4.7 8 7.3-1.2 1-2.5 2-3.7 3-3 2.7-6 5.3-8.8 8.1-1.4 1.3-2.9 2.1-4.9 2.3-1.7.2-3.4.6-5.1.9-1.1.2-1.7-.2-1.5-1.2l1.5-6.9c0-.4.4-.7.7-1 4.5-4.1 8.9-8.1 13.4-12.2l.4-.2ZM69 35.4l-8-7.3c1.5-1.4 2.9-3 4.6-4.3 1.2-1 3.1-.9 4.4.1 1 .8 2 1.6 2.9 2.5 1.4 1.4 1.5 3.4.1 4.8-1.3 1.4-2.7 2.8-4 4.1Z"
              />
            </svg>
            <span class="quickaction-btn-txt">MODIFICA PET</span>
          </div>

          <div
            v-if="
              permissions &&
              permissions['sala d attesa'] &&
              permissions['sala d attesa'].store_waiting_room?.enabled === true
            "
            class="col-span-4 sm:col-span-2 lg:col-span-4 quickaction-btn"
            @click="checkIn()"
          >
            <a class="" data-toggle="modal" data-target="#button-modal-preview">
              <!-- TODO: create check-in modal from old vanadium-->
              <i class="fas fa-sign-out-alt text-lg px-1 mr-1" />
            </a>
            <p class="quickaction-btn-txt">CHECK-IN</p>
          </div>

          <div v-if="false" class="quickaction-btn" @click="workInProgress()">
            <i class="fas fa-procedures text-lg px-1 mr-1" />
            <span class="quickaction-btn-txt">RICOVERA/DIMETTI</span>
          </div>

          <div
            class="col-span-4 sm:col-span-2 lg:col-span-4 quickaction-btn"
            @click="openComunicazioni()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="h-4 w-4 fill-current"
            >
              <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"
              />
            </svg>
            <div class="quickaction-btn-txt">COMUNICAZIONI</div>
          </div>

          <div
            v-if="
              permissions &&
              permissions?.petCorner?.go_to_petcorner?.enabled === true
            "
            class="col-span-4 sm:col-span-2 lg:col-span-4 quickaction-btn"
            @click="openPetCorner()"
          >
            <i class="fas fa-paw" />
            <div class="quickaction-btn-txt">PETCORNER</div>
          </div>
          <div
            v-if="permissions && permissions?.pet?.delete_pet?.enabled === true"
            class="col-span-4 sm:col-span-2 lg:col-span-4 quickaction-btn"
            @click="deletePet()"
          >
            <a class="text-theme-43 hover:text-theme-41">
              <i class="fas fa-times text-base" />
            </a>
            <span class="quickaction-btn-txt">ELIMINA PET</span>
          </div>
        </div>

        <!-- END: Rapid actions-->
      </div>
    </div>
    <!--    END: Top section customer tab-->
    <!--    BEGIN: Bottom section customer tab-->

    <div
      class="mx-12 flex flex-col text-center justify-center sm:mx-36 md:mx-48 lg:flex-wrap lg:flex-row my-2 lg:mx-1"
      role="tablist"
    >
      <button
        v-for="(elem, elemKey) in computedMenu"
        :key="elemKey"
        class="mx-1 my-1 text-center"
        :class="[activeMenu === elemKey ? 'orange-btn' : 'orange-btn-inverted']"
        @click="getMenu(elemKey)"
      >
        <i :class="elem.icon" class="pr-1"></i>
        {{ elem.name }}
      </button>
      <button
        v-if="
          permissions &&
          permissions['schede cliniche'] &&
          permissions['schede cliniche'].view_medicaltemplate &&
          permissions['schede cliniche'].view_medicaltemplate.enabled === true
        "
        class="mx-1 my-1 text-center orange-btn-inverted"
        @click="() => $emit('openTest', petDetails)"
      >
        <i class="pr-1 fas fa-file-medical"></i>
        Cartella Clinica
      </button>
    </div>

    <div
      v-for="(item, itemKey) in computedMenu"
      v-show="activeMenu === itemKey"
      :key="itemKey"
      class="mt-5"
    >
      <keep-alive>
        <component
          :is="item.component"
          :key="keyNumber"
          :pet-details="petDetails"
          :rev-docs="revDocs"
          :loading-search-rev="loadingSearchRev"
          :total-records="totalRecords"
          :notes="notes"
          :new-annotation-modal="newAnnotationModalOpen"
          @changePaginationNotes="changePaginationNotes"
          @addNote="addNote"
          @deleteNote="deleteNote"
          @handleSendMessage="handleSendMessage"
          @handleSavePdfRev="handleSavePdfRev"
          @handleDeleteRev="handleDeleteRev"
          @handleLoadRev="handleLoadRev"
          @handleNewRecipeModal="handleNewRecipeModal"
          @handleFreeRecipeModal="handleFreeRecipeModal"
          @handleDetailsModal="
            (type, scheda_clinica) => handleDetailsModal(type, scheda_clinica)
          "
          @handleSpecialisticModal="
            (scheda_clinica) => handleSpecialisticModal(scheda_clinica)
          "
          @toggleNewAnnotationModal="toggleNewAnnotationModal"
          @refresh="refreshTable()"
          @refreshPetData="refreshPetData"
          @refreshNotes="
            (type, pagination, filters) => getNote(type, pagination, filters)
          "
          @handleEditRev="handleEditRev"
        ></component>
      </keep-alive>
    </div>

    <!--    END: Bottom section customer tab-->
    <SpecialisticChoice
      v-if="specialisticModal"
      class="absolute top-0"
      title=" Tiplogie Schede Specialistiche"
      :options="schedaPage ? templateSchede : templateReferti"
      @close="closeSpecialisticModal"
      @specialisticSelected="handleSpecialisticChoice"
    />
  </div>
  <!-- <SendMessageModal
    v-if="messageModal"
    :elem="selectedElem"
    @close="closeSendMessageModal"
    @confirm="confirmMessage"
  /> -->

  <NewRecipeModal
    v-if="newRecipeModal"
    :new-drugs="newDrugsList"
    :pet-invoice="pet"
    :client-invoice="clientInvoice"
    :edit_id="edit_id"
    :diagnosi="prescapoDiagnosiCodice"
    :note="ricNote"
    @close="closeNewRecipeModal"
    @emitDrug="emitDrug"
    @confirm="confirmAddNewRecipe"
    @addDrug="handleAddDrug"
    @removeDrug="removeDrug"
    @editDrug="editDrug"
    @editRev="editRev"
    @selectClientPet="handleClientPet"
    @setRicNote="setRicNote"
    @setDiagnosi="setDiagnosi"
  />
  <AddDrugModal
    v-if="drugModal"
    :edit-drug-pet="editDrugPet"
    :editable-drug-pet="editableDrugPet"
    :drugs-types="drugsTypes"
    :unique-drug-type="uniqueDrugType"
    @close="closeAddDrugModal"
    @confirm="confirmAddDrug"
    @edit="confirmEditDrug"
  />

  <FreeRecipeModal
    v-if="freeRecipeModal"
    :pet="pet"
    @handleFreeRecipeModal="handleFreeRecipeModal"
    @close="closeFreeRecipeModal"
    @confirmAddNewFreeRecipe="confirmAddNewFreeRecipe"
  />

  <addWaitingListModal
    v-if="showAddWaitingList"
    title=" Aggiungi paziente alla lista d'attesa"
    :pet="pet"
    @close="showAddWaitingList = false"
  />

  <VetslabModal
    v-if="showVetslabModal"
    title="Seleziona un esame"
    :pet="pet"
    @close="showVetslabModal = false"
  />

  <SelectPatientModal
    v-if="addClientModal"
    title="Aggiungi proprietario"
    :client="null"
    :vets-selected="null"
    :new-client="true"
    @close="closeSelectClient"
    @selectCustomer="(customer) => addPetOwner(customer, 'add')"
  />

  <!-- <SpecialisticChoice
    v-if="specialisticModal"
    title=" Tiplogie Schede Specialistiche"
    :options="schedaPage ? templateSchede : templateReferti"
    @close="closeSpecialisticModal"
    @specialisticSelected="handleSpecialisticChoice"
  /> -->
</template>

<script>
import { defineComponent, shallowRef } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import axios from '../../gateway/backend-api'
import Dashboard from './PetDashboard'
import PetHealthCard from './PetHealthCard'
import PetClinicalRecords from './PetClinicalRecords'
import PetDocuments from './PetDocuments'
import PetReports from './PetReports'
import PetPrescription from './PetPrescription'
import PetAcceptance from './PetAcceptance'
import PetClinicalCases from './PetClinicalCases'
import PetAttachments from './PetAttachments'
import NewPet from '../../views/nuovo-pet/NewPet.vue'
import Toastify from 'toastify-js'
import SendMessageModal from '../modals/SendMessageModal.vue'
import NewRecipeModal from '../modals/NewRecipeModal.vue'
import FreeRecipeModal from '../modals/FreeRecipeModal.vue'
import AddDrugModal from '../modals/AddDrugModal.vue'
import DetailsModal from '../modals/DetailsModal.vue'
import addWaitingListModal from '../modals/addWaitingListModal.vue'
import SpecialisticChoice from '../modals/SpecialisticChoice.vue'
import NotificationComponent from '../notification/NotificationComponent.vue'
import VetslabModal from '../modals/VetslabModal.vue'
import image from '../../assets/images/fotoTest.png'
import { useStore } from '@/store'
import SelectPatientModal from '../modals/SelectPatientModal.vue'
import NewReferente from '../../views/nuovo-pet/NewReferente.vue'

const dashboard = shallowRef(Dashboard)
const healthCard = shallowRef(PetHealthCard)
const clinicalRecords = shallowRef(PetClinicalRecords)
const documents = shallowRef(PetDocuments)
const reports = shallowRef(PetReports)
const prescription = shallowRef(PetPrescription)
const clinicalCases = shallowRef(PetClinicalCases)
const attachments = shallowRef(PetAttachments)
const acceptance = shallowRef(PetAcceptance)

const components = [
  dashboard,
  healthCard,
  clinicalRecords,
  documents,
  reports,
  prescription,
  clinicalCases,
  attachments,
  acceptance
]

export default defineComponent({
  components: {
    SendMessageModal,
    NotificationComponent,
    NewRecipeModal,
    NewReferente,
    FreeRecipeModal,
    AddDrugModal,
    NewPet,
    DetailsModal,
    addWaitingListModal,
    SpecialisticChoice,
    VetslabModal,
    SelectPatientModal
  },
  props: {
    petDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    }
  },
  emits: ['openTest', 'removeTab'],
  data() {
    return {
      showAddWaitingList: false,
      newReferent: false,
      keyNumber: 0,
      store: useStore(),
      detailsConfiguration: [
        {
          type: 'text',
          title: 'Titolo',
          value: 'Esempio che fa al caso nostro'
        },
        {
          type: 'text',
          title: 'Medico Responsabile',
          value: 'Medico molto bravo'
        },
        {
          type: 'info',
          title: 'Cliente',
          value: 'Cliente con bel gattino'
        },
        {
          type: 'text',
          title: 'Referti',
          value: 'Referti effettuati e schedati',
          buttons: [
            {
              name: 'pdf',
              action: () => {}
            },
            {
              name: 'info',
              action: () => {}
            },
            {
              name: 'print',
              action: () => {}
            }
          ]
        },
        {
          type: 'text',
          title: 'Messaggi medico',
          value: 'Collare antipulci per due settimane'
        },
        {
          type: 'date',
          title: 'Inizio terapia',
          value: ''
        },
        {
          type: 'checkbox',
          title: 'Terapia a distanza',
          value: ['mai'],
          options: ['sempre', 'mai', 'magari']
        },
        {
          type: 'section',
          title: 'Altri dati',
          value: [
            {
              type: 'text',
              title: 'Peso',
              value: '',
              required: false
            },
            {
              type: 'text',
              title: 'Temperatura',
              value: '',
              required: true
            }
          ]
        },
        {
          type: 'image',
          title: 'Test immagine',
          didascalia: 'Cane  visiona laterale',
          imageBg: image,
          value: null
        },
        {
          type: 'video',
          title: 'Test Video',
          bio: 'Operazione delicata a  zampa dx posteriore',
          anteprima: image,
          link: 'https://www.youtube.com/watch?v=XImVGejg6zo'
        }
      ],
      pet: {},
      clientInvoice: null,
      menu: [
        {
          name: 'Cruscotto',
          icon: 'fas fa-th-large',
          component: components[0],
          props: this.petDetails
        },
        {
          name: 'Libretto',
          icon: 'fas fa-history',
          component: components[1],
          props: this.petDetails
        },
        // {
        //   name: 'Schede Cliniche',
        //   icon: 'fas fa-file-medical',
        //   component: components[2],
        //   props: this.petDetails
        // },
        // {
        //   name: 'Referti',
        //   icon: 'far fa-folder',
        //   component: components[4],
        //   props: this.petDetails
        // },
        {
          name: 'Documenti',
          icon: 'fas fa-file-medical-alt',
          component: components[3],
          props: this.petDetails,
          permissions: ['allegati', 'view_attachments']
        },
        // {
        //   name: 'Allegati',
        //   icon: 'far fa-copy',
        //   component: components[7],
        //   props: this.petDetails
        // },

        {
          name: 'Ricette',
          icon: 'fas fa-list',
          component: components[5],
          props: this.petDetails,
          permissions: ['REV - Ricetta Elettonica Veterinaria', 'view_rev']
        },
        {
          name: 'Consensi / Certificati',
          icon: 'fas fa-list-alt',
          component: components[8],
          props: this.petDetails,
          permissions: ['pet', 'view_pet_consent']
        }
        // {
        //   name: 'Casi Clinici',
        //   icon: 'fas fa-list-alt',
        //   component: components[6],
        //   props: this.petDetails
        // }
      ],
      activeMenu: 0,
      messageModal: false,
      selectedElem: null,
      newRecipeModal: false,
      freeRecipeModal: false,
      detailsModal: false,
      specialisticModal: false,
      drugModal: false,
      showModalEditPet: false,
      editDrugPet: false,
      editableDrugPet: [],
      edit_id: null,
      revDocs: [],
      loadingSearchRev: false,
      totalRecords: 0,
      newDrugsList: [],
      detailsConfig: null,
      templateSchede: null,
      templateReferti: null,
      specialisticSelected: null,
      medicalTemplatesID: null,
      modifySchedaClinica: false,
      petClinicalDocuments: null,
      showVetslabModal: false,
      schedaPage: true,
      ricNote: '',
      prescapoDiagnosiCodice: null,
      notes: {
        forPageOption: [3, 5, 10, 20, 50],
        annotations: {
          note: null,
          pagination: {
            page: 1,
            forPage: 3
          },
          total: 0
        },
        events: {
          note: null,
          pagination: {
            page: 1,
            forPage: 5
          },
          total: 0
        }
      },
      newAnnotationModalOpen: false,
      // drugsTypes: ['Farmaco', 'Galenico', 'Omeopatico', 'Estero'],
      uniqueDrugType: false, //La ricetta non può contenere più di un medicinale con presrigaFlTipoMedicinale valorizzato a S o O o E.
      addClientModal: false,
      external_id: null
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails', // NON CANCELLARE
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer', // NON CANCELLARE
      // anche se non sono utilizzate in questo file, quando si utilizzano le funzioni globali di goTo se non vengono dichiarate qua sono inutilizzabili in quel file
      getTab: 'anagraficaPazienti/getTab',
      getShow: 'anagraficaPazienti/getShow',
      permissions: 'reusableData/getPermissions'
    }),
    drugsTypes: function () {
      if (this.uniqueDrugType && !this.editDrugPet) return ['Farmaco']
      else return ['Farmaco', 'Galenico', 'Omeopatico', 'Estero']
    },
    computedMenu: function () {
      let resp = []
      if (this.menu) {
        resp = this.menu.filter((el) => {
          return (
            !el.permissions ||
            (this.permissions[el.permissions[0]] &&
              this.permissions[el.permissions[0]][el.permissions[1]] &&
              this.permissions[el.permissions[0]][el.permissions[1]].enabled ===
                true)
          )
        })
      }
      return resp
    }
  },
  watch: {
    petDetails: function (elem) {
      this.pet = elem
    }
  },
  created() {
    this.pet = this.petDetails
  },
  mounted() {
    if (localStorage.getItem('clinic')) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medicallayout?type=clinical_card '
        ) // scarico la lista di documenti // ?type=record per ottenere i report
        .then((res) => {
          this.templateSchede = res.data
          // this.templateSchede = resp.data
        })

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/medicallayout?type=report '
        ) // scarico la lista di documenti // ?type=record per ottenere i report
        .then((res) => {
          this.templateReferti = res.data
          // this.templateSchede = resp.data
        })

      this.getNote('annotations')
      this.getNote('events')
    }
    this.getExternalId()
  },
  methods: {
    ...mapActions({
      getListAction: 'anagraficaPazienti/getListAction',
      getPetAction: 'anagraficaPazienti/getPetAction',
      getSinglePetAction: 'anagraficaPazienti/getSinglePetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      getSingleCustomerAction: 'anagraficaClienti/getSingleCustomerAction',
      removeTabPetAction: 'anagraficaPazienti/removeTabPetAction',
      removeTabCustomerAction: 'anagraficaClienti/removeTabCustomerAction',
      updatePetData: 'anagraficaPazienti/updatePetData'
    }),
    closeNewReferent() {
      this.newReferent = false
      this.$refs.newPet.getReferenti()
    },

    getCustomer: function () {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/customer/' +
            this.pet.owners[0].id
        )
        .then((res) => {
          const customer = res.data
          this.$goToCustomer(customer)
        })
    },
    getReferent: function () {},
    handleClientPet(elem, value) {
      if (elem == 'clientInvoice') this.clientInvoice = value
      //else if (elem == 'petInvoice') this.pet = value
    },
    openReferente() {
      this.newReferent = !this.newReferent
    },
    getMenu: function (elem) {
      this.activeMenu = elem
    },
    setRicNote(elem) {
      this.ricNote = elem
    },
    setDiagnosi(elem) {
      this.prescapoDiagnosiCodice = elem
    },
    workInProgress: function () {
      alert('Funzione presto disponibile')
    },
    handleSendMessage(elem) {
      // metodo che apre il popup di invio messaggio
      this.selectedElem = elem

      this.messageModal = true
    },
    closeSendMessageModal() {
      // metodo per chiudere il modal di invio messaggio
      this.selectedElem = null
      this.messageModal = false
    },
    openVetslab() {
      this.showVetslabModal = !this.showVetslabModal
    },
    handleAddDrug() {
      // metodo che apre il popup di aggiunta nuovo farmaco
      this.drugModal = true
      this.editDrugPet = false
    },
    refreshTable() {
      this.keyNumber += 1
    },
    removeDrug(elem) {
      if (elem.type != 'Farmaco') this.uniqueDrugType = false
      const filtered = this.newDrugsList.filter((e) => {
        return !_.isEqual(e, elem)
      })
      this.newDrugsList = filtered
    },
    editDrug(elem) {
      this.drugModal = true
      this.editDrugPet = true
      var indexDrugsList = null
      this.editableDrugPet = this.newDrugsList.filter((e, index) => {
        if (_.isEqual(e, elem)) {
          indexDrugsList = index
          return _.isEqual(e, elem)
        }
      })
      this.editableDrugPet[0]['indexDrugsList'] = indexDrugsList
    },
    emitDrug(elem, emit) {
      this.newRecipeModal = false
      var ricMedicinali = []
      var ricCapi = []
      var ricMedicinaliCapi = []
      this.newDrugsList.forEach((drug, index) => {
        var presrigaFlTipoMedicinale = ''
        switch (drug.tipo) {
          case 'Farmaco':
            presrigaFlTipoMedicinale = 'N'
            break
          case 'Galenico':
            presrigaFlTipoMedicinale = 'S'
            break
          case 'Omeopatico':
            presrigaFlTipoMedicinale = 'O'
            break
          case 'Estero':
            presrigaFlTipoMedicinale = 'E'
            break
          // default:
        }
        ricMedicinali.push({
          presrigaFlTipoMedicinale: presrigaFlTipoMedicinale,
          presrigaProdAic: drug.aic,
          presrigaPosologia: drug.farmaco.posologia
            ? drug.farmaco.posologia
            : drug.posologia,
          presrigaDurataTrattamento: drug.farmaco.durata
            ? drug.farmaco.durata
            : drug.tNumber,
          presrigaQuantitativo: drug.farmaco.qty ? drug.farmaco.qty : drug.qty,
          presrigaDescrizione: drug.description,
          presrigaNote: drug.data.note,
          presrigaFlMalattiaCronica: drug.data.chronical,
          presrigaProfilassiMetafilassiCodice: drug.revOption
            ? drug.revOption.toUpperCase()
            : null,
          extra1: drug.trattamento ? drug.trattamento : null,
          presrigaGiustificazioneCodice: drug.whyProfilassiMetafilassi
            ? drug.whyProfilassiMetafilassi
            : null,
          presrigaGiustificazioneNote: drug.noteWhyProfilassiMetafilassi
            ? drug.noteWhyProfilassiMetafilassi
            : null,
          presrigaFlRipetibile:
            drug.data && drug.data.repeatable
              ? drug.data.repeatable === 'Si'
                ? 'S'
                : 'N'
              : ''
        })

        if (index == 0) {
          ricCapi.push({
            prescapoSpecieCodice: this.pet.type,
            prescapoIdentificativo: this.pet.microchip
              ? this.pet.microchip
              : this.pet.id
          })
        }

        ricMedicinaliCapi.push({
          presrigaIndex: index,
          prescapoIndex: 0,
          prescapoDerogaCodice: '',
          prescapoNote: drug.data.deroga,
          prescapoDiagnosiCodice: this.prescapoDiagnosiCodice
        })
      })

      var send_data = {
        customer_id: elem.id,
        pet_id: this.pet.id,
        strCodice: '', //codice clinica
        ricFlProsieguo: true, //todo
        vetIdFiscale: 'ZSIZSI80A01L103N', //codice fiscale veterinario
        propFlStraniero: false, //verificare se cliente ha residenza in italia
        propIdFiscale:
          this.pet.owners[0].vat_number?.vat_number ??
          this.pet.owners[0].fiscal_code,
        propNome: this.pet.owners[0].first_name,
        propCognome: this.pet.owners[0].last_name,
        propFlGiuridico:
          this.pet.owners[0].type == 'Persona giuridica' ? true : false,
        propRagioneSociale: this.pet.owners[0].ragione_sociale,
        ricNote: this.ricNote,
        warnDisabled: true,
        ricMedicinali: ricMedicinali,
        ricCapi: ricCapi,
        ricMedicinaliCapi: ricMedicinaliCapi,
        emit: emit
      }

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/rev/storeRev', send_data)
        .then((res) => {
          if (emit) {
            if (typeof res.data.ricNumero != 'undefined') {
              this.newRecipeModal = false
              this.revDocs = []

              this.$store.dispatch('commonComponents/hideLoadingModalAction')
              this.uniqueDrugType = false
              this.handleLoadRev()
              this.closeNewRecipeModal()
            } else {
              this.handleLoadRev()
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            }
          } else {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.uniqueDrugType = false
            this.handleLoadRev()
            this.closeNewRecipeModal()
          }
        })
        .catch(() => {
          this.handleNewRecipeModal(false)
          this.handleLoadRev()
          this.closeNewRecipeModal()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    handleEditRev(elem) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      this.edit_id = elem.id
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/pet/' + elem.details.pet_id
        )
        .then((res) => {
          this.petInvoice = res.data
          this.clientInvoice = res?.data?.owners[0]
          res.data.owners.forEach((el) => {
            if (el.id === elem.details.customer_id) this.clientInvoice = el
          })
          if (elem.type == 'Libera') {
            this.handleFreeRecipeModal(elem.note)
          } else {
            axios
              .get(
                '/' +
                  localStorage.getItem('clinic') +
                  '/rev/' +
                  elem.id +
                  '/info'
              )
              .then((res) => {
                let j = JSON.parse(res.data.details)
                if (res?.data?.details && res?.data?.farmaci) {
                  let tipo_farmaco = ''
                  for (let i = 0; i < j.ricMedicinali.length; i++) {
                    if (j.ricMedicinali[i].presrigaFlTipoMedicinale)
                      switch (j.ricMedicinali[i].presrigaFlTipoMedicinale) {
                        case 'N':
                          tipo_farmaco = 'Farmaco'
                          break
                        case 'S':
                          tipo_farmaco = 'Galenico'
                          break
                        case 'O':
                          tipo_farmaco = 'Omeopatico'
                          break
                        case 'E':
                          tipo_farmaco = 'Estero'
                          break
                        // default:
                      }
                    let xtr = j.ricMedicinali[i].extraObject
                      ? JSON.parse(j.ricMedicinali[i].extraObject)
                      : null

                    this.newDrugsList.push({
                      aic: j.ricMedicinali[i].presrigaProdAic,
                      farmaco: {
                        aic: j.ricMedicinali[i].presrigaProdAic,
                        farmaco: res.data.farmaci[i]
                          ? res.data.farmaci[i].MARCHIO
                          : null,
                        confezione: res.data.farmaci[i]
                          ? res.data.farmaci[i].CONFEZIONE
                          : null,
                        qty: j.ricMedicinali[i].presrigaQuantitativo,
                        posologia: j.ricMedicinali[i].presrigaPosologia,
                        durata: j.ricMedicinali[i].presrigaDurataTrattamento,
                        tipo: tipo_farmaco
                      }, // res.data.farmaci[i].MARCHIO,
                      nome: res.data.farmaci[i],
                      //deroga: j.ricMedicinali[i].,
                      qty: j.ricMedicinali[i].presrigaQuantitativo,
                      tipo: tipo_farmaco,
                      data: {
                        note: j.ricMedicinali[i].presrigaNote,
                        repeatable:
                          j.ricMedicinali[i].presrigaFlRipetibile &&
                          j.ricMedicinali[i].presrigaFlRipetibile === 'S'
                            ? 'Si'
                            : 'No',
                        chronical: j.ricMedicinali[i].presrigaFlMalattiaCronica,
                        deroga: j.ricMedicinaliCapi
                          ? j.ricMedicinaliCapi[0]?.prescapoNote
                          : null,
                        ormonal: j.ricMedicinali[i].presrigaFlOrmonaleVaccino
                      },
                      category: res.data.farmaci[i]
                        ? res.data.farmaci[i].TIPO_PRODOTTO
                        : null,
                      antimicrobico:
                        xtr && xtr.presrigaProdFlAntimicrobico
                          ? xtr.presrigaProdFlAntimicrobico
                          : null, //el.antimicrobico, // c'è solo se  un farmaco estero, da provare
                      //revOption: el.revOption, // da capire cosa sia
                      // whyProfilassiMetafilassi:
                      //   xtr && xtr.presrigaProfilassiMetafilassiCodice
                      //     ? xtr.presrigaProfilassiMetafilassiCodice
                      //     : null,
                      trattamento: j.ricMedicinali[i].extra1,
                      confezione: res.data.farmaci[i]
                        ? res.data.farmaci[i].CONFEZIONE
                        : null,
                      tNumber: j.ricMedicinali[i].presrigaDurataTrattamento,
                      posologia: j.ricMedicinali[i].presrigaPosologia,
                      description: res.data.farmaci[i]
                        ? res.data.farmaci[i].DESCRIZIONE_DISPENSAZIONE
                        : j.ricMedicinali[i].presrigaDescrizione,
                      deroga: j.ricMedicinaliCapi
                        ? j.ricMedicinaliCapi[0]?.prescapoNote
                        : null,
                      revOption:
                        j.ricMedicinali[i]?.presrigaProfilassiMetafilassiCodice,
                      whyProfilassiMetafilassi:
                        j.ricMedicinali[i]?.presrigaGiustificazioneCodice,
                      noteWhyProfilassiMetafilassi:
                        j.ricMedicinali[i]?.presrigaGiustificazioneNote
                      // noteWhyProfilassiMetafilassi:
                      //   el.noteWhyProfilassiMetafilassi,
                      // indexDrugsListEditable: el.indexDrugsListEditable
                    })
                  }
                }
                this.setDiagnosi(j.ricMedicinaliCapi[0]?.prescapoDiagnosiCodice)
                this.setRicNote(j.ricNote)

                this.handleNewRecipeModal()
              })
          }
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    editRev(client, pet) {
      var ricMedicinali = []
      var ricCapi = []
      var ricMedicinaliCapi = []
      this.newDrugsList.forEach((drug, index) => {
        var presrigaFlTipoMedicinale = ''
        switch (drug.tipo) {
          case 'Farmaco':
            presrigaFlTipoMedicinale = 'N'
            break
          case 'Galenico':
            presrigaFlTipoMedicinale = 'S'
            break
          case 'Omeopatico':
            presrigaFlTipoMedicinale = 'O'
            break
          case 'Estero':
            presrigaFlTipoMedicinale = 'E'
            break
          // default:
        }
        ricMedicinali.push({
          presrigaFlTipoMedicinale: presrigaFlTipoMedicinale,
          presrigaProdAic: drug.aic,
          presrigaPosologia:
            drug.farmaco && drug.farmaco.posologia
              ? drug.farmaco.posologia
              : drug.posologia,
          presrigaDurataTrattamento:
            drug.farmaco && drug.farmaco.durata
              ? drug.farmaco.durata
              : drug.tNumber,
          presrigaQuantitativo:
            drug.farmaco && drug.farmaco.qty ? drug.farmaco.qty : drug.qty,
          presrigaDescrizione: drug.description,
          presrigaNote: drug.data && drug.data.note ? drug.data.note : '',
          presrigaFlMalattiaCronica:
            drug.data && drug.data.chronical ? drug.data.chronical : '',
          presrigaProfilassiMetafilassiCodice: drug.revOption
            ? drug.revOption.toUpperCase()
            : null,
          extra1: drug.trattamento ? drug.trattamento : null,
          presrigaGiustificazioneCodice: drug.whyProfilassiMetafilassi
            ? drug.whyProfilassiMetafilassi
            : null,
          presrigaGiustificazioneNote: drug.noteWhyProfilassiMetafilassi
            ? drug.noteWhyProfilassiMetafilassi
            : null,
          presrigaFlRipetibile:
            drug.data && drug.data.repeatable
              ? drug.data.repeatable === 'Si'
                ? 'S'
                : 'N'
              : '',
          presrigaFlOrmonaleVaccino: drug.data.ormonal
        })

        if (index == 0) {
          ricCapi.push({
            prescapoSpecieCodice: this.petInvoice.type,
            prescapoIdentificativo: this.petInvoice.microchip
              ? this.petInvoice.microchip
              : this.petInvoice.id
          })
        }

        ricMedicinaliCapi.push({
          presrigaIndex: index,
          prescapoIndex: 0,
          prescapoDerogaCodice: '',
          prescapoNote: drug.data.deroga,
          prescapoDiagnosiCodice: this.prescapoDiagnosiCodice
        })
      })

      var send_data = {
        customer_id: client.id,
        pet_id: pet.id,
        strCodice: '', //codice clinica
        ricFlProsieguo: true, //todo
        vetIdFiscale: 'ZSIZSI80A01L103N', //codice fiscale veterinario
        propFlStraniero: false, //verificare se cliente ha residenza in italia
        propIdFiscale:
          this.petInvoice.owners[0].vat_number?.vat_number ??
          this.petInvoice.owners[0].fiscal_code,
        propNome: this.petInvoice.owners[0].first_name,
        propCognome: this.petInvoice.owners[0].last_name,
        propFlGiuridico:
          this.petInvoice.owners[0].type == 'Persona giuridica' ? true : false,
        propRagioneSociale: this.petInvoice.owners[0].ragione_sociale,
        ricNote: this.ricNote,
        warnDisabled: true,
        ricMedicinali: ricMedicinali,
        ricCapi: ricCapi,
        ricMedicinaliCapi: ricMedicinaliCapi
      }

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/rev/editRev/' + this.edit_id,
          send_data
        )
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Ricetta clinica modificata con successo!'
          })
          this.uniqueDrugType = false
          //this.handleNewRecipeModal(false)
          this.closeNewRecipeModal()
          this.handleLoadRev()
        })
        .catch((error) => {
          this.handleLoadRev()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openMessageModal', {
            title: 'Errore',
            subtitle:
              error.response.status == 403
                ? 'Non autorizzato'
                : error.response.data.message,
            background: false
          })
        })
    },
    handleLoadRev(value = {}, pagination) {
      this.loadingSearchRev = true
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      if (localStorage.getItem('clinic')) {
        axios
          .post('/' + localStorage.getItem('clinic') + '/rev', {
            active_filters: value,
            pet_id: this.pet.id,
            per_page: pagination ? pagination.forPage : 10,
            page: pagination ? pagination.page : 1
          })
          .then((res) => {
            this.loadingSearchRev = false
            this.revDocs = res.data.data
            this.totalRecords = res.data.total
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      }
    },
    handleSavePdfRev(elem) {
      if (elem.type === 'Libera') {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios({
          url:
            '/' +
            localStorage.getItem('clinic') +
            '/pet/prescription/show/' +
            elem.number,
          method: 'POST',
          responseType: 'blob'
        })
          .then((response) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Ricetta_' + elem.number + '.pdf')
            document.body.appendChild(link)
            this.$store.dispatch('commonComponents/openPdfModal', {
              title: 'Ricetta ' + elem.type + '_' + elem.number,
              link: url,
              background: false,
              table_reference: 'revs',
              id_reference: elem.id,
              client: this.petDetails.owners[0],
              print: () => {
                console.log('PRINT')
              },
              download: () => {
                link.click()
              }
            })
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      } else {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/rev/pdfRev',
            {
              ricNumero: elem.number
            },
            {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
              }
            }
          )
          .then((response) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Ricetta_' + elem.number + '.pdf')
            document.body.appendChild(link)
            this.$store.dispatch('commonComponents/openPdfModal', {
              title: elem.number,
              link: url,
              background: false,
              table_reference: 'revs',
              id_reference: elem.number,
              client: this.petDetails.owners[0],
              print: () => {
                console.log('PRINT')
              },
              download: () => {
                link.click()
              }
            })

            // link.click()
          })
          .catch((error) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            if (error.response.status === 403)
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Credenziali utente non trovate'
              })
          })
      }
    },
    handleDeleteRev(elem) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler annullare la ricetta?',
        background: false,
        confirm: () => {
          axios
            .post('/' + localStorage.getItem('clinic') + '/rev/annullaRev', {
              ricNumero: elem?.number?.toString(),
              id: elem.id
            })
            .then((response) => {
              if (response.status == 200) {
                const temp = this.revDocs.filter((doc) => {
                  return !_.isEqual(doc, elem)
                })
                this.revDocs = temp
                this.totalRecords = this.revDocs.length
              }
            })
        },
        decline: () => {}
      })
    },
    closeNewRecipeModal() {
      this.selectedElem = null
      this.edit_id = null
      this.ricNote = null
      this.prescapoDiagnosiCodice = null
      this.newRecipeModal = false
      this.newDrugsList = []
    },
    confirmMessage() {
      // (titolo, message, file)
      this.messageModal = false

      //topbarIndex.classList.remove('z-40')
    },
    handleNewRecipeModal(open = true) {
      //this.newDrugsList = []
      if (this.pet && this.pet.owners) this.clientInvoice = this.pet.owners[0]
      this.newRecipeModal = open

      document.body.classList.add('overflow-hidden')
    },

    confirmAddDrug(elem) {
      if (elem.type.value != 'Farmaco') this.uniqueDrugType = true

      this.newDrugsList.push({
        aic: elem.aic,
        farmaco: elem.farmaci,
        nome: elem.denominazione,
        deroga: elem.deroga,
        qty: elem.cNumber,
        tipo: elem.type,
        data: {
          note: elem.note,
          repeatable: elem.repeatable,
          chronical: elem.chronical,
          deroga: elem.deroga,
          ormonal: elem.ormonal
        },
        category: elem.category,
        antimicrobico: elem.antimicrobico,
        revOption: elem.revOption,
        whyProfilassiMetafilassi: elem.whyProfilassiMetafilassi,
        trattamento: elem.trattamento,
        confezione: elem.confezione,
        tNumber: elem.tNumber,
        posologia: elem.posologia,
        description: elem.description,
        noteWhyProfilassiMetafilassi: elem.noteWhyProfilassiMetafilassi,
        indexDrugsListEditable: elem.indexDrugsListEditable
      })

      this.newRecipeModal = true
      this.drugModal = false
    },
    confirmEditDrug(elem) {
      var indexDrugsListEditable = elem.indexDrugsListEditable.value

      if (
        this.newDrugsList[indexDrugsListEditable].tipo != 'Farmaco' &&
        elem.type.value == 'Farmaco'
      )
        this.uniqueDrugType = false

      this.newDrugsList[indexDrugsListEditable] = {
        aic: elem.aic,
        farmaco: elem.farmaci,
        nome: elem.denominazione,
        deroga: elem.deroga,
        qty: elem.cNumber,
        tipo: elem.type,
        data: {
          note: elem.note,
          repeatable: elem.repeatable,
          chronical: elem.chronical,
          deroga: elem.deroga,
          ormonal: elem.ormonal
        },
        category: elem.category,
        antimicrobico: elem.antimicrobico,
        revOption: elem.revOption,
        whyProfilassiMetafilassi: elem.whyProfilassiMetafilassi,
        trattamento: elem.trattamento,
        confezione: elem.confezione,
        tNumber: elem.tNumber,
        posologia: elem.posologia,
        description: elem.description,
        noteWhyProfilassiMetafilassi: elem.noteWhyProfilassiMetafilassi,
        indexDrugsListEditable: elem.indexDrugsListEditable
      }

      this.newRecipeModal = true
      this.drugModal = false
    },
    checkIn() {
      this.showAddWaitingList = !this.showAddWaitingList
    },
    handleFreeRecipeModal() {
      this.freeRecipeModal = true
    },
    confirmAddNewFreeRecipe(ricetta, customer) {
      this.freeRecipeModal = false
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/pet/' +
            this.pet.id +
            '/prescription',
          {
            customer_id: customer.id,
            prescription: ricetta
          }
        )
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            // here update ricette
            this.handleLoadRev()
          }
          this.refreshTable()
        })
        .catch((err) => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: err
          })
          this.handleLoadRev()
        })
    },
    handleDetailsModal(elem, scheda_clinica) {
      if (scheda_clinica !== null && scheda_clinica !== undefined) {
        this.schedaPage = scheda_clinica
      }

      this.detailsModal = true
      this.medicalTemplatesID = elem.id
      this.specialisticSelected = elem.layout_id
      if (this.medicalTemplatesID) this.modifySchedaClinica = true
      else this.modifySchedaClinica = false
    },
    handleSpecialisticModal(scheda_clinica) {
      if (scheda_clinica !== null && scheda_clinica !== undefined) {
        this.schedaPage = scheda_clinica
      }
      this.specialisticModal = true
    },
    handleSpecialisticChoice(type) {
      this.closeSpecialisticModal()
      //this.handleDetailsModal(type)
      this.specialisticSelected = type
      this.detailsModal = true
    },
    closeFreeRecipeModal() {
      this.selectedElem = null
      this.freeRecipeModal = false
    },
    closeDetailsModal() {
      this.medicalTemplatesID = null
      this.selectedElem = null
      this.detailsModal = false
      this.specialisticSelected = null
      this.modifySchedaClinica = false
    },
    closeSpecialisticModal() {
      this.selectedElem = null
      this.specialisticModal = false
    },
    closeAddDrugModal() {
      this.drugModal = false
      this.newRecipeModal = true
    },
    editPet() {
      this.showModalEditPet = false
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/' + this.pet.id)
        .then((res) => {
          this.pet = res.data
        })
    },
    refreshPetData() {
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/pet?pet_id=' + this.pet.id
        )
        .then((res) => {
          this.updatePetData(res.data.data[0])
          //this.pet = res.data
        })
    },
    openComunicazioni() {
      this.$store.dispatch('commonComponents/openSendMessageModal', {
        data: { type: 'mail', client: this.petDetails.owners[0] },
        confirm: (data) => {
          console.log('invio la mail', data)
          let attachements = []
          if (data && data.selectedFile) {
            data.selectedFile.forEach((el) => {
              attachements.push({
                id: typeof el.id === 'string' ? 1 : el.id,
                alternative_id: el.id.toString(),
                type: el.table,
                file_name: el.name
              })
            })
          }
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post('/' + localStorage.getItem('clinic') + '/mailersend/send', {
              customer_id: this.petDetails?.owners[0]?.id,
              type: 'email',
              email: data.destinatario,
              //text: 'Documento: ' + props.title,
              object: data.titolo,
              body: data.message,
              email_type: 'simple_message',
              attachments: attachements,
              cc: data.cc
            })
            .then(() => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Email inviata con successo!'
              })
              this.store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: "Errore durante l'invio della mail"
              })
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        }
      })
    },
    getNote(type, pagination = { page: 1, forPage: 5 }, filters = {}) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/note', {
          type: type,
          active_filters: filters,
          pet_id: this.pet.id,
          per_page: pagination.forPage,
          page: pagination.page
        })
        .then((res) => {
          console.log(res.data)
          this.notes[type].note = res.data.data
          this.notes[type].total = res.data.total
        })
    },
    addNote(type, note) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/note/new-note', {
          type: type,
          pet_id: this.pet.id,
          note: note
        })
        .then(() => {
          var msg = ''
          if (type == 'annotations') msg = 'Annotazione aggiunta con successo!'
          else if (type == 'events') msg = 'Evento aggiunto con successo!'

          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: msg
          })
          this.closeNewAnnotationModal()
          this.getNote(type)
        })
    },

    deleteNote(type, note_id) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler eliminare questa nota?',
        background: false,
        confirm: () => {
          axios
            .delete(
              '/' +
                localStorage.getItem('clinic') +
                '/note/' +
                note_id +
                '/delete'
            )
            .then(() => {
              var msg = ''
              if (type == 'annotations')
                msg = 'Annotazione eliminata con successo!'
              else if (type == 'events') msg = 'Evento eliminato con successo!'
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: msg
              })
              this.getNote(type)
            })
            .catch((e) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Errore:' + e
              })
            })
        },
        decline: () => {
          console.log('Ho declinato la risposta')
        }
      })
    },
    changePaginationNotes(pagination, type) {
      this.notes[type].pagination = pagination
      this.getNote(type, pagination)
    },
    toggleNewAnnotationModal(boolean) {
      this.newAnnotationModalOpen = boolean
    },
    closeNewAnnotationModal() {
      this.$store.dispatch('commonComponents/closeAnnotationModal')
    },

    // funzione che fa apparire pop up quando superi il numero di tab aperte
    maxTabOpen: function () {
      Toastify({
        node: cash('#max-tab-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    // funzione che fa apparire pop-up quando provi ad aprire una tab già aperta
    tabAlreadyOpen: function () {
      Toastify({
        node: cash('#tab-already-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    handleGoToCustomer(owner) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + owner.id)
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$goToCustomer(res.data)
        })
    },
    openPetCorner() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/pet/go-to-petcorner', {
          pet_id: this.pet.id
        })
        .then((res) => {
          window.open(res.data.url, '_blank')
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch((error) => {
          console.log(error)
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openMessageModal', {
            title: 'Dati Mancanti',
            subtitle: error.response.data.message
          })
        })
    },
    deletePet() {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare questo paziente?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/pet/' +
                this.pet.id +
                '/delete'
            )
            .then(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
              this.$emit('removeTab')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    },
    openSelectClient() {
      this.addClientModal = true
    },
    closeSelectClient() {
      this.addClientModal = false
    },
    addPetOwner(customer, type) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/pet/associate-pet-customer',
          { pet_id: this.pet.id, customer_id: customer.id, type: type }
        )
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.refreshPetData()
          // this.$store.dispatch('commonComponents/openNotificationModal', {
          //   message: 'Successo'
          // })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          // this.$store.dispatch('commonComponents/openNotificationModal', {
          //   message: 'Errore'
          // })
        })
      this.closeSelectClient()

      console.log(customer)
    },
    getExternalId() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/pet/external_id', {
          pet_id: this.pet.id
        })
        .then((res) => {
          this.external_id = res.data.external_id
        })
    },
  }
})
</script>
