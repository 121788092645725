<template>
  <div
    ref="fileUploadRef"
    v-file-upload="{ props, emit }"
    class="dropzone"
    :class="{ 'background-image': imageProp, 'blue-background': !imageProp }"
    :style="imageStyle"
  >
    <div class="dz-message">
      <slot v-if="imageProp"></slot>
      <span v-else>{{ clinicNameProp }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from 'vue'
import { init } from './index'

export default defineComponent({
  directives: {
    fileUpload: {
      mounted(el, { value }) {
        init(el, value.props, () => {
          value.emit('fetchClinicData')
        })
      }
    }
  },
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    refKey: {
      type: String,
      default: null
    },
    imageProp: {
      type: String,
      default: null
    },
    clinicNameProp: {
      type: String,
      default: null
    },
    uploadToUrl: {
      type: String,
      default: null
    }
  },
  emits: ['fetchClinicData'],
  setup(props, context) {
    const fileUploadRef = ref()
    const bindInstance = () => {
      if (props.refKey) {
        const bind = inject(`bind[${props.refKey}]`)
        if (bind) {
          bind(fileUploadRef.value)
        }
      }
    }

    onMounted(() => {
      bindInstance()
    })

    return {
      props,
      ...context,
      fileUploadRef
    }
  },
  computed: {
    imageStyle() {
      return this.imageProp ? { backgroundImage: `url(${this.imageProp})` } : {}
    }
  },
  methods: {
    fetchClinicData() {
      console.log('ciaoabja')
      this.$emit('fetchClinicData')
    }
  }
})
</script>

<style scoped>
.background-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.blue-background {
  background-color: #003764;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2rem;
}
</style>
