<template>
  <NewClient
    v-if="showModalEditClient"
    :title="'Modifica cliente'"
    :client="customerDetails"
    @close="toggleNewClient"
    @edit_close="editClient()"
  />
  <div class="px-4 rounded-b-lg bg-white pb-5">
    <!--    BEGIN: Top section customer tab-->
    <div class="grid grid-cols-8 gap-2 p-3">
      <!--      BEGIN: Customer infos-->
      <div
        class="col-span-8 md:col-span-4 lg:col-span-3 p-2 rounded-lg shadow-md bg-white"
      >
        <div class="flex justify-center bg-theme-45 rounded-md py-2">
          <h1 class="text-xl mr-2 nunito text-theme-40 font-bold">Cliente</h1>
        </div>

        <div class="flex flex-col p-2">
          <div class="flex flex-col gap-5 items-center text-base mx-1">
            <div class="flex mt-1 items-center">
              <h2 class="text-center nunito text-base uppercase">
                {{ customer.ragione_sociale ? customer.ragione_sociale : '' }}
                {{ customer.last_name ? customer.last_name : '' }}
                {{ customer.first_name ? customer.first_name : '' }}
              </h2>
            </div>
            <div v-if="customer.type">
              <i class="fas fa-exclamation-circle mr-1" />

              <span class="text-sm">{{
                customer.type == 'PA'
                  ? 'Pubblica Amministrazione'
                  : customer.type
              }}</span>
            </div>
            <div v-else class="h-6" />
          </div>

          <div class="flex flex-col mt-2 items-center text-s mx-1 w-full">
            <div class=" mt-1 ">
              <span class=" font-bold">Id cliente: </span> <span>{{ customer.id }}</span>
            </div>
            <div v-if="external_id">
              <span class=" font-bold">Id esterno: </span> <span>{{ external_id }}</span>
            </div>
            <div v-else class="h-6" />
          </div>
          

          <div
            class="flex flex-col content-center items-center gap-2 sm:gap-0 sm:flex-row mt-6 justify-center"
          >
            <div
              class="text-center rounded-xl bg-theme-2 shadow px-3 w-24 items-center py-1 h-7 flex mx-1 justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-4 h-4 mr-2 text-xs"
                :class="[
                  customer.phones && customer.phones.phone1
                    ? 'text-theme-41 fill-current'
                    : 'text-theme-44 fill-current'
                ]"
              >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z"
                />
              </svg>

              <div class="text-theme-43 text-xs">SMS</div>
            </div>

            <div
              class="text-center rounded-xl bg-theme-2 shadow px-3 w-24 items-center py-1 h-7 flex mx-1 justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-4 h-4 mr-2 text-xs"
                :class="[
                  customer.emails &&
                  customer.emails.email1 &&
                  customer.emails.email1.email
                    ? 'text-theme-41 fill-current'
                    : 'text-theme-44 fill-current'
                ]"
              >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M207.8 20.73c-93.45 18.32-168.7 93.66-187 187.1c-27.64 140.9 68.65 266.2 199.1 285.1c19.01 2.888 36.17-12.26 36.17-31.49l.0001-.6631c0-15.74-11.44-28.88-26.84-31.24c-84.35-12.98-149.2-86.13-149.2-174.2c0-102.9 88.61-185.5 193.4-175.4c91.54 8.869 158.6 91.25 158.6 183.2l0 16.16c0 22.09-17.94 40.05-40 40.05s-40.01-17.96-40.01-40.05v-120.1c0-8.847-7.161-16.02-16.01-16.02l-31.98 .0036c-7.299 0-13.2 4.992-15.12 11.68c-24.85-12.15-54.24-16.38-86.06-5.106c-38.75 13.73-68.12 48.91-73.72 89.64c-9.483 69.01 43.81 128 110.9 128c26.44 0 50.43-9.544 69.59-24.88c24 31.3 65.23 48.69 109.4 37.49C465.2 369.3 496 324.1 495.1 277.2V256.3C495.1 107.1 361.2-9.332 207.8 20.73zM239.1 304.3c-26.47 0-48-21.56-48-48.05s21.53-48.05 48-48.05s48 21.56 48 48.05S266.5 304.3 239.1 304.3z"
                />
              </svg>

              <div class="text-theme-43 text-xs">E-mail</div>
            </div>

            <div
              class="text-center rounded-xl bg-theme-2 shadow px-3 w-24 items-center py-1 h-7 flex mx-1 justify-center cursor-pointer"
              @click="showPrivacyPdf"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="w-4 h-4 mr-2 text-xs"
                :class="[
                  customer.privacy && customer.privacy.status === 'signed'
                    ? 'text-theme-41 fill-current'
                    : 'text-theme-44 fill-current'
                ]"
              >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M144 192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80V144C80 64.47 144.5 0 224 0C281.5 0 331 33.69 354.1 82.27C361.7 98.23 354.9 117.3 338.1 124.9C322.1 132.5 303.9 125.7 296.3 109.7C283.4 82.63 255.9 64 224 64C179.8 64 144 99.82 144 144L144 192z"
                />
              </svg>

              <div class="text-theme-43 text-xs">GDPR</div>
            </div>
            <div v-if="paymentS && paymentS.tot_to_pay" class="px-4">
              <i
                v-if="paymentS.tot_to_pay > 0"
                class="fas fa-circle text-red-500"
              />
              <i v-else class="fas fa-circle text-green-500" />
            </div>
          </div>
        </div>
      </div>
      <!--      END: Customer infos-->
      <!--      BEGIN: Customer details-->
      <div
        class="col-span-8 md:col-span-4 lg:col-span-3 p-2 rounded-lg shadow-md bg-white"
      >
        <div class="flex justify-center bg-theme-45 rounded-md py-2">
          <h1 class="text-xl mr-2 nunito text-theme-40 font-bold">
            Dettagli cliente
          </h1>
        </div>

        <div
          class="flex flex-col gap-2 text-left justify-center mt-4 ml-3 sm:ml-10 md:ml-1 lg:ml-4 pb-2"
        >
          <div class="flex items-center gap-2">
            <i
              class="fas fa-home text-base xl:text-lg text-center w-6 text-gray-700"
            />
            <div class="text-left">
              <span class="form-label mb-0">Indirizzo: </span>
              <span class="text-gray-700 text-sm font-normal capitalize">
                {{ customer.address.address }}
                {{ customer.address.house_number }}
                {{ customer.address.zip_code }}

                {{
                  customer.address.address &&
                  customer.address.house_number &&
                  customer.address.zip_code
                    ? ''
                    : 'Indirizzo da completare'
                }}
                {{
                  customer.address.city && customer.address.city.nome
                    ? customer.address.city.nome
                    : customer.address.city
                }}{{
                  customer.address.district
                    ? ' (' + customer.address.district + ')'
                    : ''
                }}
              </span>
            </div>
          </div>
          <div v-if="customer.phones" class="flex items-start gap-2">
            <i
              class="fas fa-mobile-alt xl:text-lg text-center w-6 text-gray-700"
            />
            <div class="text-left flex gap-1 items-start flex-wrap">
              <span class="form-label mb-0">Telefono: </span>
              <div class="flex gap-2">
                <div
                  v-for="(phone, index) in customer.phones"
                  :key="phone"
                  class="flex items-center gap-2"
                >
                  <span
                    v-if="index === ('phone2' || 'phone3')"
                    class="font-black text-gray-700"
                  >
                    |
                  </span>
                  <span class="text-gray-700 text-gray-700 text-sm"
                    >{{ phone.prefisso }} {{ phone.phone }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="customer.emails" class="flex items-start gap-2">
            <i
              class="fas fa-envelope xl:text-lg text-center w-6 text-gray-700"
            />
            <div class="text-left flex gap-1 items-start flex-wrap">
              <span class="form-label mb-0">E-mail: </span>

              <div
                v-for="(email, index) in customer.emails"
                :key="email"
                class="flex items-center gap-2 flex-wrap"
              >
                <span
                  v-if="index === ('email2' || 'email3')"
                  class="font-black text-gray-700"
                >
                  |
                </span>
                <span class="text-gray-700 text-sm"> {{ email.email }}</span>
              </div>
            </div>
          </div>
          <div v-if="customer.fiscal_code" class="flex items-center gap-2">
            <i
              class="fas fa-exclamation-circle xl:text-lg text-center w-6 text-gray-700"
            />
            <div class="text-left">
              <span class="form-label mb-0">Cod. Fiscale: </span>
              <span class="text-gray-700">{{ customer.fiscal_code }}</span>
            </div>
          </div>

          <div
            v-if="customer.vat_number && typeof customer.vat_number == 'object'"
            class="flex items-center"
          >
            <i
              class="fas fa-exclamation-circle text-base xl:text-lg text-center w-6 mr-3"
            />
            <div>
              <span class="font-bold text-sm xl:text-base">P. IVA: </span>
              <span class="text-xs xl:text-sm font-normal">{{
                customer.vat_number.vat_number
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--      END: Customer details-->

      <div class="col-span-8 lg:col-span-2 p-2 rounded-lg shadow-md bg-white">
        <!--      BEGIN: Rapid actions-->
        <div class="justify-center bg-theme-45 rounded-md py-2">
          <h1 class="text-xl mr-2 nunito text-theme-40 font-bold text-center">
            Azioni rapide
          </h1>
        </div>
        <div
          class="flex flex-col sm:flex-row justify-center lg:flex-col p-2 gap-2 mt-1 content-center"
        >
          <div class="flex flex-col gap-2">
            <div
              v-if="
                permissions &&
                permissions?.clienti?.edit_customer?.enabled === true
              "
              class="quickaction-btn"
              @click="toggleNewClient"
            >
              <i class="fas fa-user-edit h-3 w-3 mr-1" />
              <p class="quickaction-btn-txt">MODIFICA CLIENTE</p>
            </div>

            <div
              v-if="
                permissions &&
                permissions?.fatturazione?.create_billing?.enabled === true
              "
              class="quickaction-btn"
              @click="openInvoiceModal()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                class="h-4 fill-current col-span-1 text-right"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M64 240C46.33 240 32 225.7 32 208C32 190.3 46.33 176 64 176H92.29C121.9 92.11 201.1 32 296 32H320C337.7 32 352 46.33 352 64C352 81.67 337.7 96 320 96H296C238.1 96 187.8 128.4 162.1 176H288C305.7 176 320 190.3 320 208C320 225.7 305.7 240 288 240H144.2C144.1 242.6 144 245.3 144 248V264C144 266.7 144.1 269.4 144.2 272H288C305.7 272 320 286.3 320 304C320 321.7 305.7 336 288 336H162.1C187.8 383.6 238.1 416 296 416H320C337.7 416 352 430.3 352 448C352 465.7 337.7 480 320 480H296C201.1 480 121.9 419.9 92.29 336H64C46.33 336 32 321.7 32 304C32 286.3 46.33 272 64 272H80.15C80.05 269.3 80 266.7 80 264V248C80 245.3 80.05 242.7 80.15 240H64z"
                />
              </svg>
              <p class="quickaction-btn-txt">NUOVA FATTURA</p>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="quickaction-btn" @click="toggleModal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 117.85 109.04"
                class="w-5 fill-current"
              >
                <g data-name="Layer 2">
                  <g data-name="Layer 1">
                    <path
                      d="M85.21 22.06a4.31 4.31 0 0 0 4.3-4.31V8.88a4.35 4.35 0 0 0-1.26-3.06 4.4 4.4 0 0 0-6 0A4.31 4.31 0 0 0 81 8.88v8.87a4.28 4.28 0 0 0 4.21 4.31Zm-56.92 0a4.28 4.28 0 0 0 4.25-4.31V8.88a4.31 4.31 0 0 0-1.25-3.05 4.38 4.38 0 0 0-6 0A4.3 4.3 0 0 0 24 8.88v8.87a4.31 4.31 0 0 0 4.29 4.31Z"
                      style="fill: none"
                    />
                    <path
                      d="M61.81 84.83h-8.88a3.74 3.74 0 0 0-3.76 3.77 3.79 3.79 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm-29.75 0h-8.88a3.78 3.78 0 0 0-3.76 3.77 3.83 3.83 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm0-16.03h-8.88a3.78 3.78 0 0 0-3.76 3.77 3.83 3.83 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm29.75 0h-8.88a3.74 3.74 0 0 0-3.76 3.77 3.79 3.79 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm20.87-8.44h8.87a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77h-8.87a3.75 3.75 0 0 0-3.77 3.77 3.8 3.8 0 0 0 3.77 3.82Zm-20.87-7.59h-8.88a3.74 3.74 0 0 0-3.76 3.77 3.79 3.79 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm-29.75 0h-8.88a3.78 3.78 0 0 0-3.76 3.77 3.83 3.83 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Z"
                    />
                    <path
                      d="M12.86 101.67a6.84 6.84 0 0 1-4.74-1.88 7 7 0 0 1-2.2-5.06V42.6h101.66v20.92a27.13 27.13 0 0 1 5.92 3.41v-47.3a12.85 12.85 0 0 0-12.85-12.85h-6.84a8.82 8.82 0 0 0-17.16 0h-39.8a8.82 8.82 0 0 0-17.16 0h-6.83A12.85 12.85 0 0 0 0 19.63v75.1a12.93 12.93 0 0 0 6.83 11.35 12.8 12.8 0 0 0 6 1.5H77.6a27.13 27.13 0 0 1-4.38-5.91ZM81 8.88a4.31 4.31 0 0 1 1.25-3.05 4.4 4.4 0 0 1 6 0 4.35 4.35 0 0 1 1.26 3.06v8.87a4.28 4.28 0 1 1-8.55 0Zm-57 0a4.3 4.3 0 0 1 1.26-3.05 4.38 4.38 0 0 1 6 0 4.31 4.31 0 0 1 1.25 3.05v8.87a4.28 4.28 0 1 1-8.55 0Z"
                    />
                    <path
                      d="M117.81 88a4.8 4.8 0 0 0-4.31-4.3 10 10 0 0 0-1.5-.15h-9.91v-9.37c0-3.65-1.88-5.88-4.85-6a4.48 4.48 0 0 0-3.43 1.31 6.76 6.76 0 0 0-1.7 4.72v9.31c-3.56 0-6.76-.08-9.95 0-3.82.1-6.2 2.51-5.75 5.66.4 2.76 2.42 4.37 5.84 4.45 3.18.07 6.37 0 9.85 0v8c.14 2.86.6 4.76 1.52 5.91A4.13 4.13 0 0 0 97 109a4 4 0 0 0 3.51-1.46c.92-1.18 1.4-3.15 1.52-6.06v-7.79h10a8.78 8.78 0 0 0 1.41-.17c3.05-.6 4.7-2.66 4.37-5.52Z"
                    />
                  </g>
                </g>
              </svg>
              <p class="quickaction-btn-txt">NUOVO APPT.</p>
            </div>
            <div class="quickaction-btn" @click="workInProgress()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="h-4 w-4 fill-current"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"
                />
              </svg>
              <div class="quickaction-btn-txt">COMUNICAZIONI</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="openModalAppointment">
      <NewAppointment
        :coming-from-client="customer"
        :appointment-data="newEvent"
        :footer-modal-appointment="false"
        :preview="false"
        modal-appointment-title="Nuovo appuntamento"
        @close="toggleModal"
        @openConfirmModal="toggleConfirm"
        @addAppointment="toggleModal"
        @render="update"
      />
    </div>
    <!-- BEGIN: Check-in modal-->
    <div
      id="button-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content content p-0">
          <!-- BEGIN: Modal header-->
          <div class="modal-header bg-theme-1 relative -top-0.5">
            <h2 class="font-medium text-white text-base m-auto">Check-in</h2>
            <a class="pr-2" data-dismiss="modal" href="javascript:">
              <XIcon class="w-8 h-8 text-gray-500" />
            </a>
          </div>
          <!-- END: Modal header-->
          <div class="modal-body p-0">
            <!-- BEGIN: Ticket -->
            <div class="col-span-12 lg:col-span-4">
              <div class="intro-y pr-1">
                <div class="box p-2">
                  <!-- TODO: change the active class in active-bg_blue-->
                  <div
                    class="pos__tabs nav nav-tabs justify-center"
                    role="tablist"
                  >
                    <a
                      id="ticket-tab"
                      data-toggle="tab"
                      data-target="#ticket"
                      href="javascript:"
                      class="flex-1 py-2 text-center rounded border border-gray-400 active"
                      role="tab"
                      aria-controls="ticket"
                      aria-selected="true"
                      >Check-in non medico</a
                    >
                    <a
                      id="details-tab"
                      data-toggle="tab"
                      data-target="#details"
                      href="javascript:"
                      class="flex-1 py-2 text-center rounded border border-gray-400"
                      role="tab"
                      aria-controls="details"
                      aria-selected="false"
                      >Check-in medico</a
                    >
                  </div>
                </div>
              </div>
              <div class="tab-content p-2">
                <div
                  id="ticket"
                  class="tab-pane grid grid-cols-12 gap-2 gap-y-3 active"
                  role="tabpanel"
                  aria-labelledby="ticket-tab"
                >
                  <div class="col-span-12 sm:col-span-4">
                    <button class="btn btn-primary hover:bg-theme-45 w-full">
                      Saldo sospeso
                    </button>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <button class="btn btn-primary hover:bg-theme-45 w-full">
                      Saldo prestazioni
                    </button>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <button class="btn btn-primary hover:bg-theme-45 w-full">
                      Acquisto prodotti
                    </button>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <button class="btn btn-primary hover:bg-theme-45 w-full">
                      Colloquio con medico
                    </button>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <button class="btn btn-primary hover:bg-theme-45 w-full">
                      Ritiro referti
                    </button>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <button class="btn btn-primary hover:bg-theme-45 w-full">
                      Visita paziente ricoverato
                    </button>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <button class="btn btn-primary hover:bg-theme-45 w-full">
                      Dimissioni paziente
                    </button>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <button
                      class="btn btn-primary hover:bg-green-300 w-full bg-green-500"
                    >
                      Check-in medico
                    </button>
                  </div>
                  <div class="col-span-12 px-3">
                    <label for="modal-form-reference" class="form-label"
                      >Referente</label
                    >
                    <TomSelect
                      id="modal-form-reference"
                      :options="{ placeholder: 'Seleziona il referente' }"
                      class="w-full rounded-md"
                    >
                      <option value="0">Seleziona il referente</option>
                      <option value="Cane">Cane</option>
                      <option value="Gatto">Gatto</option>
                      <option value="Coniglio">Coniglio</option>
                      <option value="Top">Topo</option>
                      <option value="Cavallo">Cavallo</option>
                      <option value="Mucca">Mucca</option>
                    </TomSelect>
                  </div>
                  <div class="col-span-12">
                    <label for="modal-form-25" class="form-label">Note</label>
                    <textarea
                      id="modal-form-25"
                      name="modal-form-25"
                      cols="30"
                      rows="10"
                      class="form-control"
                      style="height: 100px"
                    />
                  </div>
                  <div class="col-span-12 flex justify-between mt-5 px-5">
                    <button
                      class="btn w-32 border-gray-400 dark:border-dark-5 text-white bg-red-600 dark:text-gray-300"
                    >
                      Annulla
                    </button>
                    <button class="btn btn-primary w-32 shadow-md ml-auto">
                      OK
                    </button>
                  </div>
                </div>
                <div
                  id="details"
                  class="tab-pane"
                  role="tabpanel"
                  aria-labelledby="details-tab"
                >
                  <div class="box p-5 mt-5">
                    <div class="col-span-12 px-3">
                      <label for="modal-form-reference" class="form-label"
                        >Paziente</label
                      >
                      <TomSelect
                        id="modal-form-reference"
                        :options="{ placeholder: 'Seleziona il paziente' }"
                        class="w-full rounded-md"
                      >
                        <option value="0">Seleziona il paziente</option>
                        <option value="Cane">Cane</option>
                        <option value="Gatto">Gatto</option>
                        <option value="Coniglio">Coniglio</option>
                        <option value="Top">Topo</option>
                        <option value="Cavallo">Cavallo</option>
                        <option value="Mucca">Mucca</option>
                      </TomSelect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Ticket -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Check-in modal -->
    <!--    END: Top section customer tab-->
    <!--    BEGIN: Bottom section customer tab-->

    <div
      class="mx-12 flex flex-col text-center justify-center sm:mx-36 md:mx-48 lg:flex-wrap lg:flex-row my-2 lg:mx-1"
      role="tablist"
    >
      <button
        v-for="(elem, elemKey) in computedMenu"
        :key="elemKey"
        class="mx-1 my-1 text-center"
        :class="[activeMenu === elemKey ? 'orange-btn' : 'orange-btn-inverted']"
        @click="getMenu(elemKey)"
      >
        <i :class="elem.icon" class="pr-2"></i>
        {{ elem.name }}
      </button>
    </div>

    <div
      v-for="(item, itemKey) in computedMenu"
      v-show="activeMenu === itemKey"
      :key="itemKey"
      class="mt-5 relative"
    >
      <keep-alive>
        <component
          :is="item.component"
          :key="keyNumber"
          :customer-details="customer"
          :notes="notes"
          :total-records="totalRecords"
          :new-annotation-modal="newAnnotationModalOpen"
          :active="active"
          @changePaginationNotes="changePaginationNotes"
          @addNote="addNote"
          @deleteNote="deleteNote"
          @toggleNewAnnotationModal="toggleNewAnnotationModal"
          @showInvoice="(el, title) => openInvoiceModalReadOnly(el, title)"
          @editInvoice="
            (el, title, tipo) => openInvoiceEditable(el, title, tipo)
          "
          @showInvoiceEditable="(el) => fatturaPreventivoFunction(el)"
          @handleNotaDiCredito="(el) => openNotaDiCredito(el)"
          @showConfirm="(t, r) => handleResponseSubmitInvoice(t, r)"
          @refreshTables="refreshTable"
          @storePaymentStatus="
            (data) => {
              paymentS = data
            }
          "
        />
      </keep-alive>
    </div>
    <!--    END: Bottom section customer tab-->
    <div v-if="invoiceModalOpen">
      <NewInvoiceModal
        :read-only="invoiceReadOnly"
        :read-only-elem="invoiceElem"
        :services="checkedServices"
        :client="customer"
        :title="newInvoiceTitle"
        :fattura-preventivo="fatturaPreventivo"
        :nota-credito="notaCredito"
        :modify="modifica"
        :documento="tipoDocumento"
        @close="closeInvoiceModal"
        @responseModal="handleResponseSubmitInvoice"
        @refreshTables="refreshTable"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, shallowRef } from 'vue'
import NewClient from '../../views/nuovo-cliente/NewClient.vue'
import AnimalList from './CustomerAnimalList'
import Dashboard from './CustomerDashboard'
import CustomerInvoices from './CustomerInvoices'
import CustomerUnpaid from './CustomerUnpaid'
import CustomerQuote from './CustomerQuote'
import CustomerAttachments from './CustomerAttachments'
import CustomerStatusPayment from './CustomerStatusPayment'
import NewInvoiceModal from '../new-invoice/NewInvoiceModal.vue'
import NewAppointment from '../../views/calendar/NewAppointment.vue'

import axios from '../../gateway/backend-api'
import { mapActions, mapGetters } from 'vuex'

const dashboard = shallowRef(Dashboard)
const animalList = shallowRef(AnimalList)
const invoices = shallowRef(CustomerInvoices)
const unpaid = shallowRef(CustomerUnpaid)
const quote = shallowRef(CustomerQuote)
const attachments = shallowRef(CustomerAttachments)
const status_p = shallowRef(CustomerStatusPayment)

const components = [
  dashboard,
  animalList,
  invoices,
  unpaid,
  quote,
  attachments,
  status_p
]

export default defineComponent({
  components: {
    AnimalList,
    Dashboard,
    CustomerInvoices,
    NewInvoiceModal,
    NewClient,
    NewAppointment
  },
  props: {
    customerDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    active: {
      type: [Boolean],
      default: false
    }
  },
  emits: ['getPet'],
  data() {
    return {
      paymentS: null,
      newEvent: [],
      openModalAppointment: false,
      customer: {},
      menu: [
        {
          name: 'Cruscotto',
          icon: 'fas fa-th-large',
          component: components[0],
          props: this.customerDetails
        },
        {
          name: 'Lista Pets',
          icon: 'fas fa-paw',
          component: components[1],
          props: this.customerDetails,
          permissions: ['pet', 'view_pet']
        },
        {
          name: 'Documenti fiscali',
          icon: 'fas fa-euro-sign',
          component: components[2],
          props: this.customerDetails,
          permissions: ['fatturazione', 'view_billing']
        },
        {
          name: 'Conto Aperto',
          icon: 'fas fa-list-alt',
          component: components[3],
          props: this.customerDetails,
          permissions: ['conto aperto', 'view_account']
        },
        {
          name: 'Preventivi',
          icon: 'fas fa-file-invoice-dollar',
          component: components[4],
          props: this.customerDetails,
          permissions: ['fatturazione', 'view_preventivi']
        },
        {
          name: 'Allegati',
          icon: 'fas fa-file-medical',
          component: components[5],
          props: this.customerDetails,
          permissions: ['allegati', 'view_attachments']
        },
        {
          name: 'Stato Pagamento',
          icon: '	fas fa-hand-holding-usd',
          component: components[6],
          props: this.customerDetails
        }
      ],
      activeMenu: 0,
      isActive: false,
      showModalEditClient: false,
      invoiceModalOpen: false,
      messageModalOpen: false,
      invoiceReadOnly: false,
      invoiceElem: false,
      checkedServices: [],
      keyNumber: 0,
      notes: {
        forPageOption: [5, 10, 20, 50],
        communications: {
          note: null,
          pagination: {
            page: 1,
            forPage: 3
          },
          total: 0
        },
        payments: {
          note: null,
          pagination: {
            page: 1,
            forPage: 3
          },
          total: 0
        }
      },
      newAnnotationModalOpen: false,
      fatturaPreventivo: false,
      notaCredito: false,
      modifica: false,
      tipoDocumento: null,
      external_id: null
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    }),
    computedMenu: function () {
      let resp = []
      if (this.menu) {
        resp = this.menu.filter((el) => {
          return (
            !el.permissions ||
            (this.permissions[el.permissions[0]] &&
              this.permissions[el.permissions[0]][el.permissions[1]] &&
               this.permissions[el.permissions[0]][el.permissions[1]].hidden === false &&
              this.permissions[el.permissions[0]][el.permissions[1]].enabled ===
                true)
          )
        })
      }
      return resp
    }
  },
  watch: {
    customerDetails: function (elem) {
      this.customer = elem
      // this.refreshTable()
    }
  },
  created() {
    this.customer = this.customerDetails
  },
  mounted() {
    if (this.active) {
      this.getNote('communications')
      this.getNote('payments')
    }
    this.getExternalId()
  },
  methods: {
    ...mapActions({
      updateClientData: 'anagraficaClienti/updateClientData'
    }),
    showPrivacyPdf() {
      let data_to_send = {}
      if (this.customer.privacy) {
        data_to_send['privacy_id'] = this.customer.privacy.id
      }
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios({
        url:
          '/' +
          localStorage.getItem('clinic') +
          '/customer/' +
          this.customerDetails.id +
          '/privacy/pdf',
        method: 'POST',
        data: data_to_send
        // responseType: 'blob'
      })
        .then(async (response) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          console.log(response.data)

          const base64Response = await fetch(
            `data:application/pdf;base64,${response.data.pdf}`
          )

          const url = window.URL.createObjectURL(
            new Blob([await base64Response.blob()])
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'privacy.pdf')
          document.body.appendChild(link)
          if (!this.customer.privacy)
            this.customer.privacy = {
              id: response.data.privacy_id,
              status: 'created'
            }
          var marketing = null
          axios
            .get(
              '/' +
                localStorage.getItem('clinic') +
                '/customer/' +
                this.customer.id +
                '/consents'
            )
            .then((res) => {
              marketing =
                res.data &&
                res.data.consents &&
                JSON.parse(res.data.consents).marketing !== null &&
                JSON.parse(res.data.consents).marketing !== undefined
                  ? JSON.parse(res.data.consents).marketing
                  : null
              this.$store.dispatch('commonComponents/openPdfModal', {
                title: 'Privacy GDPR',
                link: url,
                background: false,
                table_reference: 'privacy',
                id_reference: response.data.privacy_id,
                pdf_signed:
                  this.customer.privacy &&
                  this.customer.privacy.status === 'signed',
                client: this.customer,
                confirmations: [
                  {
                    title: 'Consenso marketing',
                    description: '',
                    options: [
                      {
                        label: 'Accetto',
                        value: true,
                        selected: marketing === true
                      },
                      {
                        label: 'Non accetto',
                        value: false,
                        selected: marketing === false
                      }
                    ],
                    onChange: (res) => {
                      console.log('click fatto', res)
                      axios
                        .post(
                          '/' +
                            localStorage.getItem('clinic') +
                            '/customer/' +
                            this.customer.id +
                            '/consents/save',
                          {
                            marketing: res == 'true' ? true : false
                          }
                        )
                        .then(() => {
                          console.log('then')
                        })
                        .catch(() => {
                          console.log('catch')
                        })
                    }
                  }
                ],
                print: () => {},
                download: () => {
                  link.click()
                },
                sign_done: () => {
                  console.log('ho firmato')
                  this.editClient()
                }
              })
            })
            .catch(() => {
              console.log('catch')
              this.$store.dispatch('commonComponents/openPdfModal', {
                title: 'Privacy GDPR',
                link: url,
                background: false,
                table_reference: 'privacy',
                id_reference: response.data.privacy_id,
                pdf_signed:
                  this.customer.privacy &&
                  this.customer.privacy.status === 'signed',
                client: this.customer,
                confirmations: [
                  {
                    title: 'Consenso marketing',
                    description: '',
                    options: [
                      {
                        label: 'Accetto',
                        value: true,
                        selected: marketing === true
                      },
                      {
                        label: 'Non accetto',
                        value: false,
                        selected: marketing === false
                      }
                    ],
                    onChange: (res) => {
                      console.log('click fatto', res)
                      axios
                        .post(
                          '/' +
                            localStorage.getItem('clinic') +
                            '/customer/' +
                            this.customer.id +
                            '/consents/save',
                          {
                            marketing: res == 'true' ? true : false
                          }
                        )
                        .then(() => {
                          console.log('then')
                        })
                        .catch(() => {
                          console.log('catch')
                        })
                    }
                  }
                ],
                print: () => {},
                download: () => {
                  link.click()
                },
                sign_done: () => {
                  console.log('ho firmato')
                  this.editClient()
                }
              })
            })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore dati utente. Controllare indirizzo di residenza'
          })
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    getMenu: function (elem) {
      this.activeMenu = elem
      // if (elem == 3) this.refreshTable() //conto aperto
    },
    toggleModal() {
      this.openModalAppointment = !this.openModalAppointment

      this.newEvent = []
    },
    refreshTable() {
      this.keyNumber += 1
    },
    // con $emit ritorno al padre di questo componente il click in questo componente
    getPetDetails: function () {
      this.$emit('getPet', this.customer.id)
    },
    toggleChevron: function () {
      this.isActive = !this.isActive
    },
    // getPetDetails: function () {
    //   this.$emit('getPet', this.customerDetails.id)
    // }
    toggleNewClient() {
      this.showModalEditClient = !this.showModalEditClient
    },
    workInProgress: function () {
      this.$store.dispatch('commonComponents/openSendMessageModal', {
        data: { type: 'mail', client: this.customerDetails },
        confirm: (data) => {
          console.log('invio la mail', data)
          let attachements = []
          if (data && data.selectedFile) {
            data.selectedFile.forEach((el) => {
              attachements.push({
                id: typeof el.id === 'string' ? 1 : el.id,
                alternative_id: el.id.toString(),
                type: el.table,
                file_name: el.name
              })
            })
          }
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post('/' + localStorage.getItem('clinic') + '/mailersend/send', {
              customer_id: this.customerDetails.id,
              type: 'email',
              email: data.destinatario,
              //text: 'Documento: ' + props.title,
              object: data.titolo,
              body: data.message,
              email_type: 'simple_message',
              attachments: attachements,
              cc: data.cc
            })
            .then(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        }
      })
    },
    openInvoiceModal() {
      this.invoiceModalOpen = !this.invoiceModalOpen
    },
    openInvoiceModalReadOnly(el, title) {
      if (title) {
        this.newInvoiceTitle = title
      } else {
        if (el && el.from_active_bill_n_doc) {
          this.newInvoiceTitle = 'Riepilogo Nota di credito'
        } else {
          this.newInvoiceTitle = 'Riepilogo Fattura'
        }
      }

      this.invoiceModalOpen = !this.invoiceModalOpen
      this.invoiceReadOnly = true
      this.invoiceElem = el
    },
    openInvoiceEditable(el, title, tipo) {
      this.newInvoiceTitle = title ? title : 'Modifica Fattura'
      this.modifica = true
      this.invoiceModalOpen = !this.invoiceModalOpen
      if (tipo) this.tipoDocumento = tipo
      this.invoiceElem = el
    },
    fatturaPreventivoFunction(el, title) {
      this.newInvoiceTitle = title ? title : 'Fattura preventivo'
      this.fatturaPreventivo = true
      this.invoiceModalOpen = !this.invoiceModalOpen
      this.invoiceElem = el
    },
    openNotaDiCredito(el) {
      this.notaCredito = true
      this.newInvoiceTitle = 'Nota di credito'
      this.invoiceModalOpen = !this.invoiceModalOpen
      this.invoiceElem = el
    },
    closeInvoiceModal() {
      this.newInvoiceTitle = null
      this.invoiceModalOpen = false
      this.fatturaPreventivo = false
      this.invoiceReadOnly = false
      this.invoiceElem = null
      this.notaCredito = false
      this.modifica = false
      this.tipoDocumento = null
    },
    handleResponseSubmitInvoice() {
      this.invoiceModalOpen = false
      this.fatturaPreventivo = false
      // if (
      //   resp.status === 200 &&
      //   resp.data &&
      //   resp.data.errorCode &&
      //   resp.data.errorCode == '0000'
      // ) {
      //   this.$store.dispatch('commonComponents/openNotificationModal', {
      //     message: 'Fattura inserita con successo'
      //   })
      // } else {
      //   this.activeMenu = 2
      //   var error = 'Fattura Salvata ma non emessa '
      //   if (typeof resp.data.errorCode != 'undefined')
      //     error += 'Error: ' + resp.data.errorCode

      //   this.$store.dispatch('commonComponents/openNotificationModal', {
      //     message: error
      //   })
      // }
      this.refreshTable()
    },
    editClient() {
      this.showModalEditClient = false
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/customer/' + this.customer.id
        )
        .then((res) => {
          this.customer = res.data
          this.updateClientData(res.data)
        })
    },

    getNote(type) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/note', {
          type: type,
          customer_id: this.customer.id,
          per_page: this.notes[type].pagination.forPage,
          page: this.notes[type].pagination.page
        })
        .then((res) => {
          this.notes[type].note = res.data.data
          this.notes[type].total = res.data.total
        })
    },
    addNote(type, note) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/note/new-note', {
          type: type,
          customer_id: this.customer.id,
          note: note
        })
        .then(() => {
          this.toggleNewAnnotationModal(false)
          this.getNote(type)
        })
    },
    deleteNote(type, note_id) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler eliminare questa nota?',
        background: false,
        confirm: () => {
          axios
            .delete(
              '/' +
                localStorage.getItem('clinic') +
                '/note/' +
                note_id +
                '/delete'
            )
            .then(() => {
              this.getNote(type)
            })
            .catch((e) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Errore:' + e
              })
            })
        },
        decline: () => {}
      })
    },
    changePaginationNotes(pagination, type) {
      this.notes[type].pagination = pagination
      this.getNote(type)
    },
    toggleNewAnnotationModal(boolean) {
      this.newAnnotationModalOpen = boolean
    },
    getExternalId() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/customer/external_id', {
          customer_id: this.customer.id
        })
        .then((res) => {
          this.external_id = res.data.external_id
        })
    },
  }
})
</script>
